import { buildCustomerValueHelper } from './jsonHelpers/customerAssesmentHelpers/buildCustomerValueHelper'
import { buildPainPointsHelper } from './jsonHelpers/customerAssesmentHelpers/buildPainPointsHelper'
import { buildPainPointsOvercomeHelper } from './jsonHelpers/customerAssesmentHelpers/buildPainPointsOvercomeHelper'
import { buildPriorBadExperienceHelper } from './jsonHelpers/customerAssesmentHelpers/buildPriorBadExperienceHelper'
import { buildPriorBadExperienceOvercomeHelper } from './jsonHelpers/customerAssesmentHelpers/buildPriorBadExperienceOvercomeHelper'
import { buildSaleObjectionsHelper } from './jsonHelpers/customerAssesmentHelpers/buildSaleObjectionsHelper'
import { buildSalesObjectionOvercomeHelper } from './jsonHelpers/customerAssesmentHelpers/buildSalesObjectionOvercomeHelper'
import { buildWeAlignHelper } from './jsonHelpers/customerAssesmentHelpers/buildWeAlignHelper'
import { createApi } from '@reduxjs/toolkit/query/react'
import { getCustomerAlignmentByIdHelper } from './jsonHelpers/customerAssesmentHelpers/getCustomerAlignmentByIdHelper'
import { graphqlBaseQuery } from './graphqlBaseQuery'

export interface CustomerAlignment {
  id: string
  idealCustomerId: string
  ownerId: string
  customerValue: string
  weAlign: string
  painPoints: string
  solvesPainPoints: string
  saleObjections: string
  objectionSolutions: string
  priorBadExperience: string
  solveBadExperience: string
}

export interface CustomerAlignmentUpdate {
  id: string
  idealCustomerId: string
  ownerId: string
  customerValue: string
  weAlign: string
  painPoints: string
  solvesPainPoints: string
  saleObjections: string
  objectionSolutions: string
  priorBadExperience: string
  solveBadExperience: string
  getCustomerAlignmentById: CustomerAlignment
}
export interface GetCustomerAlignmentResponse {
  data: {
    getCustomerAlignmentById: CustomerAlignment
  }
}

export const caSlice = createApi({
  reducerPath: 'customerAlignmentApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_SERVER!
  }),
  endpoints: builder => ({
    buildCustomerValue: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildCustomerValueHelper(arg)
      })
    }),
    buildWeAlign: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildWeAlignHelper(arg)
      })
    }),
    buildPainPoints: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildPainPointsHelper(arg)
      })
    }),
    buildPainPointSolutions: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildPainPointsOvercomeHelper(arg)
      })
    }),
    buildSaleObjections: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildSaleObjectionsHelper(arg)
      })
    }),
    buildSaleObjectionsSolutions: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildSalesObjectionOvercomeHelper(arg)
      })
    }),
    buildPriorBadExperiences: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildPriorBadExperienceHelper(arg)
      })
    }),
    buildPriorBadExperiencesSolution: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: buildPriorBadExperienceOvercomeHelper(arg)
      })
    }),
    getCustomerAlignmentById: builder.query<CustomerAlignmentUpdate, string>({
      query: arg => ({
        body: getCustomerAlignmentByIdHelper(arg)
      })
    })
  })
})
export const {
  useLazyBuildCustomerValueQuery,
  useLazyBuildPainPointSolutionsQuery,
  useLazyBuildPainPointsQuery,
  useLazyBuildPriorBadExperiencesQuery,
  useLazyBuildPriorBadExperiencesSolutionQuery,
  useLazyBuildSaleObjectionsQuery,
  useLazyBuildSaleObjectionsSolutionsQuery,
  useLazyBuildWeAlignQuery,
  useLazyGetCustomerAlignmentByIdQuery
} = caSlice

import React, { FC, useEffect, useState } from 'react'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RootState } from '../Store'
import { SMALL_LOGO } from '../constants/ImageConstants'
import Vimeo from '@u-wave/react-vimeo'
import { getItemById } from '../utils/ArrayUtils'
import { insertName } from '../utils/StringUtils'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'
import { useSelector } from 'react-redux'

export const VideoGenericScreen: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const { updateProgress, goBack, nextPage } = useScreenNavigation()
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const { additionalFunctionCall } = usePillarCommunication()

  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
    if (newQuestion.functionCall) {
      additionalFunctionCall(newQuestion.functionCall)
    }
  }, [id])
  const catchError = (err: any) => {
    console.log('Video ERror', err)
  }
  const renderVideo = () => {
    if (question.videoLink) {
      return <Vimeo video={question.videoLink} autoplay width={640} height={480} onError={catchError} />
    }
    return null
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={SMALL_LOGO} alt="Marketing RAMP" />
        </div>
        <div className="mr-content">
          <center>
            {renderVideo()}
            <h2 dangerouslySetInnerHTML={{ __html: insertName(question.h2Copy, firstName) }} />
            <p>{question?.pCopy ?? ''}</p>
            <ButtonGroupComponent goBack={goBack} nextPageClick={nextPage} label={question.button} />
          </center>
        </div>
      </div>
    </div>
  )
}

import * as LottiePlayer from '@lottiefiles/lottie-player'

import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { UPGRADE_PAY_ROUTE } from '../constants/RouteConstants'
import { getItemById } from '../utils/ArrayUtils'
import { insertNames } from '../utils/StringUtils'
import { useLazyCreatePaymentIntentQuery } from '../api/stripeSlice'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const UpgradeScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const dispatch = useDispatch()
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)
  const { nextPage, goBack, updateProgress, skipClick } = useScreenNavigation()
  const [question] = useState<Question>(getItemById(questions, id ?? ''))
  const { callServer, isTalking } = usePillarCommunication()
  const navigate = useNavigate()
  const [createPaymentIntent] = useLazyCreatePaymentIntentQuery()

  const nextPageClick = () => {
    navigate(UPGRADE_PAY_ROUTE)
  }

  const skipPageClick = () => {
    skipClick('p1s3')
  }

  useEffect(() => {
    updateProgress(id ?? '')
  }, [id])

  const renderButton = () => {
    /* <ButtonGroupComponent
            goBack={goBack}
            nextPageClick={nextPageClick}
            label={question.button}
            processing={isTalking}
            useSkip
            skipClick={skipPageClick}
            noWhiteArrow
          /> */
    return <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} label={question.button} processing={isTalking} />
  }
  const renderImage = () => {
    if (question.icon) {
      return <img src={question.icon} alt="" />
    }
    if (question.gif) {
      return (
        <div className="lottie_container">
          <lottie-player loop autoplay background="transparent" speed="0.5" style={{ width: 600, height: 650 }} src={question.gif} />
        </div>
      )
    }
    return null
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            {renderImage()}
            <h2>{insertNames(question.h2Copy, firstName, idealCustomerName)}</h2>
            <p>{question.pCopy}</p>
          </center>
          {renderButton()}
        </div>
      </div>
    </div>
  )
}

import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import React, { FC, useEffect, useState } from 'react'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { SingleButtonSmartComponent } from '../components/buttons/SingleButtonSmartComponent'
import { TextInputFullWidthComponent } from '../components/TextInputFullWidthComponent'
import { UpgradeCheckoutFormComponent } from '../components/stripe/UpgradeCheckoutFormComponent'
import { loadStripe } from '@stripe/stripe-js'
import { useLazyCreatePaymentIntentQuery } from '../api/stripeSlice'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY ?? '')

export const UpgradeStripeScreenComponent: FC = () => {
  // const { callServer, isTalking } = usePillarCommunication()
  const [isTalking, setIsTalking] = useState(false)
  const [clientSecret, setClientSecret] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [amount, setAmount] = useState(0)
  const [discountCode, setDiscountCode] = useState('')
  const [createPaymentIntent] = useLazyCreatePaymentIntentQuery()
  const { nextPage } = useScreenNavigation()
  const options = {
    // passing the client secret obtained from the server
    clientSecret
  }

  const getSecret = async () => {
    setIsTalking(true)
    try {
      const result = await createPaymentIntent({
        amount: 9700,
        currency: 'usd',
        discountCode: discountCode,
        description: 'Upgrade Ramp Purchase'
      }).unwrap()
      console.log('Result', result)
      if (result.clientSecret) {
        setClientSecret(result.clientSecret)
        setTransactionId(result.id)
        setAmount(result.amount)
      }
      setIsTalking(false)
    } catch (e) {
      console.log('Error', e)
      setIsTalking(false)
    }
  }

  const renderContent = () => {
    if (clientSecret) {
      if (amount === 100) {
        return (
          <div className="mr-content">
            <center>
              <h2>Congratulations, You have access to the Ramp AI content created for free.</h2>
              <SingleButtonSmartComponent nextPageClick={nextPage} />
            </center>
          </div>
        )
      }
      return (
        <Elements stripe={stripePromise} options={options}>
          <div className="mr-content">
            <center>
              <h2>{`Submit Payment $${Number(amount) / 100}`}</h2>
              <UpgradeCheckoutFormComponent clientSecret={clientSecret} transactionId={transactionId} />
            </center>
          </div>
        </Elements>
      )
    }
    return (
      <div className="mr-content">
        <center>
          <h2>Discount Code</h2>
          <p>If you have a discount code it must be entered here.</p>
          <form onSubmit={getSecret}>
            <div className="form-field">
              <TextInputFullWidthComponent label="&nbsp;" value={discountCode} onChange={setDiscountCode} id="" placeholder="Discount Code" />
            </div>
            <SingleButtonSmartComponent nextPageClick={getSecret} processing={isTalking} />
          </form>
        </center>
      </div>
    )
  }

  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id="p4s1" />
        {renderContent()}
      </div>
    </div>
  )
}

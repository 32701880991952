import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AddPersonEmailComponent } from '../components/AddPersonEmailComponent'
import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { getItemById } from '../utils/ArrayUtils'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useLocation } from 'react-router-dom'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const RampShippingScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))

  useEffect(() => {
    updateProgress(id ?? '')
    const newQuestion = getItemById(questions, id ?? '')
    setQuestion(newQuestion)
  }, [id])
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <img src={question.icon} alt="" />
            <h2>question.h2Copy</h2>
            <AddPersonEmailComponent />
          </center>
          <ButtonGroupComponent goBack={goBack} nextPageClick={nextPage} label={question.button} />
        </div>
      </div>
    </div>
  )
}

import React, { FC } from 'react'

import { RootState } from '../../Store'
import { ViewAllTextButton } from '../buttons/ViewAllTextButton'
import { useSelector } from 'react-redux'

export const EmpathyMapPanel: FC = () => {
  const { buyerSeekingSummary, buyerSayingSummary, buyerDoingSummary, buyerFeelingSummary } = useSelector((state: RootState) => {
    return {
      buyerSeekingSummary: state.IdealCustomer.buyerSeekingSummary,
      buyerSayingSummary: state.IdealCustomer.buyerSayingSummary,
      buyerDoingSummary: state.IdealCustomer.buyerDoingSummary,
      buyerFeelingSummary: state.IdealCustomer.buyerFeelingSummary
    }
  })
  return (
    <div className="col-md-12">
      <div className="empathy-map">
        <div className="inner-row row">
          <div className="col-md-9">
            <h4>Your Empathy Map</h4>
          </div>
          <ViewAllTextButton onClick={() => console.log('viewAllClick')} isHidden />
          <div className="col-md-12">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td className="fontSize-12">
                    <strong className="bbl-red">What The Buyer Is Seeking</strong>
                    <p>{buyerSeekingSummary}</p>
                  </td>
                  <td className="fontSize-12">
                    <strong className="bbl-red">What The Buyer Is Saying</strong>
                    <p>{buyerSayingSummary}</p>
                  </td>
                </tr>
                <tr>
                  <td className="fontSize-12">
                    <strong className="bbl-red">What The Buyer Is Doing</strong>
                    <p>{buyerDoingSummary}</p>
                  </td>
                  <td className="fontSize-12">
                    <strong className="bbl-red">What The Buyer Is Seeking</strong>
                    <p>{buyerFeelingSummary}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

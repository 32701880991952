import React, { FC } from 'react'

import { DotComponent } from './DotComponent'
import { ProgressSegmentComponent } from './ProgressSegmentComponent'
import { RootState } from '../../Store'
import { RampProgressBarStyles as styles } from './RampProgressBarStyles'
import { useSelector } from 'react-redux'

type Props = {
  id: string
}
export const RampProgressBarComponent: FC<Props> = ({ id }) => {
  const currentPillar = useSelector((state: RootState) => state.ConsumerProject.currentPillar)
  return (
    <div className="progress-ramp-bar" style={styles.container}>
      <div style={styles.backBarProgress}>
        <ProgressSegmentComponent pillar={1} id={id} />
        <ProgressSegmentComponent pillar={2} id={id} />
        <ProgressSegmentComponent pillar={3} id={id} />
      </div>
      <div style={styles.backBar}>
        <DotComponent num={1} isActive={currentPillar === 1} />
        <DotComponent num={2} isActive={currentPillar === 2} />
        <DotComponent num={3} isActive={currentPillar === 3} />
        <DotComponent num={4} isActive={currentPillar === 4} />
      </div>
      <div style={styles.backBarText}>
        <span>
          PILLAR 1:
          <br />
          PEOPLE WE LOVE
        </span>
        <span>
          PILLAR 2:
          <br />
          OUR LOVE LANGUAGE
        </span>
        <span>
          PILLAR 3:
          <br />
          OUR PROPOSAL
        </span>
        <span>
          PILLAR 4:
          <br />
          RELATIONSHIP STAGES
        </span>
      </div>
    </div>
  )
}

import { Middleware, combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'

import adSpecificReducer from './reducers/AdSpecificSlice'
import { brandPitchSlice } from './api/brandPitchSlice'
import { caSlice } from './api/caSlice'
import consumerProjectReducer from './reducers/ConsumerProjectSlice'
import { icSlice } from './api/icSlice'
import idealCustomerReducer from './reducers/IdealCustomerSlice'
import meReducer from './reducers/MeSlice'
import { rampSlice } from './api/rampSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { stripeSlice } from './api/stripeSlice'
import { usersSlice } from './api/usersSlice'

const persistConfig = {
  key: 'version_0001_q',
  storage: storage
}

const rootReducer = combineReducers({
  Me: meReducer,
  IdealCustomer: idealCustomerReducer,
  AdSpecific: adSpecificReducer,
  ConsumerProject: consumerProjectReducer,
  [usersSlice.reducerPath]: usersSlice.reducer,
  [icSlice.reducerPath]: icSlice.reducer,
  [caSlice.reducerPath]: caSlice.reducer,
  [rampSlice.reducerPath]: rampSlice.reducer,
  [brandPitchSlice.reducerPath]: brandPitchSlice.reducer,
  [stripeSlice.reducerPath]: stripeSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const defaultMiddleware = getDefaultMiddleware({ serializableCheck: false })
    const customMiddleware: Middleware[] = [
      usersSlice.middleware,
      icSlice.middleware,
      brandPitchSlice.middleware,
      caSlice.middleware,
      rampSlice.middleware,
      stripeSlice.middleware
    ]
    return defaultMiddleware.concat(customMiddleware)
  }
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

import {
  RAMP_LOGO_MED,
  SI_CASUAL,
  SI_ENTHUSIASTIC,
  SI_FORMAL,
  SI_HUMOROUS,
  SI_IRREVERENT,
  SI_MATTER_OF_FACT,
  SI_RESPECTFUL,
  SI_SERIOUS
} from '../constants/ImageConstants'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { SpectrumSliderComponent } from '../components/spectrumSlider/SpectrumSliderComponent'
import { getItemById } from '../utils/ArrayUtils'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const SpectrumOfFormalityScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))

  const dispatch = useDispatch()
  const { spectrumCasual, spectrumHumorous, spectrumIrreverent, spectrumMatterOfFact } = useSelector((state: RootState) => {
    return {
      spectrumCasual: state.IdealCustomer.spectrumCasual,
      spectrumFormal: state.IdealCustomer.spectrumFormal,
      spectrumHumorous: state.IdealCustomer.spectrumHumorous,
      spectrumSerious: state.IdealCustomer.spectrumSerious,
      spectrumIrreverent: state.IdealCustomer.spectrumIrreverent,
      spectrumRespectful: state.IdealCustomer.spectrumRespectful,
      spectrumEnthusiastic: state.IdealCustomer.spectrumEnthusiastic,
      spectrumMatterOfFact: state.IdealCustomer.spectrumMatterOfFact
    }
  })
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const { isTalking, callServer } = usePillarCommunication()

  const [sliderOneValue, setSliderOneValue] = useState(spectrumCasual)
  const [sliderTwoValue, setSliderTwoValue] = useState(spectrumHumorous)
  const [sliderThreeValue, setSliderThreeValue] = useState(spectrumIrreverent)
  const [sliderFourValue, setSliderFourValue] = useState(spectrumMatterOfFact)
  const nextPageClick = async () => {
    const obj = {
      spectrumCasual: 100 - sliderOneValue,
      spectrumFormal: sliderOneValue,
      spectrumHumorous: 100 - sliderTwoValue,
      spectrumSerious: sliderTwoValue,
      spectrumIrreverent: 100 - sliderThreeValue,
      spectrumRespectful: sliderThreeValue,
      spectrumEnthusiastic: 100 - sliderFourValue,
      spectrumMatterOfFact: sliderFourValue
    }
    const result = await callServer(obj, question.propertyName)
    if (result) {
      dispatch(updateIdeal(obj))
      nextPage()
    }
  }
  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
  }, [id])
  return (
    <div className="mr rmsteps pillar_css spectrum">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>{question.h2Copy}</h2>
            <p>{question.pCopy}</p>
          </center>
          <SpectrumSliderComponent
            leftIcon={SI_CASUAL}
            leftLabel="CASUAL"
            rightIcon={SI_FORMAL}
            rightLabel="FORMAL"
            value={sliderOneValue}
            onChange={setSliderOneValue}
          />
          <SpectrumSliderComponent
            leftIcon={SI_HUMOROUS}
            leftLabel="HUMOROUS"
            rightIcon={SI_SERIOUS}
            rightLabel="SERIOUS"
            value={sliderTwoValue}
            onChange={setSliderTwoValue}
          />
          <SpectrumSliderComponent
            leftIcon={SI_IRREVERENT}
            leftLabel="IRREVERENT"
            rightIcon={SI_RESPECTFUL}
            rightLabel="RESPECTFUL"
            value={sliderThreeValue}
            onChange={setSliderThreeValue}
          />
          <SpectrumSliderComponent
            leftIcon={SI_ENTHUSIASTIC}
            leftLabel="ENTHUSIASTIC"
            rightIcon={SI_MATTER_OF_FACT}
            rightLabel="MATTER OF FACT"
            value={sliderFourValue}
            onChange={setSliderFourValue}
          />
        </div>
        <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
      </div>
    </div>
  )
}

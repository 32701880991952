import { Colors } from '../../constants/Theme'

export const DotStyles = {
  container: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',

    backgroundColor: Colors.red,
    fontFamily: 'Poppins',
    fontSize: 15,
    color: Colors.red,
    fontWeight: 600,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative' as const
  },
  containerInner: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.white
  },
  selectedInner: {
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.white,
    backgroundColor: Colors.red
  }
}

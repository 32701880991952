import { gql } from 'graphql-request'
export const completeTransactionHelper = (id: string) => {
  return gql`
    mutation completeTransaction {
      completeTransaction(transactionId: "${id}") {
        id
      }
    }
  `
}

import { gql } from 'graphql-request'
export const updateCoreServiceThree = (arg: string) => {
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          coreServiceThree: "${arg}",
        }) {
          id
        }
      }
    `
}

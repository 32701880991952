import React, { FC } from 'react'

import { EmpathyData } from '../types/AppTypes'
import { useEmpathy } from '../customHooks/useEmpathy'

type Props = {
  isLeft?: boolean
  title: string
  isActive?: boolean
  body: string
}
export const EmpathyMapBoxComponent: FC<Props> = ({ isLeft, title, isActive, body }: Props) => {
  const mainClass = isLeft ? 'input in1' : 'input in2'
  const nextClass = isActive ? (isLeft ? 'customer_seek cs1 active' : 'customer_seek cs2 active') : isLeft ? 'customer_seek cs1' : 'customer_seek cs2'

  return (
    <div className={mainClass}>
      <div className={nextClass}>
        <div className="cs_label">{title}</div>
        <div className="cs_content">{body}</div>
      </div>
    </div>
  )
}

import { UpdateSpectrum } from '../../types/AppTypes'
import { gql } from 'graphql-request'
export const updateSpectrum = (arg: UpdateSpectrum) => {
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          spectrumCasual: ${arg.spectrumCasual},
          spectrumFormal: ${arg.spectrumFormal},
          spectrumHumorous: ${arg.spectrumHumorous},
          spectrumSerious: ${arg.spectrumSerious},
          spectrumIrreverent: ${arg.spectrumIrreverent},
          spectrumRespectful: ${arg.spectrumRespectful},
          spectrumEnthusiastic: ${arg.spectrumEnthusiastic},
          spectrumMatterOfFact: ${arg.spectrumMatterOfFact}
        }) {
          id
        }
      }
    `
}

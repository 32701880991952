import { FullErrorResponse } from '../types/AppTypes'

export const isErrorToken = (error: FullErrorResponse) => {
  if (
    error &&
    error.status === 401 &&
    error.data &&
    error.data.response &&
    error.data.response.errors &&
    error.data.response.errors.length > 0 &&
    error.data.response.errors[0].errorType === 'UnauthorizedException' &&
    error.data.response.errors[0].message === 'Token has expired.'
  ) {
    return true
  }
  return false
}

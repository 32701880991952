import { REGISTER_ROUTE, VERIFY_ROUTE } from '../constants/RouteConstants'

import { RootState } from '../Store'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const useRegistration = () => {
  const navigate = useNavigate()
  const { registrationSent, signUpSent, verifiedUser } = useSelector((state: RootState) => {
    return {
      registrationSent: state.Me.registrationSent,
      signUpSent: state.Me.signUpSent,
      verifiedUser: state.Me.verifiedUser
    }
  })
  useEffect(() => {
    if (!registrationSent && signUpSent) {
      navigate(REGISTER_ROUTE)
      return
    }
    if (registrationSent && signUpSent) {
      navigate(VERIFY_ROUTE)
      return
    }
    if (verifiedUser) {
      // navigate(VERIFY_ROUTE) TODO: need to go to next route
    }
  }, [])
}

import { gql } from 'graphql-request'
export const buildSayingHelper = () => {
  return gql`
    query completeSaying {
      completeSaying {
        buyerDoingSummary
        buyerFeelingSummary
        buyerSayingSummary
        buyerSeekingSummary
      }
    }
  `
}

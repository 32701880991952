import { ConsumerProjectDef } from '../reducers/ConsumerProjectSlice'
import { buildEmailsStageHelper } from './jsonHelpers/rampHelpers/buildEmailStageHelper'
import { completeRampHelper } from './jsonHelpers/rampHelpers/completeRampHelper'
import { createApi } from '@reduxjs/toolkit/query/react'
import { getRampByIdHelper } from './jsonHelpers/rampHelpers/getRampByIdHelper'
import { getRampEmailStatusHelper } from './jsonHelpers/rampHelpers/getRampEmailStatusHelper'
import { getRampEmailsByStageHelper } from './jsonHelpers/rampHelpers/getRampEmailsByStageHelper'
import { graphqlBaseQuery } from './graphqlBaseQuery'
import { processRamp } from '../utils/RampUtils'
import { updateRampHelper } from './jsonHelpers/rampHelpers/updateRampHelper'

export interface UpdateRampInfo {
  id: string
  pillarOneProgress: string
  pillarTwoProgress: string
  pillarThreeProgress: string
  pillarFourProgress: string
  currentScreenIndex: number
  currentScreenId: string
  currentPillar: number
}
export interface CompleteRampInfo {
  id: string
  rampCompleted: boolean
}
export interface EmailObj {
  rampId: string
  functionName: string
}

export interface EmailStage {
  rampId: string
  stageName: string
}

export interface GetRampByIdResponse {
  getCustomerRampById: ConsumerProjectDef
}
export interface GetRampEmailStatusResponse {
  getCustomerRampEmailStatusId: ConsumerProjectDef
}

const isGetRampByIdResponse = (obj: any): obj is GetRampByIdResponse => {
  const process = 'getCustomerRampById' in obj
  return process
}
const isGetRampEmailStatusResponse = (obj: any): obj is GetRampEmailStatusResponse => {
  const process = 'getCustomerRampEmailStatusId' in obj
  return process
}

export const rampSlice = createApi({
  reducerPath: 'rampApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_SERVER!
  }),
  endpoints: builder => ({
    completeRamp: builder.query<ConsumerProjectDef, CompleteRampInfo>({
      query: data => ({
        body: completeRampHelper(data)
      })
    }),
    updateRamp: builder.query<ConsumerProjectDef, UpdateRampInfo>({
      query: data => ({
        body: updateRampHelper(data)
      })
    }),
    getRampById: builder.query<ConsumerProjectDef, string>({
      query: id => ({
        body: getRampByIdHelper(id)
      }),
      transformResponse: (response: GetRampByIdResponse | ConsumerProjectDef): ConsumerProjectDef =>
        isGetRampByIdResponse(response) ? response.getCustomerRampById : response
    }),
    getRampEmailStatusById: builder.query<ConsumerProjectDef, string>({
      query: id => ({
        body: getRampEmailStatusHelper(id)
      }),
      transformResponse: (response: GetRampEmailStatusResponse | ConsumerProjectDef): ConsumerProjectDef =>
        isGetRampEmailStatusResponse(response) ? response.getCustomerRampEmailStatusId : response
    }),
    createEmails: builder.query<ConsumerProjectDef, EmailObj>({
      query: blob => ({
        body: buildEmailsStageHelper(blob)
      })
    }),
    getRampEmailByStage: builder.query<ConsumerProjectDef, EmailStage>({
      query: obj => ({
        body: getRampEmailsByStageHelper(obj)
      }),
      transformResponse: (response: GetRampByIdResponse | ConsumerProjectDef): ConsumerProjectDef =>
        isGetRampByIdResponse(response) ? processRamp(response.getCustomerRampById) : response
    })
  })
})

export const {
  useLazyUpdateRampQuery,
  useLazyGetRampByIdQuery,
  useLazyGetRampEmailStatusByIdQuery,
  useLazyCreateEmailsQuery,
  useLazyGetRampEmailByStageQuery,
  useLazyCompleteRampQuery
} = rampSlice

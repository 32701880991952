import React, { FC } from 'react'

interface Props {
  onClick: () => void
  isHidden?: boolean
}

export const ViewAllTextButton: FC<Props> = ({ onClick, isHidden = false }: Props) => {
  if (isHidden) return null
  return (
    <div className="col-md-3">
      <a href="#" className="link_red" onClick={onClick}>
        View
      </a>
    </div>
  )
}

import React, { FC } from 'react'

export const RoundIconSVG: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.25" height="29.25" viewBox="0 0 29.25 29.25">
      <path
        fill="#ee3d4a"
        d="M18 3.375A14.625 14.625 0 1032.625 18 14.623 14.623 0 0018 3.375zm6.363 15.75h-5.238v5.238a1.125 1.125 0 01-2.25 0v-5.238h-5.238a1.125 1.125 0 010-2.25h5.238v-5.238a1.125 1.125 0 012.25 0v5.238h5.238a1.125 1.125 0 010 2.25z"
        data-name="Icon ionic-ios-add-circle"
        transform="translate(-3.375 -3.375)"
      />
    </svg>
  )
}

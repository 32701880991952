import '../assets/style.css'

import * as React from 'react'

import { FC, useEffect } from 'react'
import { LOGIN_ROUTE, SIGN_UP_ROUTE } from '../constants/RouteConstants'

import logo from '../assets/ramp-logo-sm.svg'
import { setInitialState as setInitialAdSpecific } from '../reducers/AdSpecificSlice'
import { setInitialState as setInitialConsumer } from '../reducers/ConsumerProjectSlice'
import { setInitialState as setInitialIdealCustomer } from '../reducers/IdealCustomerSlice'
import { setInitialState as setInitialMeSlice } from '../reducers/MeSlice'
import sparkle from '../assets/sparkle.svg'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const HomeScreenComponent: FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loginClick = () => {
    navigate(LOGIN_ROUTE)
  }
  const signUpClick = () => {
    navigate(SIGN_UP_ROUTE)
  }
  useEffect(() => {
    dispatch(setInitialAdSpecific())
    dispatch(setInitialIdealCustomer())
    dispatch(setInitialMeSlice())
    dispatch(setInitialConsumer())
  }, [])
  return (
    <div className="mr mr-login">
      <div className="mr-container">
        <div className="mr-bnr">
          <div className="bnr-head">
            <img className="sparkle" src={sparkle} alt="sparkle" />
            <img className="logo" src={logo} alt="Marketing RAMP" />
          </div>
          <div className="mr-bnr-cont">
            <h1>Create Free Account</h1>
            <div className="btn">
              <a href="#" onClick={signUpClick} className="btn-red">
                BEGIN BY SHARING A BIT ABOUT YOURSELF
              </a>
            </div>
            <p>
              Already have an account?{' '}
              <a href="#" onClick={loginClick}>
                Sign in here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export { HomeScreenComponent }

import { CHAT_ICON, COLLAR_ICON, DEMOGRAPHICS_ICON, EMPATHY_MAP_ONE, FEELING_ICON } from '../../../constants/ImageConstants'
import React, { FC } from 'react'

import { PeopleWeLoveRowComponent } from '../../../components/PeopleWeLoveRowComponent'

export const PeopleWeLoveSceneComponent: FC = () => {
  return (
    <div className="app bg-white ideal_customer">
      <div className="main-content">
        <div className="row">
          <h1 className="mod-title">People We Love</h1>
        </div>
        <div className="row main-row">
          <div className="col-md-12" />
          <div className="col-md-12">
            <div className="row inner-row c_list dashboard_content">
              <div className="col-md-12">
                <h2 className="">Key Parts of People We Love</h2>
              </div>
              <div className="col-md-12">
                <PeopleWeLoveRowComponent title="Demographics" icon={DEMOGRAPHICS_ICON} />
                <PeopleWeLoveRowComponent title="What The Buyer Is Seeking" icon={EMPATHY_MAP_ONE} />
                <PeopleWeLoveRowComponent title="What The Buyer Is Saying" icon={CHAT_ICON} />
                <PeopleWeLoveRowComponent title="What The Buyer Is Doing" icon={COLLAR_ICON} />
                <PeopleWeLoveRowComponent title="What The Buyer Is Feeling" icon={FEELING_ICON} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

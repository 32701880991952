import React, { FC } from 'react'

type Props = {
  label: string
  value: string
  onChange: (arg: string) => void
  placeHolder?: string
}
export const TextAreaFormField: FC<Props> = ({ label, value, onChange, placeHolder = 'Enter Your Answer' }: Props) => {
  const changeAnswer = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className="form-field fw">
      <label htmlFor="acc-name">{label}</label>
      <textarea id="acc-name" cols={30} rows={10} placeholder={placeHolder} value={value} onChange={changeAnswer} />
    </div>
  )
}

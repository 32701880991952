import React, { FC } from 'react'

type Props = {
  value: string
  isLeft?: boolean
  placeholder?: string
  type?: 'tel' | 'url' | 'text' | 'email' | 'password' | 'number'
  icon?: string
  onChange: (arg: string) => void
  onBlur?: () => void
}
export const TextInputIconLeftRightComponent: FC<Props> = ({
  isLeft,
  value,
  icon,
  type = 'text',
  onChange,
  placeholder,
  onBlur = () => {
    /** */
  }
}: Props) => {
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  const renderIcon = () => {
    if (icon) {
      return (
        <span className="icon">
          <img src={icon} alt="" />
        </span>
      )
    }
    return null
  }
  return (
    <div className={isLeft ? 'input in1' : 'input in2'}>
      {renderIcon()}
      <input id="title" type={type} value={value} placeholder={placeholder ?? ''} onChange={update} />
    </div>
  )
}

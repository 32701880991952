import { gql } from 'graphql-request'
export const painPointTwoHelper = (point: string, solution: string) => {
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          painPointTwo: "${point}",
          painPointTwoSolution: "${solution}",
        }) {
          id
        }
      }
    `
}

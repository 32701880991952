import { gql } from 'graphql-request'
export const updateNameGender = (customerName: string, gender: string) => {
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          customerName: "${customerName}",
          gender: "${gender}",
        }) {
          id
        }
      }
    `
}

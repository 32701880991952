import { gql } from 'graphql-request'
export const buildPainPointsOvercomeHelper = (idealCustomerId: string) => {
  return gql`
    query setPainPointSolutions {
      setPainPointSolutions (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

import { FullErrorResponse, Question, ScreenTracker } from '../types/AppTypes'
import { createScreensFromQuestions, getPillarProgress, getPillarQuestions } from '../utils/QuestionUtils'
import { lastScreen, nextScreen, updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../Store'
import { isErrorToken } from '../utils/ErrorUtils'
import { questions } from '../data/Questions'
import { useLazyUpdateRampQuery } from '../api/rampSlice'
import { useNavigate } from 'react-router-dom'
import { useSession } from './useSession'

// import { useState } from 'react'
interface Def {
  nextPage: () => void
  goBack: () => void
  initRamp: () => void
  skipClick: (newId: string) => void
  updateProgress: (arg: string, ar2?: boolean) => void
  getProgressPage: (arg: string) => string
}

export const useScreenNavigation = (): Def => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentScreenIndex = useSelector((state: RootState) => state.ConsumerProject.currentScreenIndex)
  const [updateRamp] = useLazyUpdateRampQuery()
  const {
    id,
    currentPillar,
    currentScreenId,
    pillarOneQuestions,
    pillarTwoQuestions,
    pillarThreeQuestions,
    pillarFourQuestions,
    pillarOneProgress,
    pillarTwoProgress,
    pillarThreeProgress,
    pillarFourProgress,
    nextScreenInfo,
    previousScreenInfo,
    screens
  } = useSelector((state: RootState) => {
    return {
      id: state.ConsumerProject.id,
      currentScreenId: state.ConsumerProject.currentScreenId,
      currentPillar: state.ConsumerProject.currentPillar,
      pillarOneQuestions: state.ConsumerProject.pillarOneQuestions,
      pillarTwoQuestions: state.ConsumerProject.pillarTwoQuestions,
      pillarThreeQuestions: state.ConsumerProject.pillarThreeQuestions,
      pillarFourQuestions: state.ConsumerProject.pillarFourQuestions,
      pillarOneProgress: state.ConsumerProject.pillarOneProgress,
      pillarTwoProgress: state.ConsumerProject.pillarTwoProgress,
      pillarThreeProgress: state.ConsumerProject.pillarThreeProgress,
      pillarFourProgress: state.ConsumerProject.pillarFourProgress,
      nextScreenInfo: state.ConsumerProject.screens[currentScreenIndex + 1],
      previousScreenInfo: state.ConsumerProject.screens[currentScreenIndex - 1],
      screens: state.ConsumerProject.screens
    }
  })
  const nextPage = () => {
    // check to see if the nextRoute has query String shit.
    const update = {
      pillarOneProgress,
      pillarTwoProgress,
      pillarThreeProgress,
      pillarFourProgress
    }
    switch (nextScreenInfo.pillar) {
      case 1:
        update.pillarOneProgress = getPillarProgress(nextScreenInfo.questionId!, pillarOneQuestions)
        break
      case 2:
        update.pillarTwoProgress = getPillarProgress(nextScreenInfo.questionId!, pillarTwoQuestions)
        break
      case 3:
        update.pillarThreeProgress = getPillarProgress(nextScreenInfo.questionId!, pillarThreeQuestions)
        break
      case 4:
        update.pillarFourProgress = getPillarProgress(nextScreenInfo.questionId!, pillarFourQuestions)
        break
    }
    // console.log('NExt screen info', nextScreenInfo)
    dispatch(updateCustomer(update))
    navigate(nextScreenInfo.route)
    dispatch(nextScreen())
  }
  const goBack = () => {
    navigate(previousScreenInfo.route)
    dispatch(lastScreen())
  }
  const skipClick = (newId: string) => {
    //
  }
  const getProgressPage = (arg: string): string => {
    const screen = screens.find(item => item.questionId === arg)
    return screen?.route ?? ''
  }

  const initRamp = () => {
    const { pillarOneQuestions, pillarTwoQuestions, pillarThreeQuestions, pillarFourQuestions } = getPillarQuestions(questions)
    dispatch(
      updateCustomer({
        questions,
        screens: createScreensFromQuestions(questions),
        pillarOneQuestions,
        pillarTwoQuestions,
        pillarThreeQuestions,
        pillarFourQuestions,
        pillarOneTotalSlides: pillarOneQuestions.length,
        pillarTwoTotalSlides: pillarTwoQuestions.length,
        pillarThreeTotalSlides: pillarThreeQuestions.length,
        pillarFourTotalSlides: pillarFourQuestions.length,
        rampCompleted: false
      })
    )
  }

  const updateProgress = async (csId: string, addOneToIndex = false) => {
    const obj = {
      id,
      currentPillar,
      currentScreenIndex: addOneToIndex ? currentScreenIndex + 1 : currentScreenIndex,
      pillarOneProgress,
      pillarTwoProgress,
      pillarThreeProgress,
      pillarFourProgress,
      currentScreenId: csId
    }
    dispatch(updateCustomer({ currentScreenId: csId }))
    try {
      const result = await updateRamp(obj).unwrap()
      console.log('Result updateProgress', result)
    } catch (errorResponse) {
      console.log('we have an error', errorResponse)
      const error = errorResponse as FullErrorResponse
      if (isErrorToken(error)) {
        // we should navigate to Login.
        console.log('Its a token problem do something')
      }
    }
  }
  return { initRamp, nextPage, goBack, updateProgress, getProgressPage, skipClick }
}

import { EmpathyData, EmpathyQuestionData, SecretSauce, UpdateSpectrum } from '../types/AppTypes'

import { IdealCustomerDef } from '../reducers/IdealCustomerSlice'
import { buildDoingHelper } from './jsonHelpers/buildDoingHelper'
import { buildFeelingHelper } from './jsonHelpers/buildFeelingHelper'
import { buildSayingHelper } from './jsonHelpers/buildSayingHelper'
import { buildSeekingHelper } from './jsonHelpers/buildSeekingHelper'
import { createApi } from '@reduxjs/toolkit/query/react'
import { getEmpathyMapSummaries } from './jsonHelpers/getEmpathyMapHelper'
import { getIdealCustomerByIdHelper } from './jsonHelpers/getIdealCustomerByIdHelper'
import { gql } from 'graphql-request'
import { graphqlBaseQuery } from './graphqlBaseQuery'
import { objectionOneHelper } from './jsonHelpers/objectionOneHelper'
import { objectionThreeHelper } from './jsonHelpers/objectionThreeHelper'
import { objectionTwoHelper } from './jsonHelpers/objectionTwoHelper'
import { painPointOneHelper } from './jsonHelpers/painPointOneHelper'
import { painPointTwoHelper } from './jsonHelpers/painPointTwoHelper'
import { processIdealCustomerRaw } from '../utils/IdealCustomerUtil'
import { updateAgeRange } from './jsonHelpers/updateAgeRangeHelper'
import { updateAvatar } from './jsonHelpers/updateAvatarHelper'
import { updateBuyerDoing } from './jsonHelpers/updateBuyerDoingHelper'
import { updateBuyerFeeling } from './jsonHelpers/updateBuyerFeelingHelper'
import { updateBuyerSaying } from './jsonHelpers/updateBuyerSayingHelper'
import { updateBuyerSeeking } from './jsonHelpers/updateBuyerSeekingHelper'
import { updateChildren } from './jsonHelpers/updateChildrenHelper'
import { updateCorePurposeFive } from './jsonHelpers/updateCorePurposeFiveHelper'
import { updateCorePurposeFour } from './jsonHelpers/updateCorePurposeFourHelper'
import { updateCorePurposeOne } from './jsonHelpers/updateCorePurposeOneHelper'
import { updateCorePurposeThree } from './jsonHelpers/updateCorePurposeThreeHelper'
import { updateCorePurposeTwo } from './jsonHelpers/updateCorePurposeTwoHelper'
import { updateCoreServiceOne } from './jsonHelpers/updateCoreServiceOneHelper'
import { updateCoreServiceThree } from './jsonHelpers/updateCoreServiceThreeHelper'
import { updateCoreServiceTwo } from './jsonHelpers/updateCoreServiceTwoHelper'
import { updateEducation } from './jsonHelpers/updateEducationHelper'
import { updateEthnicity } from './jsonHelpers/updateEthnicityHelper'
import { updateIncome } from './jsonHelpers/updateIncomeHelper'
import { updateMaritalStatus } from './jsonHelpers/updateMaritalStatusHelper'
import { updateModeOfCommunicationFour } from './jsonHelpers/updateModeOfCommunicationFourHelper'
import { updateModeOfCommunicationOne } from './jsonHelpers/updateModeOfCommunicationOneHelper'
import { updateModeOfCommunicationThree } from './jsonHelpers/updateModeOfCommunicationThreeHelper'
import { updateModeOfCommunicationTwo } from './jsonHelpers/updateModeOfCommunicationTwoHelper'
import { updateNameGender } from './jsonHelpers/updateNameGenderHelper'
import { updateOwnRent } from './jsonHelpers/updateOwnRentHelper'
import { updatePets } from './jsonHelpers/updatePetsHelper'
import { updateResidence } from './jsonHelpers/updateResidenceHelper'
import { updateSecretSauce } from './jsonHelpers/updateSecretSauceHelper'
import { updateSpectrum } from './jsonHelpers/updateSpectrumOfFormalityHelper'
import { updateSuccessStepsOne } from './jsonHelpers/updateSuccessStepOneHelper'
import { updateSuccessStepsThree } from './jsonHelpers/updateSuccessStepThree'
import { updateSuccessStepsTwo } from './jsonHelpers/updateSuccessStepTwoHelper'

export interface AgeUpdate {
  start: number
  end: number
  ageRange: string
}
export interface ChildrenUpdate {
  hasChildren: string
  numberOfChildren: number
  childrenAgeRange: string[] | string
}
export interface PetsUpdate {
  hasPets: string
  pets: string[] | string
}

export interface NameGenderUpdate {
  customerName: string
  gender: string
}

export interface PainPointObjection {
  problem: string
  solution: string
}

interface UpdateIdealCustomer {
  customerName: string
  avatar: string
  ageRangeStart: number
  ageRangeEnd: number
  gender: string
  maritalStatus: string
  hasChildren: string
  numberOfChildren: number
  childrenAgeRange: string[] | string
  hasPets: string
  pets: string[]
  education: string[]
  ethnicity: string
  houseHoldIncome: string
  ownRent: string
  residence: string
  buyerSeeking: EmpathyQuestionData[]
  buyerSaying: EmpathyQuestionData[]
  buyerDoing: EmpathyQuestionData[]
  buyerFeeling: EmpathyQuestionData[]
  buyerSeekingSummary: string
  buyerSayingSummery: string
  buyerDoingSummary: string
  buyerFeelingSummary: string
  spectrumCasual: number
  spectrumFormal: number
  spectrumHumorous: number
  spectrumSerious: number
  spectrumIrreverent: number
  spectrumRespectful: number
  spectrumEnthusiastic: number
  spectrumMatterOfFact: number
  companyCorePurposeOne: string
  companyCorePurposeTwo: string
  companyCorePurposeThree: string
  companyCorePurposeFour: string
  companyCorePurposeFive: string
  coreServiceOne: string
  coreServiceTwo: string
  coreServiceThree: string
  modeOfCommunicationOne: string
  modeOfCommunicationTwo: string
  modeOfCommunicationThree: string
  modeOfCommunicationFour: string
  successStepOne: string
  successStepTwo: string
  successStepThree: string
  secretSauce: SecretSauce[]
}

export interface GetIdealCustomerResponse {
  getIdealCustomerById: IdealCustomerDef
}
const isGetIdealCustomerByIdResponse = (obj: any): obj is GetIdealCustomerResponse => {
  const process = 'getIdealCustomerById' in obj
  console.log('Process this', process)
  return process
}

export const icSlice = createApi({
  reducerPath: 'idealCustomerApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_SERVER!
  }),
  endpoints: builder => ({
    getIdealCustomer: builder.query<IdealCustomerDef, string>({
      query: id => ({
        body: getIdealCustomerByIdHelper(id)
      }),
      transformResponse: (response: GetIdealCustomerResponse | IdealCustomerDef): IdealCustomerDef =>
        isGetIdealCustomerByIdResponse(response) ? processIdealCustomerRaw(response.getIdealCustomerById) : response
    }),
    updateAgeRange: builder.query<IdealCustomerDef, AgeUpdate>({
      query: blob => ({
        body: updateAgeRange(blob.start, blob.end, blob.ageRange)
      })
    }),
    updateMaritalStatus: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateMaritalStatus(blob)
      })
    }),
    updateChildren: builder.query<IdealCustomerDef, ChildrenUpdate>({
      query: blob => ({
        body: updateChildren(blob)
      })
    }),
    updatePets: builder.query<IdealCustomerDef, PetsUpdate>({
      query: blob => ({
        body: updatePets(blob)
      })
    }),
    updateEducation: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateEducation(blob)
      })
    }),
    updateEthnicity: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateEthnicity(blob)
      })
    }),
    updateIncome: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateIncome(blob)
      })
    }),
    updateOwnRent: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateOwnRent(blob)
      })
    }),
    updateResidence: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateResidence(blob)
      })
    }),
    updateNameGender: builder.query<IdealCustomerDef, NameGenderUpdate>({
      query: blob => ({
        body: updateNameGender(blob.customerName, blob.gender)
      })
    }),
    updateAvatar: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateAvatar(blob)
      })
    }),
    updateBuyerIsSeeking: builder.query<IdealCustomerDef, EmpathyQuestionData[]>({
      query: blob => ({
        body: updateBuyerSeeking(blob)
      })
    }),
    updateBuyerIsSaying: builder.query<IdealCustomerDef, EmpathyQuestionData[]>({
      query: blob => ({
        body: updateBuyerSaying(blob)
      })
    }),
    updatePainPointOne: builder.query<IdealCustomerDef, PainPointObjection>({
      query: blob => ({
        body: painPointOneHelper(blob.problem, blob.solution)
      })
    }),
    updatePainPointTwo: builder.query<IdealCustomerDef, PainPointObjection>({
      query: blob => ({
        body: painPointTwoHelper(blob.problem, blob.solution)
      })
    }),
    updateObjectionOne: builder.query<IdealCustomerDef, PainPointObjection>({
      query: blob => ({
        body: objectionOneHelper(blob.problem, blob.solution)
      })
    }),
    updateObjectionTwo: builder.query<IdealCustomerDef, PainPointObjection>({
      query: blob => ({
        body: objectionTwoHelper(blob.problem, blob.solution)
      })
    }),
    updateObjectionThree: builder.query<IdealCustomerDef, PainPointObjection>({
      query: blob => ({
        body: objectionThreeHelper(blob.problem, blob.solution)
      })
    }),
    updateBuyerIsDoing: builder.query<IdealCustomerDef, EmpathyQuestionData[]>({
      query: blob => ({
        body: updateBuyerDoing(blob)
      })
    }),
    updateBuyerIsFeeling: builder.query<IdealCustomerDef, EmpathyQuestionData[]>({
      query: blob => ({
        body: updateBuyerFeeling(blob)
      })
    }),
    updateSpectrumOfFormality: builder.query<IdealCustomerDef, UpdateSpectrum>({
      query: blob => ({
        body: updateSpectrum(blob)
      })
    }),
    updateCompanyCorePurposeOne: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCorePurposeOne(blob)
      })
    }),
    updateCompanyCorePurposeTwo: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCorePurposeTwo(blob)
      })
    }),
    updateCompanyCorePurposeThree: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCorePurposeThree(blob)
      })
    }),
    updateCompanyCorePurposeFour: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCorePurposeFour(blob)
      })
    }),
    updateCompanyCorePurposeFive: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCorePurposeFive(blob)
      })
    }),
    updateCoreServiceOne: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCoreServiceOne(blob)
      })
    }),
    updateCoreServiceTwo: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCoreServiceTwo(blob)
      })
    }),
    updateCoreServiceThree: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateCoreServiceThree(blob)
      })
    }),
    updateModeOfCommunicationOne: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateModeOfCommunicationOne(blob)
      })
    }),
    updateModeOfCommunicationTwo: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateModeOfCommunicationTwo(blob)
      })
    }),
    updateModeOfCommunicationThree: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateModeOfCommunicationThree(blob)
      })
    }),
    updateModeOfCommunicationFour: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateModeOfCommunicationFour(blob)
      })
    }),
    updateSuccessStepOne: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateSuccessStepsOne(blob)
      })
    }),
    updateSuccessStepTwo: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateSuccessStepsTwo(blob)
      })
    }),
    updateSuccessStepThree: builder.query<IdealCustomerDef, string>({
      query: blob => ({
        body: updateSuccessStepsThree(blob)
      })
    }),
    buildSeeking: builder.query({
      query: () => ({
        body: buildSeekingHelper()
      })
    }),
    buildSaying: builder.query({
      query: () => ({
        body: buildSayingHelper()
      })
    }),
    buildDoing: builder.query({
      query: () => ({
        body: buildDoingHelper()
      })
    }),
    buildFeeling: builder.query({
      query: () => ({
        body: buildFeelingHelper()
      })
    }),
    getEmpathyMapData: builder.query<IdealCustomerDef, number>({
      query: () => ({
        body: getEmpathyMapSummaries()
      })
    }),
    updateSecretSauce: builder.query<IdealCustomerDef, SecretSauce[]>({
      query: blob => ({
        body: updateSecretSauce(blob)
      })
    })
  })
})
export const {
  useLazyGetIdealCustomerQuery,
  useLazyUpdateAgeRangeQuery,
  useLazyUpdateMaritalStatusQuery,
  useLazyUpdateChildrenQuery,
  useLazyUpdatePetsQuery,
  useLazyUpdateEducationQuery,
  useLazyUpdateEthnicityQuery,
  useLazyUpdateIncomeQuery,
  useLazyUpdateOwnRentQuery,
  useLazyUpdateResidenceQuery,
  useLazyUpdateNameGenderQuery,
  useLazyUpdateAvatarQuery,
  useLazyUpdateBuyerIsSayingQuery,
  useLazyUpdatePainPointOneQuery,
  useLazyUpdatePainPointTwoQuery,
  useLazyUpdateObjectionOneQuery,
  useLazyUpdateObjectionTwoQuery,
  useLazyUpdateObjectionThreeQuery,
  useLazyUpdateBuyerIsSeekingQuery,
  useLazyUpdateBuyerIsDoingQuery,
  useLazyUpdateBuyerIsFeelingQuery,
  useLazyUpdateSpectrumOfFormalityQuery,
  useLazyUpdateCompanyCorePurposeOneQuery,
  useLazyUpdateCompanyCorePurposeTwoQuery,
  useLazyUpdateCompanyCorePurposeThreeQuery,
  useLazyUpdateCompanyCorePurposeFourQuery,
  useLazyUpdateCompanyCorePurposeFiveQuery,
  useLazyUpdateCoreServiceOneQuery,
  useLazyUpdateCoreServiceTwoQuery,
  useLazyUpdateCoreServiceThreeQuery,
  useLazyUpdateModeOfCommunicationOneQuery,
  useLazyUpdateModeOfCommunicationTwoQuery,
  useLazyUpdateModeOfCommunicationThreeQuery,
  useLazyUpdateModeOfCommunicationFourQuery,
  useLazyUpdateSuccessStepOneQuery,
  useLazyUpdateSuccessStepTwoQuery,
  useLazyUpdateSuccessStepThreeQuery,
  useLazyGetEmpathyMapDataQuery,
  useLazyUpdateSecretSauceQuery,
  useLazyBuildDoingQuery,
  useLazyBuildFeelingQuery,
  useLazyBuildSayingQuery,
  useLazyBuildSeekingQuery
} = icSlice

import React, { FC } from 'react'

import { AccountCurrentPlanComponent } from '../../../components/AccountCurrentPlanComponent'
import { AccountInfoPaneComponent } from '../../../components/AccountInfoPaneComponent'

export const AccountOverviewSceneComponent: FC = () => {
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Account</h1>
      </div>
      <div className="row main-row">
        <AccountInfoPaneComponent />
        <AccountCurrentPlanComponent />
      </div>
    </div>
  )
}

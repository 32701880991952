import { INDUSTRY, REVENUE, STATES, TITLES } from '../constants/DropDownConstants'

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSlice } from '@reduxjs/toolkit'

export interface MeDef {
  email: string
  firstName: string
  id: string
  jwtToken: string
  refreshToken: string
  lastName: string
  password: string
  type: string
  companyName: string
  companyWebsite: string
  title: string
  industry: string
  revenue: string
  country: string
  state: string
  phone: string
  passwordResetInProgress: boolean
  startPasswordRecovery: boolean
  signUpSent: boolean
  registrationSent: boolean
  verifiedUser: boolean
  ramps: string[]
}

const getInitialState = (): MeDef => {
  return {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    jwtToken: '',
    refreshToken: '',
    password: '',
    type: 'customer',
    companyName: '',
    companyWebsite: '',
    title: TITLES[0].value,
    industry: INDUSTRY[0].value,
    country: 'United States',
    state: STATES[0].value,
    revenue: REVENUE[0].value,
    phone: '',
    passwordResetInProgress: false,
    startPasswordRecovery: false,
    signUpSent: false,
    registrationSent: false,
    verifiedUser: false,
    ramps: []
  }
}

export const meSlice = createSlice({
  name: 'Me',
  initialState: getInitialState(),
  reducers: {
    setInitialState: state => {
      return getInitialState()
    },
    initializeMe: (state, action) => {
      state.id = action.payload.id
    },
    updateMe: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload
      }
    },
    startResettingPassword: (state: MeDef) => {
      state.passwordResetInProgress = true
    },
    expireToken: (state: MeDef) => {
      state.jwtToken = ''
    },
    startPasswordRecovery: (state: MeDef) => {
      state.startPasswordRecovery = true
    },
    resetStartPasswordRecovery: (state: MeDef) => {
      state.startPasswordRecovery = false
      state.passwordResetInProgress = false
    }
  }
})

export const {
  setInitialState,
  initializeMe,
  updateMe,
  expireToken,
  startResettingPassword,
  startPasswordRecovery,
  resetStartPasswordRecovery
} = meSlice.actions

export default meSlice.reducer

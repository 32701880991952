import React, { FC } from 'react'

type Props = {
  label: string
  description: string
  superPower: string
  competitorOne: string
  competitorTwo: string
  isSelected: boolean
  onChange: (arg: string) => void
  setSuperPower: (arg: string) => void
  setCompetitorOne: (arg: string) => void
  setCompetitorTwo: (arg: string) => void
}
export const SecretSauceItemComponent: FC<Props> = ({
  label,
  description,
  isSelected,
  onChange,
  superPower,
  competitorOne,
  competitorTwo,
  setSuperPower,
  setCompetitorOne,
  setCompetitorTwo
}: Props) => {
  const onClick = () => {
    console.log('HEre lets try', label)
    onChange(label)
  }
  const updateSuperPower = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuperPower(event.target.value)
  }
  const updateCompetitorOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompetitorOne(event.target.value)
  }
  const updateCompetitorTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompetitorTwo(event.target.value)
  }
  const renderInput = () => {
    if (isSelected) {
      return (
        <>
          <div className="ss_hidden_field">
            <input type="text" name="desc" placeholder="Why this is one of your superpowers?" value={superPower} onChange={updateSuperPower} />
          </div>
          <div className="ss_hidden_field">
            <input
              type="text"
              name="desc"
              placeholder="How does your number one competitor handle this?"
              value={competitorOne}
              onChange={updateCompetitorOne}
            />
          </div>
          <div className="ss_hidden_field">
            <input
              type="text"
              name="desc"
              placeholder="How does your number two competitor handle this?"
              value={competitorTwo}
              onChange={updateCompetitorTwo}
            />
          </div>
        </>
      )
    }
    return null
  }
  return (
    <div className="form-field fw">
      <div className="radio-buttons" onClick={onClick}>
        <input type="radio" name={label} id={label} value="small" checked={isSelected} onChange={onClick} />
        <label htmlFor="time">{label}</label>
      </div>
      <div className="ss_desc">
        <p>{description}</p>
      </div>
      {renderInput()}
    </div>
  )
}

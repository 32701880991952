import { AD_5, AD_7 } from '../../../../constants/AdSpecificConstants'
import React, { FC } from 'react'

import { AppDispatch } from '../../../../Store'
import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { RampDocTableComponent } from '../../../../components/marketingRampDocTable/RampDocTableComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'
import { updateQuestion } from '../../../../reducers/AdSpecificSlice'
import { useDispatch } from 'react-redux'

export const AdSpecificSixPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const next = () => {
    dispatch(updateQuestion(AD_7))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_5))
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Your Marketing RAMP Documents</h1>
      </div>
      <RampDocTableComponent />
      <div className="col-md-12">
        <div className="grp-btns">
          <TransparentButtonComponent onClick={goBack} />
          <NextButtonComponent onClick={next} />
        </div>
      </div>
    </div>
  )
}

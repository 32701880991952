import { gql } from 'graphql-request'
export const buildFeelingHelper = () => {
  return gql`
    query completeFeeling {
      completeFeeling {
        buyerDoingSummary
        buyerFeelingSummary
        buyerSayingSummary
        buyerSeekingSummary
      }
    }
  `
}

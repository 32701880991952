import { AD_1, AD_10, AD_11, AD_2, AD_3, AD_4, AD_5, AD_6, AD_7, AD_8, AD_9 } from '../../constants/AdSpecificConstants'
import React, { FC } from 'react'

import { AdSpecificEightPanel } from './scenes/adSpecific/AdSpecificEightPanel'
import { AdSpecificElevenPanel } from './scenes/adSpecific/AdSpecificElevenPanel'
import { AdSpecificFivePanel } from './scenes/adSpecific/AdSpecificFivePanel'
import { AdSpecificFourPanel } from './scenes/adSpecific/AdSpecificFourPanel'
import { AdSpecificNinePanel } from './scenes/adSpecific/AdSpecificNinePanel'
import { AdSpecificOnePanel } from './scenes/adSpecific/AdSpecificOnePanel'
import { AdSpecificSevenPanel } from './scenes/adSpecific/AdSpecificSevenPanel'
import { AdSpecificSixPanel } from './scenes/adSpecific/AdSpecificSixPanel'
import { AdSpecificTenPanel } from './scenes/adSpecific/AdSpecificTenPanel'
import { AdSpecificThreePanel } from './scenes/adSpecific/AdSpecificThreePanel'
import { AdSpecificTwoPanel } from './scenes/adSpecific/AdSpecificTwoPanel'
import { EightStepNavigationComponent } from '../../components/EightStepNavigationComponent'
import { RootState } from '../../Store'
import { useSelector } from 'react-redux'

export const EightStepProcessScreenComponent: FC = () => {
  const currentPanel = useSelector((state: RootState) => state.AdSpecific.currentQuestionScreen)
  const renderPanel = () => {
    switch (currentPanel) {
      case AD_1:
        return <AdSpecificOnePanel />
      case AD_2:
        return <AdSpecificTwoPanel />
      case AD_3:
        return <AdSpecificThreePanel />
      case AD_4:
        return <AdSpecificFourPanel />
      case AD_5:
        return <AdSpecificFivePanel />
      case AD_6:
        return <AdSpecificSixPanel />
      case AD_7:
        return <AdSpecificSevenPanel />
      case AD_8:
        return <AdSpecificEightPanel />
      case AD_9:
        return <AdSpecificNinePanel />
      case AD_10:
        return <AdSpecificTenPanel />
      case AD_11:
        return <AdSpecificElevenPanel />
    }
  }
  return (
    <div className="app bg-white help_center">
      <EightStepNavigationComponent />
      {renderPanel()}
    </div>
  )
}

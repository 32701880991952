import { AD_1, AD_10, AD_11, AD_2, AD_3, AD_4, AD_5, AD_6, AD_7, AD_8, AD_9 } from '../constants/AdSpecificConstants'
import React, { FC } from 'react'

import { RootState } from '../Store'
import { SMALL_LOGO } from '../constants/ImageConstants'
import { StepNavigationItemComponent } from './dashboard/StepNavigationItemComponent'
import { useSelector } from 'react-redux'

export const EightStepNavigationComponent: FC = () => {
  const progressMeter = useSelector((state: RootState) => state.AdSpecific.progressMeter)
  const currentQuestionScreen = useSelector((state: RootState) => state.AdSpecific.currentQuestionScreen)
  return (
    <div className="nav ad_specific">
      <img src={SMALL_LOGO} alt="RAMP" />

      <div className="v-progress">
        <ul>
          <StepNavigationItemComponent isMain number={1} title="AD - Specific Questions" isComplete={progressMeter[AD_2]} />
          <StepNavigationItemComponent inProgress={currentQuestionScreen === AD_1} isComplete={progressMeter[AD_1]} title="Step 1" />
          <StepNavigationItemComponent inProgress={currentQuestionScreen === AD_2} isComplete={progressMeter[AD_2]} title="Step 2" />
          <StepNavigationItemComponent isMain number={2} title="B2B Ideal Target Business Supplementary Questions" isComplete={progressMeter[AD_4]} />
          <StepNavigationItemComponent inProgress={currentQuestionScreen === AD_3} isComplete={progressMeter[AD_3]} title="Step 1" />
          <StepNavigationItemComponent inProgress={currentQuestionScreen === AD_4} isComplete={progressMeter[AD_4]} title="Step 2" />
          <StepNavigationItemComponent isMain number={3} title="My Account" isComplete={progressMeter[AD_5]} />
          <StepNavigationItemComponent isMain number={4} title="Your Marketing RAMP Documents" isComplete={progressMeter[AD_6]} />
          <StepNavigationItemComponent isMain number={5} title="Identify Your Ideal Customer" isComplete={progressMeter[AD_8]} />
          <StepNavigationItemComponent inProgress={currentQuestionScreen === AD_7} isComplete={progressMeter[AD_7]} title="Customer 1" />
          <StepNavigationItemComponent inProgress={currentQuestionScreen === AD_8} isComplete={progressMeter[AD_8]} title="Customer 2" />
          <StepNavigationItemComponent isMain number={6} title="Interests" isComplete={progressMeter[AD_9]} />
          <StepNavigationItemComponent isMain number={7} title="Pain Points" isComplete={progressMeter[AD_10]} />
          <StepNavigationItemComponent isMain number={8} title="Their Relationship To You" isComplete={progressMeter[AD_11]} />
        </ul>
      </div>
    </div>
  )
}

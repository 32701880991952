import React, { FC } from 'react'
import { BACK_BTN_ARROW } from '../../constants/ImageConstants'

type Props = {
  label: string
  isBack?: boolean
  onClick: () => void
}
export const PagingNextBackButtonComponent: FC<Props> = ({ label, isBack, onClick }: Props) => {
  const renderSpan = () => {
    if (isBack) {
      return <span aria-hidden="true">&laquo;</span>
    }
    return <span aria-hidden="true">&raquo;</span>
  }
  return (
    <li className="page-item">
      <a className="page-link" href="#" aria-label={label} onClick={onClick}>
        <img className="btn_icon" src={BACK_BTN_ARROW} />
        {renderSpan()}
      </a>
    </li>
  )
}

import { Question, SecretSauce } from '../types/AppTypes'
import React, { FC, useEffect, useState } from 'react'
import { getItemById, pushReplace } from '../utils/ArrayUtils'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { SecretSauceItemComponent } from '../components/secretSauceItem/SecretSauceItemComponent'
import { addToSecretSauce } from '../reducers/IdealCustomerSlice'
import { getSecretSauceData } from '../utils/QuestionUtils'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const SecretSauceScreenV2Component: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const location = useLocation()
  const currentPath = location.pathname

  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const { additionalFunctionCall, isTalking, callServer } = usePillarCommunication()
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const { startValue, startSuperPower, startCompetitorOne, startCompetitorTwo } = useSelector((state: RootState) => {
    const data = getSecretSauceData(state.IdealCustomer[question.propertyName], id ?? '')
    return {
      startValue: data.answer,
      startSuperPower: data.superPower,
      startCompetitorOne: data.competitorOne,
      startCompetitorTwo: data.competitorTwo
    }
  })
  const secretSauceList = useSelector((state: RootState) => state.IdealCustomer.secretSauce)
  const [answerValue, setAnswerValue] = useState(startValue)
  const [superPower, setSuperPower] = useState(startSuperPower)
  const [competitorOne, setCompetitorOne] = useState(startCompetitorOne)
  const [competitorTwo, setCompetitorTwo] = useState(startCompetitorTwo)
  const excludeValues: string[] = useSelector((state: RootState) => {
    if (currentPath.includes('secretSauceOne')) {
      return []
    }
    if (currentPath.includes('secretSauceTwo')) {
      return state.IdealCustomer[question.propertyName][0].answer
    }
    return [state.IdealCustomer[question.propertyName][0].answer, state.IdealCustomer[question.propertyName][1].answer]
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }

  const nextPageClick = async () => {
    const obj = {
      id: id!,
      answer: answerValue,
      superPower,
      competitorOne,
      competitorTwo
    }
    const newArray = pushReplace(secretSauceList as SecretSauce[], obj)
    const result = await callServer(newArray, question.propertyName)
    if (result) {
      dispatch(addToSecretSauce(newArray))
      nextPage()
    }
  }

  const changeSelection = (arg: string) => {
    console.log('new ', arg)
    setSuperPower('')
    setCompetitorOne('')
    setCompetitorTwo('')
    setAnswerValue(arg)
  }

  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
    setAnswerValue(newQuestion.options[newQuestion.defaultAnswerIndex])
    if (newQuestion.functionCall) {
      additionalFunctionCall(newQuestion.functionCall)
    }
  }, [id])

  useEffect(() => {
    setAnswerValue(startValue)
  }, [question.h2Copy])

  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />

        <div className="mr-content">
          <div className="secret_sauce">
            <center>
              <h2>{question.h2Copy}</h2>
            </center>
            <div className="form-elements">
              <form data-hs-cf-bound="true" onSubmit={handleSubmit}>
                <div className="form-fields">
                  {excludeValues.includes('Time') ? null : (
                    <SecretSauceItemComponent
                      label="Time"
                      description="The ability to deliver faster/shorter than the competition."
                      isSelected={answerValue === 'Time'}
                      onChange={changeSelection}
                      superPower={superPower}
                      competitorOne={competitorOne}
                      competitorTwo={competitorTwo}
                      setSuperPower={setSuperPower}
                      setCompetitorOne={setCompetitorOne}
                      setCompetitorTwo={setCompetitorTwo}
                    />
                  )}
                  {excludeValues.includes('Price') ? null : (
                    <SecretSauceItemComponent
                      label="Price"
                      description="Pricing that competitors cannot match."
                      isSelected={answerValue === 'Price'}
                      onChange={changeSelection}
                      superPower={superPower}
                      competitorOne={competitorOne}
                      competitorTwo={competitorTwo}
                      setSuperPower={setSuperPower}
                      setCompetitorOne={setCompetitorOne}
                      setCompetitorTwo={setCompetitorTwo}
                    />
                  )}
                  {excludeValues.includes('Unique Features') ? null : (
                    <SecretSauceItemComponent
                      label="Unique Features"
                      description="Features that are specialized and/or custom to your product/service."
                      isSelected={answerValue === 'Unique Features'}
                      onChange={changeSelection}
                      superPower={superPower}
                      competitorOne={competitorOne}
                      competitorTwo={competitorTwo}
                      setSuperPower={setSuperPower}
                      setCompetitorOne={setCompetitorOne}
                      setCompetitorTwo={setCompetitorTwo}
                    />
                  )}
                  {excludeValues.includes('Innovation') ? null : (
                    <SecretSauceItemComponent
                      label="Innovation"
                      description="Cutting edge product/service or technology addressing problems with novel solutions."
                      isSelected={answerValue === 'Innovation'}
                      onChange={changeSelection}
                      superPower={superPower}
                      competitorOne={competitorOne}
                      competitorTwo={competitorTwo}
                      setSuperPower={setSuperPower}
                      setCompetitorOne={setCompetitorOne}
                      setCompetitorTwo={setCompetitorTwo}
                    />
                  )}
                  {excludeValues.includes('Experience') ? null : (
                    <SecretSauceItemComponent
                      label="Experience"
                      description="Positive customer experiences that drive loyalty and are unique to your brand."
                      isSelected={answerValue === 'Experience'}
                      onChange={changeSelection}
                      superPower={superPower}
                      competitorOne={competitorOne}
                      competitorTwo={competitorTwo}
                      setSuperPower={setSuperPower}
                      setCompetitorOne={setCompetitorOne}
                      setCompetitorTwo={setCompetitorTwo}
                    />
                  )}
                  {excludeValues.includes('Success') ? null : (
                    <SecretSauceItemComponent
                      label="Success"
                      description="Assurance of customer success that is hard to find anywhere else."
                      isSelected={answerValue === 'Success'}
                      onChange={changeSelection}
                      superPower={superPower}
                      competitorOne={competitorOne}
                      competitorTwo={competitorTwo}
                      setSuperPower={setSuperPower}
                      setCompetitorOne={setCompetitorOne}
                      setCompetitorTwo={setCompetitorTwo}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
          <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
        </div>
      </div>
    </div>
  )
}

import { AD_3, AD_5 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import { answerQuestionEleven, answerQuestionTwelve, updateQuestion } from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { TextAreaFormField } from '../../../../components/TextAreaFormFieldComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificFourPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { answerEleven, answerTwelve } = useSelector((state: RootState) => {
    return {
      answerEleven: state.AdSpecific.questionEleven,
      answerTwelve: state.AdSpecific.questionTwelve
    }
  })
  const next = () => {
    dispatch(updateQuestion(AD_5))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_3))
  }
  const changeAnswerEleven = (arg: string) => {
    dispatch(answerQuestionEleven(arg))
  }
  const changeAnswerTwelve = (arg: string) => {
    dispatch(answerQuestionTwelve(arg))
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">B2B Ideal Target Business Supplementary Questions</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <TextAreaFormField label="Who are their main competitors?" value={answerEleven} onChange={changeAnswerEleven} />
              <TextAreaFormField label="What sets them apart from their competition?" value={answerTwelve} onChange={changeAnswerTwelve} />

              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { FC } from 'react'

import { LoadingIconComponent } from '../loadingIcon/LoadingIconComponent'
import { NEXT_BTN_ARROW_DARK } from '../../constants/ImageConstants'
import { NextButtonComponent } from './NextButtonComponent'
import { TransparentButtonComponent } from './TransparentButtonComponent'

type Props = {
  goBack?: () => void
  nextPageClick?: () => void
  skipClick?: () => void
  label?: string
  processing?: boolean
  useSkip?: boolean
  noWhiteArrow?: boolean
  backOnly?: boolean
  nextOnly?: boolean
}
export const ButtonGroupComponent: FC<Props> = ({
  label = 'Next',
  goBack = () => console.log('goBack'),
  nextPageClick = () => console.log('next'),
  skipClick = () => console.log('skipped'),
  processing = false,
  useSkip = false,
  noWhiteArrow = false,
  backOnly = false,
  nextOnly = false
}: Props) => {
  if (processing) {
    return (
      <div className="grp-btns">
        <LoadingIconComponent />
      </div>
    )
  }
  if (nextOnly) {
    return (
      <div className="grp-btns">
        <NextButtonComponent onClick={nextPageClick} label={label} />
      </div>
    )
  }
  if (backOnly) {
    return (
      <div className="grp-btns">
        <TransparentButtonComponent onClick={goBack} />
      </div>
    )
  }
  if (useSkip) {
    return (
      <div className="grp-btns">
        <TransparentButtonComponent onClick={goBack} />
        <NextButtonComponent onClick={nextPageClick} label={label} noWhiteArrow={noWhiteArrow} />
        <TransparentButtonComponent onClick={skipClick} label="Next" icon={NEXT_BTN_ARROW_DARK} isNext />
      </div>
    )
  }
  return (
    <div className="grp-btns">
      <TransparentButtonComponent onClick={goBack} />
      <NextButtonComponent onClick={nextPageClick} label={label} />
    </div>
  )
}

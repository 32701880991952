import React, { ChangeEvent, FC } from 'react'

type Option = {
  label: string
  value: string
}

type Props = {
  label: string
  options: Option[]
  value: string
  onChange: (value: string) => void
}

export const SelectionComponent: FC<Props> = ({ label, options, value, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value)
  }

  return (
    <div className="inputContainer">
      <label className="label" htmlFor="cm-state">
        {label}
      </label>
      <select name="state" id="cm-state" value={value} onChange={handleChange}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

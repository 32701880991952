import React, { FC } from 'react'

import { RoundIconSVG } from '../../svgs/RoundIconSVG'

type Props = {
  onClick: () => void
}
export const RoundButtonComponent: FC<Props> = ({ onClick }: Props) => {
  return (
    <button id="add" className="btn add-more button-yellow uppercase" type="button" onClick={onClick}>
      <RoundIconSVG />
    </button>
  )
}

import '../assets/style.css'

import * as React from 'react'

import { FC } from 'react'
import { IdealCustomerPersonComponent } from './IdealCustomerPersonComponent'
import { RootState } from '../Store'
import { ViewAllTextButton } from './buttons/ViewAllTextButton'
import { useSelector } from 'react-redux'

const IdealCustomerPaneComponent: FC = () => {
  const { customerName, avatar } = useSelector((state: RootState) => state.IdealCustomer)
  return (
    <div className="col-md-5">
      <div className="inner-row row">
        <div className="col-md-9">
          <h4>Your Ideal Customer</h4>
        </div>
        <ViewAllTextButton onClick={() => console.log('viewAllClick')} isHidden />
        <div className="list-contacts">
          <div className="row g-2">
            <div className="col-md-9">
              <IdealCustomerPersonComponent name={customerName} icon={avatar} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { IdealCustomerPaneComponent }

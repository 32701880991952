import { PASSWORD_ICON, RAMP_LOGO_BIG, USERNAME_ICON } from '../constants/ImageConstants'
import React, { FC } from 'react'

import { LOGIN_ROUTE } from '../constants/RouteConstants'
import { LoadingIconComponent } from '../components/loadingIcon/LoadingIconComponent'
import { TextInputIconFWComponent } from '../components/TextInputIconFWComponent'
import { TextInputIconLeftRightComponent } from '../components/TextInputIconLeftRightComponent'
import { useCognito } from '../customHooks/useCognito'
import { useNavigate } from 'react-router-dom'
import { useRegistration } from '../customHooks/useRegistration'

export const SignUpScreenComponent: FC = () => {
  useRegistration()
  const navigate = useNavigate()
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    password,
    setPassword,
    register,
    errorMessage,
    submittingRegistration
  } = useCognito()

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    // This will prevent the default form submission action
    event.preventDefault()
    /// validate email // validate password.
    register()
  }
  const renderError = () => {
    if (errorMessage) {
      return <p>{errorMessage}</p>
    }
    return null
  }

  const gotoLogin = () => {
    navigate(LOGIN_ROUTE)
  }

  return (
    <div className="mr mr-login">
      <div className="mr-container">
        <div className="mr-bnr">
          <div className="mr-bnr-cont">
            <h1>Sign Up</h1>
            <div className="bnr-head">
              <img className="logo" src={RAMP_LOGO_BIG} alt="Marketing RAMP" />
            </div>
            <div className="form-elements login-screen">
              <form onSubmit={submit}>
                <div className="form-fields">
                  <div className="form-field duo-input">
                    <TextInputIconLeftRightComponent value={firstName} onChange={setFirstName} placeholder="Firstname" isLeft icon={USERNAME_ICON} />
                    <TextInputIconLeftRightComponent value={lastName} onChange={setLastName} placeholder="Lastname" icon={USERNAME_ICON} />
                  </div>
                  <TextInputIconFWComponent type="email" value={email} onChange={setEmail} placeholder="Email" icon={USERNAME_ICON} />
                  <TextInputIconFWComponent type="password" value={password} onChange={setPassword} placeholder="Password" icon={PASSWORD_ICON} />
                  {renderError()}
                  {submittingRegistration ? <LoadingIconComponent /> : <input type="submit" className="btn-red" value="SIGN UP" />}
                </div>
              </form>
            </div>

            {submittingRegistration ? null : (
              <p>
                Already a member?{' '}
                <a href="#" onClick={gotoLogin}>
                  Login
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

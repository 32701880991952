import { LL_COMPANY_ALIGN, LL_COMPANY_CORE } from '../../../constants/ImageConstants'
import React, { FC } from 'react'

import { PeopleWeLoveRowComponent } from '../../../components/PeopleWeLoveRowComponent'

export const OurProposalSceneComponent: FC = () => {
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Our Proposal</h1>
      </div>
      <div className="row main-row">
        <div className="col-md-12" />
        <div className="col-md-12">
          <div className="row inner-row c_list dashboard_content">
            <div className="col-md-12">
              <h2 className="">Key Parts of Our Proposal</h2>
            </div>
            <div className="col-md-12">
              <PeopleWeLoveRowComponent title="Secret Sauce" icon={LL_COMPANY_CORE} />
              <PeopleWeLoveRowComponent title="Brand Pitch" icon={LL_COMPANY_ALIGN} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

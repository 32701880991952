import React, { FC, useEffect, useRef } from 'react'

import { Draggable } from 'gsap/Draggable'
import { gsap } from 'gsap'

gsap.registerPlugin(Draggable)

type Props = {
  leftIcon: string
  leftLabel: string
  rightIcon: string
  rightLabel: string
  value: number
  onChange: (arg: number) => void
}

export const SpectrumSliderComponent: FC<Props> = ({ leftIcon, leftLabel, rightIcon, rightLabel, value, onChange }: Props) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)

  const snapPoints = [0, 25, 50, 75, 100]

  useEffect(() => {
    if (sliderRef?.current?.offsetWidth) {
      const initialValuePixel = (value / 100) * sliderRef.current.offsetWidth
      gsap.set(thumbRef.current, { x: initialValuePixel })

      Draggable.create(thumbRef.current, {
        type: 'x',
        bounds: sliderRef.current,
        onDrag: function() {
          // Handle drag event
        },
        onDragEnd: function() {
          // Handle drag end event
          const nearestSnapPoint = snapPoints.reduce((prev, curr) => {
            return Math.abs(curr - (this.x / sliderRef.current!.offsetWidth) * 100) < Math.abs(prev - (this.x / sliderRef.current!.offsetWidth) * 100)
              ? curr
              : prev
          }, 0)

          const newValue = (nearestSnapPoint / 100) * sliderRef.current!.offsetWidth
          gsap.to(thumbRef.current, { x: newValue })

          onChange(nearestSnapPoint)
        }
      })
    }
  }, [value])

  return (
    <div className="range-slider">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '720px' }}>
        <div className="range_slider_level" style={{ margin: '0 25px', width: '200px', textAlign: 'center' }}>
          <img src={leftIcon} alt="" />
          <span>{leftLabel}</span>
        </div>
        <div
          className="slider-bar"
          ref={sliderRef}
          style={{ width: 'calc(100% - (73px))', height: '10px', background: 'linear-gradient(45deg, #3d92c6, #ee3d4a)' }}
        >
          <div
            ref={thumbRef}
            className="slider-thumb"
            style={{ position: 'relative', width: '20px', height: '20px', borderRadius: '50%', background: '#2c3e50', cursor: 'pointer', top: '-5px' }}
          />
        </div>
        <div className="range_slider_level" style={{ margin: '0 25px', width: '200px', textAlign: 'center' }}>
          <span>{rightLabel}</span>
          <img src={rightIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

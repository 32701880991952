import '../../assets/style.css'

import * as React from 'react'

import { ACCOUNT, DASHBOARD, HELP_CENTER, LOVE_LANGUAGE, PEOPLE_WE_LOVE, PROPOSAL, RELATIONSHIP_STAGES } from '../../constants/SceneKeys'
import { FC, useEffect, useState } from 'react'

import { AccountOverviewSceneComponent } from './scenes/AccountOverviewSceneComponent'
import { AdminNavigationComponent } from '../../components/AdminNavigationComponent'
import { DashboardSceneComponent } from './scenes/DashboardSceneComponent'
import { HelpCenterSceneComponent } from './scenes/HelpCenterSceneComponent'
import { LoveLanguageSceneComponent } from './scenes/LoveLanguageSceneComponent'
import { OurProposalSceneComponent } from './scenes/OuProposalSceneComponent'
import { PeopleWeLoveSceneComponent } from './scenes/PeopleWeLoveSceneComponent'
import { RelationshipStagesSceneComponent } from './scenes/RelationshipStagesSceneComponent'
import { removeHash } from '../../utils/StringUtils'
import { useLocation } from 'react-router-dom'

const AdminMainScreenComponent: FC = () => {
  const location = useLocation()
  const [currentScene, setCurrentScene] = useState(DASHBOARD)
  useEffect(() => {
    const hash = removeHash(location.hash)
    setCurrentScene(hash === '' ? DASHBOARD : hash)
  }, [location.hash])

  const renderContent = () => {
    switch (currentScene) {
      case DASHBOARD:
        return <DashboardSceneComponent />
      case PEOPLE_WE_LOVE:
        return <PeopleWeLoveSceneComponent />
      case LOVE_LANGUAGE:
        return <LoveLanguageSceneComponent />
      case PROPOSAL:
        return <OurProposalSceneComponent />
      case RELATIONSHIP_STAGES:
        return <RelationshipStagesSceneComponent />
      case HELP_CENTER:
        return <HelpCenterSceneComponent />
      case ACCOUNT:
        return <AccountOverviewSceneComponent />
    }
  }
  return (
    <div className="app bg-white">
      <AdminNavigationComponent />
      {renderContent()}
    </div>
  )
}

export { AdminMainScreenComponent }

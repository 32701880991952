export const WELCOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const SIGN_UP_ROUTE = '/signup'
export const REGISTER_ROUTE = '/registration'
export const VERIFY_ROUTE = '/verify'
export const DASHBOARD_ROUTE = '/dashboard'
export const DASHBOARD_STEP_BY_STEP = '/dashboardStepByStep'
export const INIT_EXPLAINER_ROUTE = '/initExplainer'
export const CODE_CONFIRMED_ROUTE = '/codeConfirmed'
export const PILLAR_START_ROUTE = '/pillarStart'
export const AGE_RANGE = '/ageRange'
export const CHOOSE_AVATAR = '/choseAvatar'

export const PRIMARY_GENDERS = '/primaryGender'

export const MARITAL_STATUS = '/maritalStatus'
export const CUSTOMER_CHILDREN = '/children'
export const PETS = '/pets'
export const EDUCATION = '/education'
export const ETHNICITY_ROUTE = '/ethnicity'
export const HOUSEHOLD_INCOME = '/income'
export const OWN_RENT = '/ownRent'
export const RESIDENCE = '/reside'
export const FINISH_DEMOGRAPHICS = '/finishDemographics'
export const WHAT_BUYER_IS_SEEKING_INTRO = '/buyerSeekingIntro'
export const WHAT_BUYER_IS_SEEKING = '/buyerSeeking'
export const WHAT_BUYER_IS_SEEKING_CB = '/buyerSeekingCB'
export const WHAT_BUYER_IS_SAYING_INTRO = '/buyerSayingIntro'
export const WHAT_BUYER_IS_SAYING = '/buyerSaying'
export const WHAT_BUYER_IS_SAYING_PAIN = '/buyerSayingPain'
export const WHAT_BUYER_IS_DOING_INTRO = '/buyerIsDoingIntro'
export const WHAT_BUYER_IS_DOING = '/buyerIsDoing'
export const WHAT_BUYER_IS_FEELING_INTRO = '/buyerFeelingIntro'
export const WHAT_BUYER_IS_FEELING = '/buyerFeeling'
export const WHAT_BUYER_IS_FEELING_CB = '/buyerFeelingCB'
export const WHAT_BUYER_IS_FEELING_CB_TEXT = '/buyerFeelingCBText'
export const WHAT_BUYER_IS_FEELING_YES_NO = '/buyerFeelingYesNo'
export const PILLAR_COMPLETE = '/pillarComplete'
export const EMPATHY_MAP = '/empathyMap'
export const SPECTRUM = '/spectrumOfFormality'
export const COMPANY_PURPOSE = '/companyPurpose'
export const COMPANY_PURPOSE_YES_NO = '/companyPurposeYesNo'
export const CORE_SERVICES_START = '/coreServicesStart'
export const CORE_SERVICES = '/coreServices'
export const CORE_SERVICES_SL = '/coreServicesSingleLine'
export const PREFERRED_MODE_COMMUNICATION_START = '/prefModeCommStart'
export const PREFERRED_MODE_COMMUNICATION = '/prefModeComm'
export const STEPS_TO_SUCCESS_START = '/stepsToSuccessStart'
export const STEPS_TO_SUCCESS = '/stepsToSuccess'
export const STEPS_TO_SUCCESS_COMPLETE = '/stepsToSuccessComplete'
export const CUSTOMER_ALIGNMENT_START = '/customerAlignmentStart'
export const CUSTOMER_ALIGNMENT = '/customerAlignment'
export const SECRET_SAUCE_START = '/secretSauceStart'
export const SECRET_SAUCE_VIDEO = '/secretSauceVideo'
export const SECRET_SAUCE_ONE = '/secretSauceOne'
export const SECRET_SAUCE_TWO = '/secretSauceTwo'
export const SECRET_SAUCE_THREE = '/secretSauceThree'
export const BRAND_PITCH_GPT = '/brandPitchGPT'
export const BRAND_PITCH = '/brandPitch'
export const UPGRADE_ROUTE = '/upgrade'
export const UPGRADE_PAY_ROUTE = '/upgradePay'
export const AI_IN_PROGRESS = '/gptInProgress'
export const VIDEO_WATCHED = '/videoWatched'
export const RAMP_COMPLETION = '/rampCompletion'
export const RAMP_EDITING = '/rampEditing'
export const RAMP_REVIEW = '/rampReview'
export const RAMP_SHIPPING = '/rampShipping'
export const UP_SELL_1 = '/upSell1'
export const UP_SELL_2 = '/upSell2'

export const PARTNER_RESULTS = '/partnerResults'
export const PARTNER_HIRED = '/partnerHired'
export const NEXT_STEPS = '/nextSteps'

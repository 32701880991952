import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import { changeObjectionFour, changeObjectionOne, changeObjectionThree, changeObjectionTwo, updateQuestion } from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { AD_10 } from '../../../../constants/AdSpecificConstants'
import { TextAreaLeftRightComponent } from '../../../../components/TextAreaLeftRightComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificElevenPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { objectionOne, objectionTwo, objectionThree, objectionFour } = useSelector((state: RootState) => {
    return {
      objectionOne: state.AdSpecific.objectionOne,
      objectionTwo: state.AdSpecific.objectionTwo,
      objectionThree: state.AdSpecific.objectionThree,
      objectionFour: state.AdSpecific.objectionFour
    }
  })
  const goBack = () => {
    dispatch(updateQuestion(AD_10))
  }
  const changeObOne = (arg: string) => {
    dispatch(changeObjectionOne(arg))
  }
  const changeObTwo = (arg: string) => {
    dispatch(changeObjectionTwo(arg))
  }
  const changeObThree = (arg: string) => {
    dispatch(changeObjectionThree(arg))
  }
  const changeObFour = (arg: string) => {
    dispatch(changeObjectionFour(arg))
  }

  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Their Relationship To You</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <div className="form-field duo-input">
                <TextAreaLeftRightComponent
                  value={objectionOne}
                  label="Objection to the Sale #1:"
                  placeholder="Enter Your Answer"
                  isLeft
                  onChange={changeObOne}
                />
                <TextAreaLeftRightComponent value={objectionTwo} label="Objection to the Sale #2:" placeholder="Enter Your Answer" onChange={changeObTwo} />
              </div>
              <div className="form-field duo-input">
                <TextAreaLeftRightComponent
                  value={objectionThree}
                  label="Objection to the Sale #3:"
                  placeholder="Enter Your Answer"
                  isLeft
                  onChange={changeObThree}
                />
                <TextAreaLeftRightComponent value={objectionFour} label="Objection to the Sale #4:" placeholder="Enter Your Answer" onChange={changeObFour} />
              </div>

              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import getCognitoUser from './getCognitoUser'

export const resendCognitoCode = async (username: string): Promise<any> => {
  return await new Promise(function(resolve, reject) {
    const currentUser = getCognitoUser(username)
    currentUser.resendConfirmationCode(function(err, result) {
      if (err) {
        resolve(err.message || JSON.stringify(err))
      }
      resolve(result)
    })
  }).catch(err => {
    return err
  })
}

export default resendCognitoCode

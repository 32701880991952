import { gql } from 'graphql-request'
export const getUserByIdHelper = (userId: string) => {
  return gql`
  query getUserById{
    getUserById(userId: "${userId}") {
      id
      firstName
      lastName
      email
      type
      ramps
      companyName
      companyWebsite
      title
      industry
      revenue
      country
      state
    }
  }
  `
}

import { CustomerAlignment } from '../api/caSlice'
import { IdealCustomerDef } from '../reducers/IdealCustomerSlice'

export const processIdealCustomerRaw = (arg: IdealCustomerDef) => {
  return {
    ...arg,
    childrenAgeRange: JSON.parse(arg.childrenAgeRange as string),
    pets: JSON.parse(arg.pets as string),
    buyerSeeking: JSON.parse(arg.buyerSeeking as string),
    buyerSaying: JSON.parse(arg.buyerSaying as string),
    buyerDoing: JSON.parse(arg.buyerDoing as string),
    buyerFeeling: JSON.parse(arg.buyerFeeling as string),
    secretSauce: JSON.parse(arg.secretSauce as string)
  }
}

export const isCustomerAlignmentDone = (result: CustomerAlignment) => {
  console.log('Here is the result ', result)
  for (const key in result) {
    const item: string = result[key as keyof CustomerAlignment]
    console.log('item')
    if (item.includes('Holder')) {
      return false
    }
  }
  return true
}

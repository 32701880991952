import React, { FC } from 'react'

import { PageNumberComponent } from '../buttons/PageNumberComponent'
import { PagingNextBackButtonComponent } from '../buttons/PagingNextBackButtonComponent'

type Props = {
  currentPage: number
  changePage: (arg: number) => void
  totalPages: number
}
export const PagingComponent: FC<Props> = ({ currentPage, changePage, totalPages }: Props) => {
  const goBack = () => {
    if (currentPage > 1) {
      changePage(currentPage - 1)
    }
  }
  const next = () => {
    if (currentPage < totalPages) {
      changePage(currentPage + 1)
    }
  }
  const renderPageItems = () => {
    const numberItems = []
    for (let i = 0; i < totalPages; i++) {
      const p = i + 1
      numberItems.push(<PageNumberComponent isSelected={currentPage === p} num={p} onClick={changePage} />)
    }
    return numberItems
  }
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <PagingNextBackButtonComponent label="Previous" isBack onClick={goBack} />
        {renderPageItems()}
        <PagingNextBackButtonComponent label="Next" onClick={next} />
      </ul>
    </nav>
  )
}

import * as LottiePlayer from '@lottiefiles/lottie-player'

import React, { FC, useEffect, useState } from 'react'

import { NextButtonComponent } from '../components/buttons/NextButtonComponent'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RootState } from '../Store'
import { getItemById } from '../utils/ArrayUtils'
import { insertNames } from '../utils/StringUtils'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'
import { useSelector } from 'react-redux'

export const PillarCompletionScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)

  const { nextPage } = useScreenNavigation()
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)
  const [question] = useState<Question>(getItemById(questions, id ?? ''))
  const { additionalFunctionCall } = usePillarCommunication()
  // So this is where we ask it to contact server and do a synopsis.

  useEffect(() => {
    if (question.functionCall) {
      additionalFunctionCall(question.functionCall)
    }
  }, [question.pillar])
  const renderImage = () => {
    console.log('Help', question)
    if (question.icon) {
      return <img src={question.icon} alt="asdasdasd" />
    }
    if (question.gif) {
      console.log('Have gif', question.gif)
      return (
        <div className="lottie_container">
          <lottie-player loop autoplay background="transparent" speed="0.5" style={{ width: 600, height: 650 }} src={question.gif} />
        </div>
      )
    }
    return null
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <div className="mr-content">
          <center>
            {renderImage()}
            <h2>{insertNames(question.h2Copy, firstName, idealCustomerName)}</h2>
            <p dangerouslySetInnerHTML={{ __html: insertNames(question.pCopy ?? '', firstName, idealCustomerName) }} />
            <div className="btn">
              <NextButtonComponent onClick={nextPage} label={question.button} />
            </div>
          </center>
        </div>
      </div>
    </div>
  )
}

import React, { FC, useState } from 'react'

import { Colors } from '../constants/Theme'
import { LoadingIconComponent } from './loadingIcon/LoadingIconComponent'
import { StageEmail } from '../types/AppTypes'
import { ThreeDots } from 'react-loader-spinner'
import { createEmailText } from '../utils/UserUtils'

type Props = {
  icon: string
  title: string
  bigText: StageEmail[]
}
export const RelationshipStageRowComponent: FC<Props> = ({ icon, title, bigText }: Props) => {
  const [isDownloading, setIsDownLoading] = useState(false)
  const onClick = () => {
    if (isDownloading) return
    console.log('onClick')
    // setIsDownLoading(true)
    console.log('bigText', bigText)
    const formattedEmails = createEmailText(bigText)
    // Create a Blob containing the text
    const blob = new Blob([formattedEmails], { type: 'text/plain' })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary anchor element
    const a = document.createElement('a')
    a.href = url
    a.download = `Ramp_${title}.txt` // Set the file name

    // Programmatically click the anchor to trigger the download
    document.body.appendChild(a)
    a.click()

    // Clean up
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  const renderInside = () => {
    if (isDownloading) {
      return (
        <div className="customer-row">
          <div className="c_img" style={{ flexShrink: 1, minWidth: 50 }}>
            <ThreeDots
              height="30"
              width="50"
              radius="9"
              color={Colors.loader}
              ariaLabel="three-dots-loading"
              wrapperStyle={{ width: '100%', display: 'flex', flexDirection: 'column' }}
              wrapperClass=""
              visible
            />
          </div>
          <div className="c_info" style={{ flex: 1, textAlign: 'left' }}>
            {`Downloading ${title}`}
          </div>
        </div>
      )
    }
    return (
      <div className="customer-row">
        <div className="c_img" style={{ backgroundImage: `url(${icon})`, flexShrink: 1 }} />
        <div className="c_info" style={{ flex: 1, textAlign: 'left' }}>
          {title}
        </div>
        <div className="c_info" style={{ flexShrink: 1 }}>
          DOWNLOAD
        </div>
      </div>
    )
  }
  return (
    <div className="stage-row mb-3" onClick={onClick}>
      {renderInside()}
    </div>
  )
}

import { COUNTRIES, INDUSTRY, REVENUE, STATES, TITLES } from '../constants/DropDownConstants'
import { RESET_CIRCLE, SMALL_LOGO } from '../constants/ImageConstants'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../Store'
import { SelectionComponent } from '../components/SelectionComponent'
import { TextInputContainerComponent } from '../components/TextInputContainerComponent'
import { VERIFY_ROUTE } from '../constants/RouteConstants'
import { updateMe } from '../reducers/MeSlice'
import { useNavigate } from 'react-router-dom'

export const RegistrationScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { fullName, mobilePhone, email, companyName, country, state, title, revenue, industry } = useSelector((state: RootState) => {
    return {
      fullName: state.Me.firstName + ' ' + state.Me.lastName,
      mobilePhone: state.Me.phone,
      email: state.Me.email,
      companyName: state.Me.companyName,
      country: state.Me.country,
      state: state.Me.state,
      title: state.Me.title,
      revenue: state.Me.revenue,
      industry: state.Me.industry
    }
  })
  const [phone, setPhone] = useState(mobilePhone)
  const [company, setCompany] = useState(companyName)
  const [selectedCountry, setSelectedCountry] = useState(country)
  const [selectedState, setSelectedState] = useState(state)
  const [selectedRevenue, setSelectedRevenue] = useState(revenue)
  const [selectedIndustry, setSelectedIndustry] = useState(industry)
  const [selectedTitle, setSelectedTitle] = useState(title)

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(
      updateMe({
        phone,
        title: selectedTitle,
        industry: selectedIndustry,
        country: selectedCountry,
        state: selectedState,
        companyName: company
      })
    )
    navigate(VERIFY_ROUTE)
  }

  return (
    <div className="mr rmsteps">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={SMALL_LOGO} alt="Marketing RAMP" />
        </div>
        <form onSubmit={submit}>
          <div className="form-container">
            <h2>Start by telling us a bit about yourself</h2>
            <div className="mr-row">
              <div className="mr-col-6">
                <TextInputContainerComponent label="Name*" placeholder="Type here" value={fullName} isDisabled onChange={() => console.log('noChange')} />
              </div>
              <div className="mr-col-6">
                <TextInputContainerComponent
                  label="Email*"
                  type="email"
                  placeholder="name@domain.com"
                  value={email}
                  isDisabled
                  onChange={() => console.log('noChange')}
                />
              </div>
              <div className="mr-col-6">
                <TextInputContainerComponent label="Phone*" type="tel" placeholder="Type here" value={phone} onChange={setPhone} />
              </div>
            </div>
          </div>
          <div className="form-container">
            <h2>Tell us about your company</h2>
            <span className="sub-title">Company Info</span>
            <div className="divider" />
            <div className="mr-row">
              <div className="mr-col-12">
                <TextInputContainerComponent label="Company Name*" placeholder="Type here" value={company} onChange={setCompany} />
              </div>
              <div className="mr-col-6">
                <SelectionComponent value={selectedIndustry} options={INDUSTRY} label="Industry" onChange={setSelectedIndustry} />
              </div>
              <div className="mr-col-6">
                <SelectionComponent value={selectedTitle} options={TITLES} label="Title" onChange={setSelectedTitle} />
              </div>
              <div className="mr-col-6">
                <SelectionComponent value={selectedCountry} options={COUNTRIES} label="Country" onChange={setSelectedCountry} />
              </div>
              <div className="mr-col-6">
                {selectedCountry === 'United States' ? (
                  <SelectionComponent value={selectedState} options={STATES} label="State" onChange={setSelectedState} />
                ) : null}
              </div>
              <div className="mr-col-12">
                <SelectionComponent value={selectedRevenue} options={REVENUE} label="Revenue" onChange={setSelectedRevenue} />
              </div>
              <div className="mr-col-3">
                <a className="reset-btn" href="#">
                  {' '}
                  <img src={RESET_CIRCLE} alt="" /> Reset Status
                </a>
              </div>
              <div className="mr-col-7">
                <div className="inputContainer">
                  <input type="submit" className="submit-btn" value="NOW IT’S TIME TO GET YOUR CONFIRMATION CODE" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

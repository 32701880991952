import '../assets/style.css'

import * as React from 'react'

import { ACCOUNT, DASHBOARD, HELP_CENTER, LOVE_LANGUAGE, PEOPLE_WE_LOVE, PROPOSAL, RELATIONSHIP_STAGES } from '../constants/SceneKeys'
import {
  ACCOUNT_ICON,
  DASHBOARD_ICON,
  HELP_ICON,
  MENU_LANGUAGE_ICON,
  MENU_LOVE_ICON,
  MENU_PROPOSAL_ICON,
  MENU_RELATIONSHIP_ICON,
  SMALL_LOGO
} from '../constants/ImageConstants'
import { FC, useEffect, useState } from 'react'

import { NavigationButtonComponent } from './NavigationButtonComponent'
import { removeHash } from '../utils/StringUtils'
import { useLocation } from 'react-router-dom'

const AdminNavigationComponent: FC = () => {
  const location = useLocation()
  const [currentScene, setCurrentScene] = useState(DASHBOARD)
  useEffect(() => {
    const hash = removeHash(location.hash)
    setCurrentScene(hash === '' ? DASHBOARD : hash)
  }, [location.hash])
  return (
    <div className="nav">
      <img src={SMALL_LOGO} alt="RAMP" />
      <ul className="main-nav">
        <NavigationButtonComponent label="Dashboard" value={DASHBOARD} icon={DASHBOARD_ICON} isActive={currentScene === DASHBOARD} />
        <NavigationButtonComponent label="People We Love" value={PEOPLE_WE_LOVE} icon={MENU_LOVE_ICON} isActive={currentScene === PEOPLE_WE_LOVE} />
        <NavigationButtonComponent label="Our Love Language" value={LOVE_LANGUAGE} icon={MENU_LANGUAGE_ICON} isActive={currentScene === LOVE_LANGUAGE} />
        <NavigationButtonComponent label="Our Proposal" value={PROPOSAL} icon={MENU_PROPOSAL_ICON} isActive={currentScene === PROPOSAL} />
        <NavigationButtonComponent
          label="Relationship Stages"
          value={RELATIONSHIP_STAGES}
          icon={MENU_RELATIONSHIP_ICON}
          isActive={currentScene === RELATIONSHIP_STAGES}
        />
      </ul>
      <ul className="btm-nav">
        <NavigationButtonComponent label="Help Center" value={HELP_CENTER} icon={HELP_ICON} isActive={currentScene === HELP_CENTER} />
        <NavigationButtonComponent label="Account" value={ACCOUNT} icon={ACCOUNT_ICON} isActive={currentScene === ACCOUNT} />
      </ul>
    </div>
  )
}

export { AdminNavigationComponent }

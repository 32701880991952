import { gql } from 'graphql-request'
export const buildWeAlignHelper = (idealCustomerId: string) => {
  return gql`
    query setWeAlign {
      setWeAlign (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

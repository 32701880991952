import React, { FC, useEffect, useState } from 'react'

import { DocumentRowComponent } from './DocumentRowComponent'
import { PagingComponent } from './PagingComponent'
import { RampDocument } from '../../types/AppTypes'
import { paginate } from '../../utils/ArrayUtils'

const data: RampDocument[] = [
  { id: 'asd', name: 'Item 1', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 2', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 3', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 4', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 5', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 6', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 7', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 8', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 9', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 10', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 11', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 12', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 13', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 14', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 15', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 16', score: '80', role: 'owner', status: 'Semi-Final' },
  { id: 'asd', name: 'Item 17', score: '80', role: 'owner', status: 'Semi-Final' }
]

// Document data { name, score, role, status}
// rowsPerPage
// totalPages
// current page
//
const ITEMS_PER_PAGE = 5
export const RampDocTableComponent: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE)
  const [displayArray, setDisplayArray] = useState(paginate(data, 1, ITEMS_PER_PAGE))

  useEffect(() => {
    setDisplayArray(paginate(data, currentPage, ITEMS_PER_PAGE))
  }, [currentPage])

  const renderRows = () => {
    const items = displayArray.map((item: RampDocument, index: number) => {
      return <DocumentRowComponent key={index} item={item} />
    })
    return items
  }
  return (
    <div className="row main-row">
      <div className="col-md-12">
        <div className="row inner-row">
          <div className="col-md-12">
            <table className="table table-striped ramp_docs">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Completion Score</th>
                  <th scope="col">Role</th>
                  <th scope="col">Status</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>{renderRows()}</tbody>
            </table>
            <PagingComponent currentPage={currentPage} changePage={setCurrentPage} totalPages={totalPages} />
          </div>
        </div>
      </div>
    </div>
  )
}

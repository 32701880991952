import { BrandPitch, useLazyGetBrandPitchDataQuery } from '../../api/brandPitchSlice'
import React, { FC, useEffect, useState } from 'react'

import { BrandPitchItem } from './BrandPitchItem'
import { LoadingIconComponent } from '../loadingIcon/LoadingIconComponent'
import { ViewAllTextButton } from '../buttons/ViewAllTextButton'

export const BrandPitchPanel: FC = () => {
  const [getBrandPitchData] = useLazyGetBrandPitchDataQuery()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<BrandPitch | undefined>()

  const getData = async () => {
    setIsLoading(true)
    try {
      const result = await getBrandPitchData(10)
      console.log('result', result?.data?.getBrandPitchByOwner)
      if (result.data) {
        setData(result?.data?.getBrandPitchByOwner)
      }
      setIsLoading(false)
    } catch (e) {
      console.log('Error')
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const renderData = () => {
    if (isLoading) {
      return <LoadingIconComponent />
    }
    return (
      <table className="table">
        <tbody>
          <BrandPitchItem title="We Are" copy={data?.whoWeAre ?? ''} />
          <BrandPitchItem title="We Serve" copy={data?.weServe ?? ''} />
          <BrandPitchItem title="Who Need" copy={data?.whoNeeds ?? ''} />
          <BrandPitchItem title="Our Solution" copy={data?.ourSolution ?? ''} />
          <BrandPitchItem title="Short Narrative:" copy={data?.shortNarrative ?? ''} />
          <BrandPitchItem title="Pitch" copy={data?.pitch ?? ''} />
        </tbody>
      </table>
    )
  }

  return (
    <div className="col-md-12">
      <div className="secret-sauce">
        <div className="inner-row row">
          <div className="col-md-9">
            <h4>Your Brand Pitch</h4>
          </div>
          <ViewAllTextButton onClick={() => console.log('viewAllClick')} isHidden />
          <div className="col-md-12">
            <div className="table-responsive" style={{ maxHeight: '200px' }}>
              {renderData()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { EmpathyQuestionData, SecretSauce } from '../types/AppTypes'

import { createSlice } from '@reduxjs/toolkit'
import { pushReplace } from '../utils/ArrayUtils'

export interface IdealCustomerDef {
  [key: string]: any
  id: string
  ownerId: string
  customerName: string
  avatar: string
  ageRange: string
  ageRangeStart: number
  ageRangeEnd: number
  gender: string
  maritalStatus: string
  hasChildren: string
  numberOfChildren: number
  childrenAgeRange: string[] | string
  hasPets: string
  pets: string[] | string
  education: string
  ethnicity: string
  houseHoldIncome: string
  ownRent: string
  residence: string
  buyerSeeking: EmpathyQuestionData[] | string
  buyerSaying: EmpathyQuestionData[] | string
  buyerDoing: EmpathyQuestionData[] | string
  buyerFeeling: EmpathyQuestionData[] | string
  painPointOne: string
  painPointOneSolution: string
  painPointTwo: string
  painPointTwoSolution: string
  painPointThree: string
  painPointThreeSolution: string
  objectionOne: string
  objectionOneOvercome: string
  objectionTwo: string
  objectionTwoOvercome: string
  objectionThree: string
  objectionThreeOvercome: string
  buyerSeekingSummary: string
  buyerSayingSummary: string
  buyerDoingSummary: string
  buyerFeelingSummary: string
  spectrumCasual: number
  spectrumFormal: number
  spectrumHumorous: number
  spectrumSerious: number
  spectrumIrreverent: number
  spectrumRespectful: number
  spectrumEnthusiastic: number
  spectrumMatterOfFact: number
  companyCorePurposeOne: string
  companyCorePurposeTwo: string
  companyCorePurposeThree: string
  companyCorePurposeFour: string
  companyCorePurposeFive: string
  coreServiceOne: string
  coreServiceTwo: string
  coreServiceThree: string
  modeOfCommunicationOne: string
  modeOfCommunicationTwo: string
  modeOfCommunicationThree: string
  modeOfCommunicationFour: string
  successStepOne: string
  successStepTwo: string
  successStepThree: string
  secretSauce: SecretSauce[] | string
}

const getInitialState = (): IdealCustomerDef => {
  return {
    id: '',
    ownerId: '',
    customerName: '',
    avatar: '',
    ageRange: '',
    ageRangeStart: 0,
    ageRangeEnd: 0,
    gender: 'Male',
    maritalStatus: 'Single',
    hasChildren: 'No',
    numberOfChildren: 0,
    childrenAgeRange: [],
    hasPets: 'No',
    pets: [],
    education: 'N/A',
    ethnicity: 'N/A',
    houseHoldIncome: '$79k-98.999k',
    ownRent: 'Own',
    residence: '',
    buyerSeeking: [],
    buyerSaying: [],
    buyerDoing: [],
    buyerFeeling: [],
    painPointOne: '',
    painPointOneSolution: '',
    painPointTwo: '',
    painPointTwoSolution: '',
    painPointThree: '',
    painPointThreeSolution: '',
    objectionOne: '',
    objectionOneOvercome: '',
    objectionTwo: '',
    objectionTwoOvercome: '',
    objectionThree: '',
    objectionThreeOvercome: '',
    buyerSeekingSummary: '',
    buyerSayingSummary: '',
    buyerDoingSummary: '',
    buyerFeelingSummary: '',
    spectrumCasual: 50,
    spectrumFormal: 50,
    spectrumHumorous: 50,
    spectrumSerious: 50,
    spectrumIrreverent: 50,
    spectrumRespectful: 50,
    spectrumEnthusiastic: 50,
    spectrumMatterOfFact: 50,
    companyCorePurposeOne: '',
    companyCorePurposeTwo: '',
    companyCorePurposeThree: '',
    companyCorePurposeFour: '',
    companyCorePurposeFive: '',
    coreServiceOne: '',
    coreServiceTwo: '',
    coreServiceThree: '',
    modeOfCommunicationOne: '',
    modeOfCommunicationTwo: '',
    modeOfCommunicationThree: '',
    modeOfCommunicationFour: '',
    successStepOne: '',
    successStepTwo: '',
    successStepThree: '',
    secretSauce: []
  }
}

export const idealCustomerSlice = createSlice({
  name: 'IdealCustomer',
  initialState: getInitialState(),
  reducers: {
    setInitialState: state => {
      return getInitialState()
    },
    updateIdeal: (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...state,
        ...action.payload
      }
    },
    setBuyerIsSeeking: (state, action) => {
      state.buyerSeeking = action.payload
    },
    setBuyerIsSaying: (state, action) => {
      state.buyerSaying = action.payload
    },
    setBuyerIsDoing: (state, action) => {
      state.buyerDoing = action.payload
    },
    setBuyerFeeling: (state, action) => {
      state.buyerFeeling = action.payload
    },
    addToSecretSauce: (state, action) => {
      state.secretSauce = action.payload
    }
  }
})
export const {
  setInitialState,
  updateIdeal,
  setBuyerIsSeeking,
  setBuyerIsSaying,
  setBuyerIsDoing,
  setBuyerFeeling,
  addToSecretSauce
} = idealCustomerSlice.actions

export default idealCustomerSlice.reducer

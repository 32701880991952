import { gql } from 'graphql-request'
export const buildWhoNeedsHelper = (idealCustomerId: string) => {
  return gql`
    query completeWhoNeeds {
      completeWhoNeeds (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

import { gql } from 'graphql-request'
export const getEmpathyMapSummaries = () => {
  return gql`
    query getEmpathyMap {
      getEmpathyMap {
        buyerDoingSummary
        buyerFeelingSummary
        buyerSayingSummary
        buyerSeekingSummary
      }
    }
  `
}

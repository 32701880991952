export const calculatePercentage = (amount: number, total: number) => {
  if (total === 0) {
    return 0
  }

  return amount / total
}

export const calculatePercentageAsString = (amount: number, total: number) => {
  if (total === 0) {
    return '0%'
  }

  const percentage = (amount / total) * 100
  return percentage.toFixed(0) + '%'
}

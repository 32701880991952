import validate from './validate'

export const validatePassword = arg => {
  const result = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(arg)
  return result
}

export const validateVerificationCode = arg => {
  console.log('arg', arg.length)
  return arg.length === 6
}

export default function validation(fieldName, value) {
  const constraints = {
    fullName: {
      presence: true,
      format: {
        pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g
      }
    },
    email: {
      presence: true,
      format: {
        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
      }
    },
    password: {
      presence: true,
      format: {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
      },
      length: {
        minimum: 8,
        message: 'Minimum length is 8'
      }
    }
  }

  const formValues = {}
  formValues[fieldName] = value
  const formFields = {}
  formFields[fieldName] = constraints[fieldName]
  const result = validate(formValues, formFields)
  if (result) {
    return result[fieldName][0]
  }
  return null
}

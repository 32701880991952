import { AD_7, AD_9 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import {
  changeIdealCustomerAnnualIncome,
  changeIdealCustomerEducation,
  changeIdealCustomerEthnicity,
  changeIdealCustomerInterest,
  changeIdealCustomerPets,
  updateQuestion
} from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { RadioButtonSquareComponent } from '../../../../components/RadioButtonSquareComponent'
import { TextInputFullWidthComponent } from '../../../../components/TextInputFullWidthComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificEightPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const idealCustomer = useSelector((state: RootState) => state.AdSpecific.idealCustomerDemographics)

  const next = () => {
    dispatch(updateQuestion(AD_9))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_7))
  }
  const changeInterest = (arg: string) => {
    dispatch(changeIdealCustomerInterest(arg))
  }
  const changePets = (arg: string) => {
    dispatch(changeIdealCustomerPets(arg))
  }
  const changeEducation = (arg: string) => {
    dispatch(changeIdealCustomerEducation(arg))
  }
  const changeEthnicity = (arg: string) => {
    dispatch(changeIdealCustomerEthnicity(arg))
  }
  const changeAnnualIncome = (arg: string) => {
    dispatch(changeIdealCustomerAnnualIncome(arg))
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Identify The Ideal Customer</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <div className="form-field fw">
                <h4>Identify the ideal customer</h4>
              </div>
              <TextInputFullWidthComponent value={idealCustomer.interest} label="Interest" onChange={changeInterest} placeholder="Enter the name" />
              <div className="form-field fw">
                <label>Pets</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="None" isSelected={idealCustomer.pets === 'None'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Dogs" isSelected={idealCustomer.pets === 'Dogs'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Cats" isSelected={idealCustomer.pets === 'Cats'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Reptiles" isSelected={idealCustomer.pets === 'Reptiles'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Fish" isSelected={idealCustomer.pets === 'Fish'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Birds" isSelected={idealCustomer.pets === 'Birds'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Livestock" isSelected={idealCustomer.pets === 'Livestock'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Horses" isSelected={idealCustomer.pets === 'Horses'} onClick={changePets} />
                  <RadioButtonSquareComponent label="Exotic" isSelected={idealCustomer.pets === 'Exotic'} onClick={changePets} />
                </div>
              </div>
              <div className="form-field fw">
                <label>Level of Education</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="Some HS" isSelected={idealCustomer.levelOfEducation === 'Some HS'} onClick={changeEducation} />
                  <RadioButtonSquareComponent label="HS Grad" isSelected={idealCustomer.levelOfEducation === 'HS Grad'} onClick={changeEducation} />
                  <RadioButtonSquareComponent label="Some College" isSelected={idealCustomer.levelOfEducation === 'Some College'} onClick={changeEducation} />
                  <RadioButtonSquareComponent label="College Grad" isSelected={idealCustomer.levelOfEducation === 'College Grad'} onClick={changeEducation} />
                  <RadioButtonSquareComponent label="PHD" isSelected={idealCustomer.levelOfEducation === 'PHD'} onClick={changeEducation} />
                  <RadioButtonSquareComponent label="Trade School" isSelected={idealCustomer.levelOfEducation === 'Trade School'} onClick={changeEducation} />
                  <RadioButtonSquareComponent label="MBA Grad" isSelected={idealCustomer.levelOfEducation === 'MBA Grad'} onClick={changeEducation} />
                </div>
              </div>
              <div className="form-field fw">
                <label>Does your brand serve a specific ethnicity? If so which one(s)?</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="None" isSelected={idealCustomer.ethnicity === 'None'} onClick={changeEthnicity} group="ethnic" />
                  <RadioButtonSquareComponent label="White" isSelected={idealCustomer.ethnicity === 'White'} onClick={changeEthnicity} group="ethnic" />
                  <RadioButtonSquareComponent label="Hispanic" isSelected={idealCustomer.ethnicity === 'Hispanic'} onClick={changeEthnicity} group="ethnic" />
                  <RadioButtonSquareComponent label="Mexican" isSelected={idealCustomer.ethnicity === 'Mexican'} onClick={changeEthnicity} group="ethnic" />
                  <RadioButtonSquareComponent
                    label="African American"
                    isSelected={idealCustomer.ethnicity === 'African American'}
                    onClick={changeEthnicity}
                    group="ethnic"
                  />
                  <RadioButtonSquareComponent
                    label="Pacific Islander"
                    isSelected={idealCustomer.ethnicity === 'Pacific Islander'}
                    onClick={changeEthnicity}
                    group="ethnic"
                  />
                  <RadioButtonSquareComponent
                    label="Native America"
                    isSelected={idealCustomer.ethnicity === 'Native America'}
                    onClick={changeEthnicity}
                    group="ethnic"
                  />
                  <RadioButtonSquareComponent label="Asian" isSelected={idealCustomer.ethnicity === 'Asian'} onClick={changeEthnicity} group="ethnic" />
                </div>
              </div>
              <div className="form-field fw">
                <label>Annual Household income</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="$0-45k" isSelected={idealCustomer.annualIncome === '$0-45k'} onClick={changeAnnualIncome} />
                  <RadioButtonSquareComponent label="$46-75k" isSelected={idealCustomer.annualIncome === '$46-75k'} onClick={changeAnnualIncome} />
                  <RadioButtonSquareComponent label="$76-99k" isSelected={idealCustomer.annualIncome === '$76-99k'} onClick={changeAnnualIncome} />
                  <RadioButtonSquareComponent label="$100-149k" isSelected={idealCustomer.annualIncome === '$100-149k'} onClick={changeAnnualIncome} />
                  <RadioButtonSquareComponent label="$150-248k" isSelected={idealCustomer.annualIncome === '$150-248k'} onClick={changeAnnualIncome} />
                  <RadioButtonSquareComponent label="$250-499k" isSelected={idealCustomer.annualIncome === '$250-499k'} onClick={changeAnnualIncome} />
                  <RadioButtonSquareComponent label="$1M+" isSelected={idealCustomer.annualIncome === '$1M+'} onClick={changeAnnualIncome} />
                </div>
              </div>

              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { gql } from 'graphql-request'
export const buildPitchHelper = (idealCustomerId: string) => {
  return gql`
    query completePitch {
      completePitch (idealCustomerId: "${idealCustomerId}") {
        id
        whoWeAre
        weServe
        whoNeeds
        ourSolution
        versus
        shortNarrative
        pitch
      }
    }
  `
}

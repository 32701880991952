export const removeHash = (arg: string) => {
  if (arg.charAt(0) === '#') {
    return arg.slice(1)
  }
  return arg
}

export const insertNames = (arg: string, myName: string, customerName: string) => {
  const b = arg.replace('$FirstName', myName)
  const c = b.replace('(Ideal.Customer.Name)', customerName)
  const d = c.replace(/\n/g, '<br />')
  console.log()
  return d
}

export const insertName = (arg: string, myName: string) => {
  const b = arg.replace('$FirstName', myName)
  const d = b.replace(/\n/g, '<br />')
  return d
}

export const cleanLineBreaks = (arg: string) => {
  const d = arg.replace(/\n/g, '<br />')
  return d
}

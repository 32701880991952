import React, { FC, useEffect, useRef, useState } from 'react'

import { LoadingIconComponent } from '../components/loadingIcon/LoadingIconComponent'
import { RAMP_LOGO_BIG } from '../constants/ImageConstants'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useCognito } from '../customHooks/useCognito'
import { useDispatch } from 'react-redux'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const EmailConfirmationScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const { initRamp } = useScreenNavigation()
  const { resendCode, verificationCode, verify, setVerificationCode, verificationError, verifying } = useCognito()

  const [digitOne, setDigitOne] = useState('')
  const [digitTwo, setDigitTwo] = useState('')
  const [digitThree, setDigitThree] = useState('')
  const [digitFour, setDigitFour] = useState('')
  const [digitFive, setDigitFive] = useState('')
  const [digitSix, setDigitSix] = useState('')

  const inputOne = useRef<HTMLInputElement | null>(null)
  const inputTwo = useRef<HTMLInputElement | null>(null)
  const inputThree = useRef<HTMLInputElement | null>(null)
  const inputFour = useRef<HTMLInputElement | null>(null)
  const inputFive = useRef<HTMLInputElement | null>(null)
  const inputSix = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    initRamp()
  }, [])

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    processNext()
  }
  const processNext = () => {
    if (!verifying) {
      verify()
    }
  }
  useEffect(() => {
    if (verificationCode.length === 6) {
      processNext()
    }
  }, [verificationCode])

  useEffect(() => {
    if (digitOne && digitTwo && digitThree && digitFour && digitFive && digitSix) {
      setVerificationCode(`${digitOne}${digitTwo}${digitThree}${digitFour}${digitFive}${digitSix}`)
    }
  }, [digitOne, digitTwo, digitThree, digitFour, digitFive, digitSix])

  const onChangeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDigitOne(event.target.value)
    if (inputTwo.current) {
      inputTwo.current.focus()
    }
  }
  const onChangeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDigitTwo(event.target.value)
    if (inputThree.current) {
      inputThree.current.focus()
    }
  }
  const onChangeThree = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDigitThree(event.target.value)
    if (inputFour.current) {
      inputFour.current.focus()
    }
  }
  const onChangeFour = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDigitFour(event.target.value)
    if (inputFive.current) {
      inputFive.current.focus()
    }
  }
  const onChangeFive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDigitFive(event.target.value)
    if (inputSix.current) {
      inputSix.current.focus()
    }
  }
  const onChangeSix = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDigitSix(event.target.value)
  }

  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_BIG} alt="Marketing RAMP" />
        </div>
        <form onSubmit={submit}>
          <div className="form-container">
            <center>
              <h2>Confirm your email</h2>
              <p>Check your email, grab that verification code, and enter it below</p>
            </center>

            <div className="mr-row">
              <div className="mr-col-2">
                <div className="inputContainer email-con">
                  <input type="number" className="input" placeholder="0" ref={inputOne} onChange={onChangeOne} maxLength={1} autoFocus />
                </div>
              </div>
              <div className="mr-col-2">
                <div className="inputContainer email-con">
                  <input type="number" className="input" placeholder="0" ref={inputTwo} onChange={onChangeTwo} maxLength={1} />
                </div>
              </div>
              <div className="mr-col-2">
                <div className="inputContainer email-con">
                  <input type="number" className="input" placeholder="0" ref={inputThree} onChange={onChangeThree} maxLength={1} />
                </div>
              </div>
              <div className="mr-col-2">
                <div className="inputContainer email-con">
                  <input type="number" className="input" placeholder="0" ref={inputFour} onChange={onChangeFour} maxLength={1} />
                </div>
              </div>
              <div className="mr-col-2">
                <div className="inputContainer email-con">
                  <input type="number" className="input" placeholder="0" ref={inputFive} onChange={onChangeFive} maxLength={1} />
                </div>
              </div>
              <div className="mr-col-2">
                <div className="inputContainer email-con">
                  <input type="number" className="input" placeholder="0" ref={inputSix} onChange={onChangeSix} maxLength={1} />
                </div>
              </div>
            </div>
            <div className="mr-col-12">
              {!verifying ? (
                <div className="inputContainer">
                  <input type="submit" className="submit-btn" value="CONFIRM" />
                </div>
              ) : (
                <LoadingIconComponent />
              )}
            </div>
          </div>
        </form>
        <div className="resend-verificationCode">
          <center>
            {verificationError ? <p>{verificationError}</p> : null}
            {!verifying ? (
              <p>
                Didn’t get it?{' '}
                <a href="#" onClick={resendCode}>
                  Click here
                </a>{' '}
                to resend a verificationCode
              </p>
            ) : null}
          </center>
        </div>
      </div>
    </div>
  )
}

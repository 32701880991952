export const AD_1 = 'adSpecificPanelOne'
export const AD_2 = 'adSpecificPanelTwo'
export const AD_3 = 'adSpecificPanelThree'
export const AD_4 = 'adSpecificPanelFour'
export const AD_5 = 'adSpecificPanelFive'
export const AD_6 = 'adSpecificPanelSix'
export const AD_7 = 'adSpecificPanelSeven'
export const AD_8 = 'adSpecificPanelEight'
export const AD_9 = 'adSpecificPanelNine'
export const AD_10 = 'adSpecificPanelTen'
export const AD_11 = 'adSpecificPanelEleven'

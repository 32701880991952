import '../assets/style.css'

import * as React from 'react'

import { BOOK } from '../constants/ImageConstants'
import { FC } from 'react'

const BuyBookPaneComponent: FC = () => {
  const buyNow = () => {
    // TODO: Create link to buy now and make link work.
  }
  return (
    <div className="col-md-12">
      <div className="row inner-row gradient_bbl_white_blue buy_the_book">
        <div className="col-md-8">
          <h2>
            MARKETING BUILT BY
            <span className="bbl-red"> LOVE</span>
          </h2>
          <p>Now Available. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</p>
          <div className="btn">
            <a href="#" className="btn-red" onClick={buyNow}>
              Buy The Book
            </a>
          </div>
        </div>
        <div className="col-md-4">
          <center>
            <img src={BOOK} alt="" />
          </center>
        </div>
      </div>
    </div>
  )
}

export { BuyBookPaneComponent }

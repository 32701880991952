import { completeTransactionHelper } from './jsonHelpers/stripHelpers/completeTransactionHelper'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createPaymentIntentHelper } from './jsonHelpers/stripHelpers/createPaymentIntentHelper'
import { graphqlBaseQuery } from './graphqlBaseQuery'

export type StripeIntent = {
  amount: number
  currency: string
  discountCode: string
  description: string
}
export type StripeData = {
  id: string
  clientSecret: string
  amount: number
}
export type StripeDataResponse = {
  createPaymentIntent: StripeData
}

const isGetIntentResponse = (obj: any): obj is StripeDataResponse => {
  const process = 'createPaymentIntent' in obj
  return process
}

export const stripeSlice = createApi({
  reducerPath: 'stripeApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_SERVER!
  }),
  endpoints: builder => ({
    createPaymentIntent: builder.query<StripeData, StripeIntent>({
      query: intent => ({
        body: createPaymentIntentHelper(intent)
      }),
      transformResponse: (response: StripeDataResponse | StripeData): StripeData => (isGetIntentResponse(response) ? response.createPaymentIntent : response)
    }),
    completeTransaction: builder.query<StripeData, string>({
      query: id => ({
        body: completeTransactionHelper(id)
      })
    })
  })
})

export const { useLazyCreatePaymentIntentQuery, useLazyCompleteTransactionQuery } = stripeSlice

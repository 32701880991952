import { gql } from 'graphql-request'
export const buildPriorBadExperienceHelper = (idealCustomerId: string) => {
  return gql`
    query setPriorBadExperience {
      setPriorBadExperience (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

import { UpdateRampInfo } from '../../rampSlice'
import { gql } from 'graphql-request'
export const updateRampHelper = (obj: UpdateRampInfo) => {
  return gql`
      mutation updateCustomerRampById {
        updateCustomerRampById (customerRamp: {
          id: "${obj.id}",
          pillarOneProgress: "${obj.pillarOneProgress}",
          pillarTwoProgress: "${obj.pillarTwoProgress}",
          pillarThreeProgress: "${obj.pillarThreeProgress}",
          pillarFourProgress: "${obj.pillarFourProgress}",
          currentScreenIndex: ${obj.currentScreenIndex},
          currentPillar: ${obj.currentPillar},
          currentScreenId: "${obj.currentScreenId}",
        }) {
          id
        }
      }
    `
}

import React, { FC } from 'react'

import { BACK_BTN_ARROW } from '../../constants/ImageConstants'

type Props = {
  onClick: () => void
  label?: string
  icon?: string
  isNext?: boolean
}
export const TransparentButtonComponent: FC<Props> = ({ onClick, label = 'Back', icon = BACK_BTN_ARROW, isNext = false }) => {
  if (isNext) {
    return (
      <a href="#" onClick={onClick} className="btn-transparent" rel="noopener noreferrer">
        {label}
        <img className="btn_icon" src={icon} />
      </a>
    )
  }
  return (
    <a href="#" onClick={onClick} className="btn-transparent" rel="noopener noreferrer">
      <img className="btn_icon" src={icon} />
      {label}
    </a>
  )
}

import React, { FC } from 'react'

import { RootState } from '../../Store'
import { SpectrumItem } from './SpectrumItem'
import { ViewAllTextButton } from '../buttons/ViewAllTextButton'
import { useSelector } from 'react-redux'

export const SpectrumPanel: FC = () => {
  const {
    spectrumFormal,
    spectrumSerious,
    spectrumRespectful,
    spectrumEnthusiastic,
    spectrumHumorous,
    spectrumCasual,
    spectrumIrreverent,
    spectrumMatterOfFact
  } = useSelector((state: RootState) => state.IdealCustomer)
  return (
    <div className="col-md-5">
      <div className="formality">
        <div className="inner-row row">
          <div className="col-md-9">
            <h4>Your Spectrum of Formality</h4>
          </div>
          <ViewAllTextButton onClick={() => console.log('viewAllClick')} isHidden />
          <div className="col-md-12">
            <SpectrumItem nameOne="Formal" percentageOne={spectrumFormal} nameTwo="Casual" percentageTwo={spectrumCasual} color="#EF5350" />
            <SpectrumItem nameOne="Serious" percentageOne={spectrumSerious} nameTwo="Humorous" percentageTwo={spectrumHumorous} color="#208AF5" />
            <SpectrumItem nameOne="Respectful" percentageOne={spectrumRespectful} nameTwo="Irreverent" percentageTwo={spectrumIrreverent} color="#00B689" />
            <SpectrumItem
              nameOne="Enthusiastic"
              percentageOne={spectrumEnthusiastic}
              nameTwo="Matter Of Fact"
              percentageTwo={spectrumMatterOfFact}
              color="#7258ED"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

/*

*/

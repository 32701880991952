import '../../../assets/style.css'

import * as React from 'react'

import { BrandPitchPanel } from '../../../components/dashboard/BandPitchPanel'
import { BrandVoicePanel } from '../../../components/dashboard/BrandVoicePanel'
import { BuyBookPaneComponent } from '../../../components/BuyBookPaneComponent'
import { EmpathyMapPanel } from '../../../components/dashboard/EmpathyMapPanel'
import { FC } from 'react'
import { IdealCustomerPaneComponent } from '../../../components/IdealCustomerPaneComponent'
import { SecretSaucePanel } from '../../../components/dashboard/SecretSaucePanel'
import { SpectrumPanel } from '../../../components/dashboard/SpectrumPanel'

const DashboardSceneComponent: FC = () => {
  const renderOld = () => {
    return (
      <div className="row main-row g-3">
        <IdealCustomerPaneComponent />
        <BrandVoicePanel />
        <EmpathyMapPanel />
        <BuyBookPaneComponent />
      </div>
    )
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Welcome to Marketing Ramp</h1>
      </div>
      <div className="row main-row g-3">
        <IdealCustomerPaneComponent />
        <SpectrumPanel />
        <EmpathyMapPanel />
        <SecretSaucePanel />
        <BrandPitchPanel />
        <BuyBookPaneComponent />
      </div>
    </div>
  )
}

export { DashboardSceneComponent }
// get emails if they do not exist on panel load, - loading animation

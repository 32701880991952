import React, { FC, useState } from 'react'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const ResidenceChoiceScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const dispatch = useDispatch()
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const { isTalking, callServer } = usePillarCommunication()

  const [value, setValue] = useState(null)
  const [placeLabel, setPlaceLabel] = useState('')
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }

  const gotItem = (arg: any) => {
    setValue(arg)
    setPlaceLabel(arg.label)
  }

  const nextPageClick = async () => {
    const result = await callServer(placeLabel, 'residence')
    if (result) {
      dispatch(
        updateIdeal({
          residence: placeLabel
        })
      )
      nextPage()
    }
  }
  const erroring = (arg: any) => {
    console.log('ERRORING ', arg)
  }
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>Where do they live?</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyD_uS9ZYg7zOhxDFlxs8hZDmj6fJEh_8Dk"
                  selectProps={{
                    value,
                    onChange: gotItem,
                    placeholder: 'Type in Your City or Town'
                  }}
                  onLoadFailed={erroring}
                />
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

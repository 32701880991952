import { DASHBOARD_ROUTE, LOGIN_ROUTE, WELCOME_ROUTE } from '../constants/RouteConstants'

import { RootState } from '../Store'
import { isExpired } from 'react-jwt'
import { useCognito } from './useCognito'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const useSession = (redirect?: boolean) => {
  const { token, refreshToken, type } = useSelector((state: RootState) => {
    return {
      token: state.Me.jwtToken,
      refreshToken: state.Me.refreshToken,
      type: state.Me.type
    }
  })
  const { refreshTheToken } = useCognito()
  const navigate = useNavigate()
  useEffect(() => {
    if (token === '') {
      navigate(WELCOME_ROUTE)
      return
    }
    if (token && isExpired(token)) {
      navigate(LOGIN_ROUTE)
      return
    }
    if (token && !isExpired(token)) {
      // dispatch(expireToken())
      refreshTheToken(refreshToken)
      /* if (redirect && type !== 'customer') {
        navigate(DASHBOARD_ROUTE)
      } */
    }
  }, [])

  return {}
}

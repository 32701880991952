import { EmpathyQuestionData, Question } from '../types/AppTypes'
import React, { FC, useEffect, useState } from 'react'
import { getAnswerData, getItemById, pushReplace } from '../utils/ArrayUtils'
import { setBuyerFeeling, setBuyerIsDoing, setBuyerIsSaying, setBuyerIsSeeking, updateIdeal } from '../reducers/IdealCustomerSlice'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { TextAreaFormField } from '../components/TextAreaFormFieldComponent'
import { insertNames } from '../utils/StringUtils'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const TextAreaGenericScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)

  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)

  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const { additionalFunctionCall, isTalking, callServer } = usePillarCommunication()

  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const { buyerIsSeeking, buyerIsSaying, buyerIsDoing, buyerIsFeeling } = useSelector((state: RootState) => {
    return {
      buyerIsSeeking: state.IdealCustomer.buyerSeeking,
      buyerIsSaying: state.IdealCustomer.buyerSaying,
      buyerIsDoing: state.IdealCustomer.buyerDoing,
      buyerIsFeeling: state.IdealCustomer.buyerFeeling
    }
  })

  const startValue = useSelector((state: RootState) => {
    switch (question.propertyName) {
      case 'buyerSeeking':
        return getAnswerData(state.IdealCustomer[question.propertyName] as EmpathyQuestionData[], id ?? '')
      case 'buyerSaying':
        return getAnswerData(state.IdealCustomer[question.propertyName] as EmpathyQuestionData[], id ?? '')
      case 'buyerDoing':
        return getAnswerData(state.IdealCustomer[question.propertyName] as EmpathyQuestionData[], id ?? '')
      case 'buyerFeeling':
        return getAnswerData(state.IdealCustomer[question.propertyName] as EmpathyQuestionData[], id ?? '')
      default:
        return state.IdealCustomer[question.propertyName]
    }
  })

  const [answerValue, setAnswerValue] = useState(startValue)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }

  const nextPageClick = async () => {
    let result
    let newArray
    const newItemObj = {
      id: question.id,
      question: insertNames(question.h2Copy, firstName, idealCustomerName),
      answer: answerValue
    }
    switch (question.propertyName) {
      case 'buyerFeeling':
        newArray = pushReplace(buyerIsFeeling as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerFeeling(newArray))
          nextPage()
        }
        break
      case 'buyerDoing':
        newArray = pushReplace(buyerIsDoing as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerIsDoing(newArray))
          nextPage()
        }
        break
      case 'buyerSaying':
        newArray = pushReplace(buyerIsSaying as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerIsSaying(newArray))
          nextPage()
        }
        break
      case 'buyerSeeking':
        console.log('BUyer is seeking', buyerIsSeeking)
        console.log('BUyer is seeking newItemObj', newItemObj)
        newArray = pushReplace(buyerIsSeeking as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerIsSeeking(newArray))
          nextPage()
        }
        break
      default:
        result = await callServer(answerValue, question.propertyName)
        if (result) {
          dispatch(
            updateIdeal({
              [question.propertyName]: answerValue
            })
          )
          nextPage()
        }
        break
    }
  }
  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
    if (newQuestion.functionCall) {
      additionalFunctionCall(newQuestion.functionCall)
    }
  }, [id])

  useEffect(() => {
    setAnswerValue(startValue)
  }, [question.h2Copy])
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>{insertNames(question.h2Copy, firstName, idealCustomerName)}</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field fw">
                  <TextAreaFormField label="&nbsp;" value={answerValue} onChange={setAnswerValue} />
                </div>
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

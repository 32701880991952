import React, { FC } from 'react'

type Props = {
  num: number
  onClick: (arg: number) => void
  isSelected?: boolean
}
export const PageNumberComponent: FC<Props> = ({ num, onClick, isSelected }: Props) => {
  const cls = isSelected ? 'page-item active' : 'page-item'
  const onPress = () => {
    onClick(num)
  }
  return (
    <li className={cls}>
      <a className="page-link" href="#" onClick={onPress}>
        {num}
      </a>
    </li>
  )
}

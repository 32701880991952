import { useEffect, useState } from 'react'
import { useLazyCreateEmailsQuery, useLazyGetRampEmailByStageQuery, useLazyGetRampEmailStatusByIdQuery } from '../api/rampSlice'

import { FullErrorResponse } from '../types/AppTypes'
import { RootState } from '../Store'
import { WELCOME_ROUTE } from '../constants/RouteConstants'
import { isErrorToken } from '../utils/ErrorUtils'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const useRampBuilder = (): {
  processingEmailStatus: boolean
  buildEmails: () => Promise<void>
  getEmailsByStage: (arg: string) => Promise<any>
  emailsCompleted: number
} => {
  const navigate = useNavigate()
  const rampId = useSelector((state: RootState) => state.ConsumerProject.id)
  const [createEmails] = useLazyCreateEmailsQuery()
  const [getStageEmails] = useLazyGetRampEmailByStageQuery()
  const [processingEmailStatus, setProcessingEmailStatus] = useState(true)
  const [emailsCompleted, setEmailsCompleted] = useState(0)

  const updateEmail = (arg: number) => {
    if (arg > emailsCompleted) {
      const finalNumber = 40
      const percentage = Math.floor((arg / finalNumber) * 100)
      setEmailsCompleted(percentage)
    }
  }
  const buildEmails = async () => {
    try {
      const stageOne = await createEmails({ rampId, functionName: 'generateStageOneEmailOne' })
      console.log('result from build one ', stageOne)
      updateEmail(1)
      const stageTwoEmailOne = await createEmails({ rampId, functionName: 'generateStageTwoEmailOne' })
      console.log('result from build two 1 ', stageTwoEmailOne)
      updateEmail(2)
      const stageTwoEmailTwo = await createEmails({ rampId, functionName: 'generateStageTwoEmailTwo' })
      console.log('result from build two 2', stageTwoEmailTwo)
      updateEmail(3)
      const stageTwoEmailThree = await createEmails({ rampId, functionName: 'generateStageTwoEmailThree' })
      console.log('result from build two 3', stageTwoEmailThree)
      updateEmail(4)
      const stageTwoEmailFour = await createEmails({ rampId, functionName: 'generateStageTwoEmailFour' })
      console.log('result from build two 4', stageTwoEmailFour)
      updateEmail(5)
      const stageTwoEmailFive = await createEmails({ rampId, functionName: 'generateStageTwoEmailFive' })
      console.log('result from build two 5', stageTwoEmailFive)
      updateEmail(6)
      const stageTwoEmailSix = await createEmails({ rampId, functionName: 'generateStageTwoEmailSix' })
      console.log('result from build two 6', stageTwoEmailSix)
      updateEmail(7)
      const stageTwoEmailSeven = await createEmails({ rampId, functionName: 'generateStageTwoEmailSeven' })
      console.log('result from build two 7', stageTwoEmailSeven)
      updateEmail(8)
      const stageTwoEmailEight = await createEmails({ rampId, functionName: 'generateStageTwoEmailEight' })
      console.log('result from build two 8', stageTwoEmailEight)
      updateEmail(9)
      const stageThreeEmailOne = await createEmails({ rampId, functionName: 'generateStageThreeEmailOne' })
      console.log('result from build three 1', stageThreeEmailOne)
      updateEmail(10)
      const stageFourEmailOne = await createEmails({ rampId, functionName: 'generateStageFourEmailOne' })
      console.log('result from build four 1', stageFourEmailOne)
      updateEmail(11)
      const stageFourEmailTwo = await createEmails({ rampId, functionName: 'generateStageFourEmailTwo' })
      console.log('result from build four 2', stageFourEmailTwo)
      updateEmail(12)
      const stageFourEmailThree = await createEmails({ rampId, functionName: 'generateStageFourEmailThree' })
      console.log('result from build four 3', stageFourEmailThree)
      updateEmail(13)
      const stageFourEmailFour = await createEmails({ rampId, functionName: 'generateStageFourEmailFour' })
      console.log('result from build four 4', stageFourEmailFour)
      updateEmail(14)
      const stageFourEmailFive = await createEmails({ rampId, functionName: 'generateStageFourEmailFive' })
      console.log('result from build four 5', stageFourEmailFive)
      updateEmail(15)
      const stageFiveEmailOne = await createEmails({ rampId, functionName: 'generateStageFiveEmailOne' })
      console.log('result from build five 1', stageFiveEmailOne)
      updateEmail(16)
      const stageFiveEmailTwo = await createEmails({ rampId, functionName: 'generateStageFiveEmailTwo' })
      console.log('result from build five 2', stageFiveEmailTwo)
      updateEmail(17)
      const stageSixEmailOne = await createEmails({ rampId, functionName: 'generateStageSixEmailOne' })
      console.log('result from build six 1', stageSixEmailOne)
      updateEmail(18)
      const stageSixEmailTwo = await createEmails({ rampId, functionName: 'generateStageSixEmailTwo' })
      console.log('result from build six 2', stageSixEmailTwo)
      updateEmail(19)
      const stageSixEmailThree = await createEmails({ rampId, functionName: 'generateStageSixEmailThree' })
      console.log('result from build six 2', stageSixEmailThree)
      updateEmail(20)
      const stageSevenEmailOne = await createEmails({ rampId, functionName: 'generateStageSevenEmailOne' })
      console.log('result from build seven 1', stageSevenEmailOne)
      updateEmail(21)
      const stageSevenEmailTwo = await createEmails({ rampId, functionName: 'generateStageSevenEmailTwo' })
      console.log('result from build seven 2', stageSevenEmailTwo)
      updateEmail(22)
      const stageSevenEmailThree = await createEmails({ rampId, functionName: 'generateStageSevenEmailThree' })
      console.log('result from build seven 3', stageSevenEmailThree)
      updateEmail(23)
      const stageSevenEmailFour = await createEmails({ rampId, functionName: 'generateStageSevenEmailFour' })
      console.log('result from build seven 4', stageSevenEmailFour)
      updateEmail(24)
      const stageSevenEmailFive = await createEmails({ rampId, functionName: 'generateStageSevenEmailFive' })
      console.log('result from build seven 5', stageSevenEmailFive)
      updateEmail(25)
      /* TODO: This needs to be done later
      const stageEightEmailOne = await createEmails({ rampId, functionName: 'generateStageEightEmailOne' })
      console.log('result from build eight 1', stageEightEmailOne)

      const stageEightEmailTwo = await createEmails({ rampId, functionName: 'generateStageEightEmailTwo' })
      console.log('result from build eight 2', stageEightEmailTwo)
      */
      const stageEightEmailThree = await createEmails({ rampId, functionName: 'generateStageEightEmailThree' })
      console.log('result from build eight 3', stageEightEmailThree)
      updateEmail(26)
      const stageEightEmailFour = await createEmails({ rampId, functionName: 'generateStageEightEmailFour' })
      console.log('result from build eight 4', stageEightEmailFour)
      updateEmail(27)
      const stageEightEmailFive = await createEmails({ rampId, functionName: 'generateStageEightEmailFive' })
      console.log('result from build eight 5', stageEightEmailFive)
      updateEmail(28)
      const stageEightEmailSix = await createEmails({ rampId, functionName: 'generateStageEightEmailSix' })
      console.log('result from build eight 6', stageEightEmailSix)
      updateEmail(29)
      const stageEightEmailSeven = await createEmails({ rampId, functionName: 'generateStageEightEmailSeven' })
      console.log('result from build eight 7', stageEightEmailSeven)
      updateEmail(30)
      const stageEightEmailEight = await createEmails({ rampId, functionName: 'generateStageEightEmailEight' })
      console.log('result from build eight 8', stageEightEmailEight)
      updateEmail(31)
      const stageEightEmailNine = await createEmails({ rampId, functionName: 'generateStageEightEmailNine' })
      console.log('result from build eight 9', stageEightEmailNine)
      updateEmail(32)
      const stageEightEmailTen = await createEmails({ rampId, functionName: 'generateStageEightEmailTen' })
      console.log('result from build eight 10', stageEightEmailTen)
      updateEmail(33)
      const stageEightEmailEleven = await createEmails({ rampId, functionName: 'generateStageEightEmailEleven' })
      console.log('result from build eight 11', stageEightEmailEleven)
      updateEmail(34)
      const stageNineEmailOne = await createEmails({ rampId, functionName: 'generateStageNineEmailOne' })
      console.log('result from build nine 1', stageNineEmailOne)
      updateEmail(35)
      const stageNineEmailTwo = await createEmails({ rampId, functionName: 'generateStageNineEmailTwo' })
      console.log('result from build nine 2', stageNineEmailTwo)
      updateEmail(36)
      const stageNineEmailThree = await createEmails({ rampId, functionName: 'generateStageNineEmailThree' })
      console.log('result from build nine 3', stageNineEmailThree)
      updateEmail(37)
      const stageNineEmailFour = await createEmails({ rampId, functionName: 'generateStageNineEmailFour' })
      console.log('result from build nine 3', stageNineEmailFour)
      updateEmail(38)
      const stageNineEmailFive = await createEmails({ rampId, functionName: 'generateStageNineEmailFive' })
      console.log('result from build nine 4', stageNineEmailFive)
      updateEmail(39)
      const stageNineEmailSix = await createEmails({ rampId, functionName: 'generateStageNineEmailSix' })
      console.log('result from build nine 5', stageNineEmailSix)
      updateEmail(40)
      setProcessingEmailStatus(false)
    } catch (e) {
      console.log('Error', e)
    }
  }

  const getEmailsByStage = async (arg: string) => {
    try {
      const result = await getStageEmails({ rampId, stageName: arg }).unwrap()
      // console.log('Emails list', result)
      return result
    } catch (errorResponse) {
      const error = errorResponse as FullErrorResponse
      if (isErrorToken(error)) {
        navigate(WELCOME_ROUTE)
      }
      return errorResponse
    }
  }
  /* useEffect(() => {

  }, []) */
  return { processingEmailStatus, buildEmails, getEmailsByStage, emailsCompleted }
}

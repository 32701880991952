import { useDispatch, useSelector } from 'react-redux'
import {
  useLazyBuildCustomerValueQuery,
  useLazyBuildPainPointSolutionsQuery,
  useLazyBuildPainPointsQuery,
  useLazyBuildPriorBadExperiencesQuery,
  useLazyBuildPriorBadExperiencesSolutionQuery,
  useLazyBuildSaleObjectionsQuery,
  useLazyBuildSaleObjectionsSolutionsQuery,
  useLazyBuildWeAlignQuery
} from '../api/caSlice'
import {
  useLazyBuildDoingQuery,
  useLazyBuildFeelingQuery,
  useLazyBuildSayingQuery,
  useLazyBuildSeekingQuery,
  useLazyGetEmpathyMapDataQuery,
  useLazyUpdateAgeRangeQuery,
  useLazyUpdateAvatarQuery,
  useLazyUpdateBuyerIsDoingQuery,
  useLazyUpdateBuyerIsFeelingQuery,
  useLazyUpdateBuyerIsSayingQuery,
  useLazyUpdateBuyerIsSeekingQuery,
  useLazyUpdateChildrenQuery,
  useLazyUpdateCompanyCorePurposeFiveQuery,
  useLazyUpdateCompanyCorePurposeFourQuery,
  useLazyUpdateCompanyCorePurposeOneQuery,
  useLazyUpdateCompanyCorePurposeThreeQuery,
  useLazyUpdateCompanyCorePurposeTwoQuery,
  useLazyUpdateCoreServiceOneQuery,
  useLazyUpdateCoreServiceThreeQuery,
  useLazyUpdateCoreServiceTwoQuery,
  useLazyUpdateEducationQuery,
  useLazyUpdateEthnicityQuery,
  useLazyUpdateIncomeQuery,
  useLazyUpdateMaritalStatusQuery,
  useLazyUpdateModeOfCommunicationFourQuery,
  useLazyUpdateModeOfCommunicationOneQuery,
  useLazyUpdateModeOfCommunicationThreeQuery,
  useLazyUpdateModeOfCommunicationTwoQuery,
  useLazyUpdateNameGenderQuery,
  useLazyUpdateObjectionOneQuery,
  useLazyUpdateObjectionThreeQuery,
  useLazyUpdateObjectionTwoQuery,
  useLazyUpdateOwnRentQuery,
  useLazyUpdatePainPointOneQuery,
  useLazyUpdatePainPointTwoQuery,
  useLazyUpdatePetsQuery,
  useLazyUpdateResidenceQuery,
  useLazyUpdateSecretSauceQuery,
  useLazyUpdateSpectrumOfFormalityQuery,
  useLazyUpdateSuccessStepOneQuery,
  useLazyUpdateSuccessStepThreeQuery,
  useLazyUpdateSuccessStepTwoQuery
} from '../api/icSlice'
import {
  useLazyBuildOurCompetitionQuery,
  useLazyBuildOurShortNarrativeQuery,
  useLazyBuildOurSolutionQuery,
  useLazyBuildPitchQuery,
  useLazyBuildWhoNeedsQuery,
  useLazyBuildWhoWeAreQuery,
  useLazyBuildWhoWeServeQuery
} from '../api/brandPitchSlice'

import { RootState } from '../Store'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLazyUpgradeUserQuery } from '../api/usersSlice'
import { useSession } from '../customHooks/useSession'
import { useState } from 'react'

export const usePillarCommunication = (): {
  isTalking: boolean
  getEmpathyMapSummaries: () => Promise<boolean>
  additionalFunctionCall: (arg: string) => void
  callServer: (arg: any, dataPoint: string) => Promise<boolean>
} => {
  useSession()
  const dispatch = useDispatch()
  const [updatePainPointOne] = useLazyUpdatePainPointOneQuery()
  const [updatePainPointTwo] = useLazyUpdatePainPointTwoQuery()
  const [updateObjectionOne] = useLazyUpdateObjectionOneQuery()
  const [updateObjectionTwo] = useLazyUpdateObjectionTwoQuery()
  const [updateObjectionThree] = useLazyUpdateObjectionThreeQuery()
  const [buildDoing] = useLazyBuildDoingQuery()
  const [buildFeeling] = useLazyBuildFeelingQuery()
  const [buildSaying] = useLazyBuildSayingQuery()
  const [buildSeeking] = useLazyBuildSeekingQuery()
  const [updateAgeRange] = useLazyUpdateAgeRangeQuery()
  const [updateMaritalStatus] = useLazyUpdateMaritalStatusQuery()
  const [updateChildren] = useLazyUpdateChildrenQuery()
  const [updatePets] = useLazyUpdatePetsQuery()
  const [updateEducation] = useLazyUpdateEducationQuery()
  const [updateEthnicity] = useLazyUpdateEthnicityQuery()
  const [updateIncome] = useLazyUpdateIncomeQuery()
  const [updateOwnRent] = useLazyUpdateOwnRentQuery()
  const [updateResidence] = useLazyUpdateResidenceQuery()
  const [updateNameGender] = useLazyUpdateNameGenderQuery()
  const [updateAvatar] = useLazyUpdateAvatarQuery()
  const [updateBuyerIsSeeking] = useLazyUpdateBuyerIsSeekingQuery()
  const [updateBuyerIsSaying] = useLazyUpdateBuyerIsSayingQuery()
  const [updateBuyerIsDoing] = useLazyUpdateBuyerIsDoingQuery()
  const [updateBuyerIsFeeling] = useLazyUpdateBuyerIsFeelingQuery()
  const [updateSpectrumOfFormality] = useLazyUpdateSpectrumOfFormalityQuery()
  const [updateCompanyCorePurposeOne] = useLazyUpdateCompanyCorePurposeOneQuery()
  const [updateCompanyCorePurposeTwo] = useLazyUpdateCompanyCorePurposeTwoQuery()
  const [updateCompanyCorePurposeThree] = useLazyUpdateCompanyCorePurposeThreeQuery()
  const [updateCompanyCorePurposeFour] = useLazyUpdateCompanyCorePurposeFourQuery()
  const [updateCompanyCorePurposeFive] = useLazyUpdateCompanyCorePurposeFiveQuery()
  const [updateCoreServiceOne] = useLazyUpdateCoreServiceOneQuery()
  const [updateCoreServiceTwo] = useLazyUpdateCoreServiceTwoQuery()
  const [updateCoreServiceThree] = useLazyUpdateCoreServiceThreeQuery()
  const [updateModeOfCommunicationOne] = useLazyUpdateModeOfCommunicationOneQuery()
  const [updateModeOfCommunicationTwo] = useLazyUpdateModeOfCommunicationTwoQuery()
  const [updateModeOfCommunicationThree] = useLazyUpdateModeOfCommunicationThreeQuery()
  const [updateModeOfCommunicationFour] = useLazyUpdateModeOfCommunicationFourQuery()
  const [updateSuccessStepOne] = useLazyUpdateSuccessStepOneQuery()
  const [updateSuccessStepTwo] = useLazyUpdateSuccessStepTwoQuery()
  const [updateSuccessStepThree] = useLazyUpdateSuccessStepThreeQuery()
  const [getEmpathyMapData] = useLazyGetEmpathyMapDataQuery()
  const [updateSecretSauce] = useLazyUpdateSecretSauceQuery()
  const [buildCustomerValue] = useLazyBuildCustomerValueQuery()
  const [buildPainPointSolutions] = useLazyBuildPainPointSolutionsQuery()
  const [buildPainPoints] = useLazyBuildPainPointsQuery()
  const [buildPriorBadExperiences] = useLazyBuildPriorBadExperiencesQuery()
  const [buildPriorBadExperiencesSolution] = useLazyBuildPriorBadExperiencesSolutionQuery()
  const [buildSaleObjections] = useLazyBuildSaleObjectionsQuery()
  const [buildSaleObjectionsSolutions] = useLazyBuildSaleObjectionsSolutionsQuery()
  const [buildOurCompetition] = useLazyBuildOurCompetitionQuery()
  const [buildOurShortNarrative] = useLazyBuildOurShortNarrativeQuery()
  const [buildOurSolution] = useLazyBuildOurSolutionQuery()
  const [buildPitch] = useLazyBuildPitchQuery()
  const [buildWhoNeeds] = useLazyBuildWhoNeedsQuery()
  const [buildWhoWeAre] = useLazyBuildWhoWeAreQuery()
  const [buildWhoWeServe] = useLazyBuildWhoWeServeQuery()
  const [buildWeAlign] = useLazyBuildWeAlignQuery()
  const [upgradeUser] = useLazyUpgradeUserQuery()
  const [isTalking, setIsTalking] = useState(false)
  const idealId = useSelector((state: RootState) => state.IdealCustomer.id)
  const myId = useSelector((state: RootState) => state.Me.id)
  const callServer = async (arg: any, dataPoint: string) => {
    try {
      setIsTalking(true)
      switch (dataPoint) {
        case 'upgrade297':
          await upgradeUser(myId)
          setIsTalking(false)
          break
        case 'secretSauce':
          await updateSecretSauce(arg)
          setIsTalking(false)
          break
        case 'successStepThree':
          await updateSuccessStepThree(arg)
          setIsTalking(false)
          break
        case 'successStepTwo':
          await updateSuccessStepTwo(arg)
          setIsTalking(false)
          break
        case 'successStepOne':
          await updateSuccessStepOne(arg)
          setIsTalking(false)
          break
        case 'modeOfCommunicationFour':
          await updateModeOfCommunicationFour(arg)
          setIsTalking(false)
          break
        case 'modeOfCommunicationThree':
          await updateModeOfCommunicationThree(arg)
          setIsTalking(false)
          break
        case 'modeOfCommunicationTwo':
          await updateModeOfCommunicationTwo(arg)
          setIsTalking(false)
          break
        case 'modeOfCommunicationOne':
          await updateModeOfCommunicationOne(arg)
          setIsTalking(false)
          break
        case 'coreServiceThree':
          await updateCoreServiceThree(arg)
          setIsTalking(false)
          break
        case 'coreServiceTwo':
          await updateCoreServiceTwo(arg)
          setIsTalking(false)
          break
        case 'coreServiceOne':
          await updateCoreServiceOne(arg)
          setIsTalking(false)
          break
        case 'companyCorePurposeFive':
          await updateCompanyCorePurposeFive(arg)
          setIsTalking(false)
          break
        case 'companyCorePurposeFour':
          await updateCompanyCorePurposeFour(arg)
          setIsTalking(false)
          break
        case 'companyCorePurposeThree':
          await updateCompanyCorePurposeThree(arg)
          setIsTalking(false)
          break
        case 'companyCorePurposeTwo':
          await updateCompanyCorePurposeTwo(arg)
          setIsTalking(false)
          break
        case 'companyCorePurposeOne':
          await updateCompanyCorePurposeOne(arg)
          setIsTalking(false)
          break
        case 'spectrumCasual':
          await updateSpectrumOfFormality(arg)
          setIsTalking(false)
          break
        case 'buyerFeeling':
          await updateBuyerIsFeeling(arg)
          setIsTalking(false)
          break
        case 'buyerDoing':
          await updateBuyerIsDoing(arg)
          setIsTalking(false)
          break
        case 'buyerSaying':
          await updateBuyerIsSaying(arg)
          setIsTalking(false)
          break
        case 'painPointOne':
          await updatePainPointOne(arg)
          setIsTalking(false)
          break
        case 'painPointTwo':
          await updatePainPointTwo(arg)
          setIsTalking(false)
          break
        case 'objectionOne':
          await updateObjectionOne(arg)
          setIsTalking(false)
          break
        case 'objectionTwo':
          await updateObjectionTwo(arg)
          setIsTalking(false)
          break
        case 'objectionThree':
          await updateObjectionThree(arg)
          setIsTalking(false)
          break
        case 'buyerSeeking':
          await updateBuyerIsSeeking(arg)
          setIsTalking(false)
          break
        case 'avatar':
          await updateAvatar(arg)
          setIsTalking(false)
          break
        case 'name':
          await updateNameGender(arg)
          setIsTalking(false)
          break
        case 'residence':
          await updateResidence(arg)
          setIsTalking(false)
          break
        case 'ownRent':
          await updateOwnRent(arg)
          setIsTalking(false)
          break
        case 'houseHoldIncome':
          await updateIncome(arg)
          setIsTalking(false)
          break
        case 'ethnicity':
          await updateEthnicity(arg)
          setIsTalking(false)
          break
        case 'education':
          console.log('Education', arg)
          await updateEducation(arg)
          setIsTalking(false)
          break
        case 'hasPets':
          await updatePets(arg)
          setIsTalking(false)
          break
        case 'customersChildren':
          await updateChildren(arg)
          setIsTalking(false)
          break
        case 'maritalStatus':
          await updateMaritalStatus(arg)
          setIsTalking(false)
          break
        case 'ageRangeStart':
          await updateAgeRange(arg).unwrap()
          setIsTalking(false)
          break
      }
      return true
    } catch (e) {
      console.log('Pillar Communication Error', e)
      setIsTalking(false)
      return false
    }
  }

  const additionalFunctionCall = (arg: string) => {
    switch (arg) {
      case 'buildWhoWeServe':
        completeWhoWeServe()
        break
      case 'buildOurCompetition':
        break
      case 'buildOurSolution':
        completeOurSolution()
        break
      case 'buildOurShortNarrative':
        completeShortNarrative()
        completeBrandPitch()
        completeOurSolution()
        completeOurCompetition()
        break
      case 'buildPriorBadExperiences':
        completeBadExperiences()
        break
      case 'buildSaleObjections':
        completeSaleObjections()
        break
      case 'buildPainPoints':
        completePainPoints()
        completeWhoNeeds()
        break
      case 'buildWeAlign':
        completeWeAlign()
        completeWhoWeAre()
        break
      case 'buildCustomerValue':
        completeCustomerValue()
        break
      case 'buildSeeking':
        completeSeeking()
        break
      case 'buildSaying':
        completeSaying()
        break
      case 'buildDoing':
        completeDoing()
        break
      case 'buildFeeling':
        completeFeeling()
        break
    }
  }

  const completeBrandPitch = async () => {
    try {
      const result = await buildPitch(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeShortNarrative = async () => {
    try {
      const result = await buildOurShortNarrative(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeOurCompetition = async () => {
    try {
      const result = await buildOurCompetition(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeOurSolution = async () => {
    try {
      const result = await buildOurSolution(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeWhoWeAre = async () => {
    try {
      const result = await buildWhoWeAre(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeWhoNeeds = async () => {
    try {
      const result = await buildWhoNeeds(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeWhoWeServe = async () => {
    try {
      const result = await buildWhoWeServe(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeBadExperiences = async () => {
    try {
      const result = await buildPriorBadExperiences(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      const result2 = await buildPriorBadExperiencesSolution(idealId).unwrap()
      if (result2) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeSaleObjections = async () => {
    try {
      const result = await buildSaleObjections(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      const result2 = await buildSaleObjectionsSolutions(idealId).unwrap()
      if (result2) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completePainPoints = async () => {
    try {
      const result = await buildPainPoints(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      const result2 = await buildPainPointSolutions(idealId).unwrap()
      if (result2) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeCustomerValue = async () => {
    try {
      const result = await buildCustomerValue(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }
  const completeWeAlign = async () => {
    try {
      const result = await buildWeAlign(idealId).unwrap()
      if (result) {
        console.log('Here is result', result)
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const completeSeeking = async () => {
    try {
      const result = await buildSeeking(10).unwrap()
      if (result) {
        console.log('Here is result', result)
        console.log('Idea', result.completeSeeking.buyerDoingSummary)
        dispatch(updateIdeal(result.completeSeeking))
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }
  const completeSaying = async () => {
    try {
      const result = await buildSaying(10).unwrap()
      if (result) {
        console.log('Here is result', result)
        console.log('Idea', result.completeSaying.buyerDoingSummary)
        dispatch(updateIdeal(result.completeSaying))
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }
  const completeDoing = async () => {
    try {
      const result = await buildDoing(10).unwrap()
      if (result) {
        console.log('Here is result', result)
        console.log('Idea', result.completeDoing.buyerDoingSummary)
        dispatch(updateIdeal(result.completeDoing))
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }
  const completeFeeling = async () => {
    try {
      const result = await buildFeeling(10).unwrap()
      if (result) {
        console.log('Here is result', result)
        console.log('Idea', result.completeFeeling.buyerDoingSummary)
        dispatch(updateIdeal(result.completeFeeling))
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  const getEmpathyMapSummaries = async () => {
    try {
      const result = await getEmpathyMapData(10).unwrap()
      if (result) {
        dispatch(updateIdeal(result.getEmpathyMap))
      }
      return true
    } catch (e) {
      console.log('error ')
      console.log('Error on Getting summaries', e)
      return false
    }
  }

  return { additionalFunctionCall, isTalking, callServer, getEmpathyMapSummaries }
}

import { gql } from 'graphql-request'
export const getIdealCustomerByIdHelper = (id: string) => {
  return gql`
  query getIdealCustomerById{
    getIdealCustomerById(id: "${id}") {
      id
      ownerId
      customerName
      avatar
      ageRange
      ageRangeStart
      ageRangeEnd
      gender
      maritalStatus
      hasChildren
      numberOfChildren
      childrenAgeRange
      hasPets
      pets
      education
      ethnicity
      houseHoldIncome
      ownRent
      residence
      buyerSeeking
      buyerSaying
      buyerDoing
      buyerFeeling
      buyerSeekingSummary
      buyerSayingSummary
      buyerDoingSummary
      buyerFeelingSummary
      painPointOne
      painPointOneSolution
      painPointTwo
      painPointTwoSolution
      painPointThree
      painPointThreeSolution
      objectionOne
      objectionOneOvercome
      objectionTwo
      objectionTwoOvercome
      objectionThree
      objectionThreeOvercome
      spectrumCasual
      spectrumFormal
      spectrumHumorous
      spectrumSerious
      spectrumIrreverent
      spectrumRespectful
      spectrumEnthusiastic
      spectrumMatterOfFact
      companyCorePurposeOne
      companyCorePurposeTwo
      companyCorePurposeThree
      companyCorePurposeFour
      companyCorePurposeFive
      coreServiceOne
      coreServiceTwo
      coreServiceThree
      modeOfCommunicationOne
      modeOfCommunicationTwo
      modeOfCommunicationThree
      modeOfCommunicationFour
      successStepOne
      successStepTwo
      successStepThree
      secretSauce
    }
  }
  `
}

import { gql } from 'graphql-request'
export const upgradeUserHelper = (id: string) => {
  return gql`
    mutation upgradeUser {
      upgradeUserPurchase(user: {
        id: "${id}",
        hasUpgraded: ${true}
      }) {
        id
      }
    }
  `
}

import './App.css'

import { NavigationRouter } from './navigation/NavigationRouter'
import React from 'react'

function Main() {
  return (
    <div className="App">
      <NavigationRouter />
    </div>
  )
}

export default Main

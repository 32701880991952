import React, { FC } from 'react'

import { RootState } from '../../Store'
import { SauceItem } from './SauceItem'
import { SecretSauce } from '../../types/AppTypes'
import { ViewAllTextButton } from '../buttons/ViewAllTextButton'
import { useSelector } from 'react-redux'

export const SecretSaucePanel: FC = () => {
  // Get Secret Sauce
  const secretSauce = useSelector((state: RootState) => state.IdealCustomer.secretSauce as SecretSauce[])
  return (
    <div className="col-md-12">
      <div className="secret-sauce">
        <div className="inner-row row">
          <div className="col-md-9">
            <h4>Your Company's Secret Sauce</h4>
          </div>
          <ViewAllTextButton onClick={() => console.log('viewAllClick')} isHidden />
          <div className="col-md-12">
            <div className="table-responsive" style={{ maxHeight: '200px' }}>
              <table className="table">
                <tbody>
                  <SauceItem name={secretSauce[0].answer} copy={secretSauce[0].superPower} />
                  <SauceItem name={secretSauce[1].answer} copy={secretSauce[1].superPower} />
                  <SauceItem name={secretSauce[2].answer} copy={secretSauce[2].superPower} />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

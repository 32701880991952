import { BrandPitch, useLazyGetBrandPitchDataQuery } from '../api/brandPitchSlice'
import React, { FC, useEffect, useState } from 'react'

import { BrandPitchSquareComponent } from '../components/BrandPitchSquareComponent'
import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { SMALL_LOGO } from '../constants/ImageConstants'
import { getItemById } from '../utils/ArrayUtils'
import { useLocation } from 'react-router-dom'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'
import { useSelector } from 'react-redux'

export const BrandGPTScreen: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const { updateProgress, nextPage, goBack } = useScreenNavigation()
  const [getBrandPitchData] = useLazyGetBrandPitchDataQuery()
  const ideal = useSelector((state: RootState) => state.IdealCustomer)
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const [data, setData] = useState<BrandPitch | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }
  const nextPageClick = () => {
    console.log(JSON.stringify(ideal))
    nextPage()
  }
  const getData = async () => {
    setIsLoading(true)
    try {
      const result = await getBrandPitchData(10)
      console.log('result', result?.data?.getBrandPitchByOwner)
      if (result.data) {
        setData(result?.data?.getBrandPitchByOwner)
      }
      setIsLoading(false)
    } catch (e) {
      console.log('Error')
      setIsLoading(false)
    }
  }
  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
    getData()
  }, [id])
  return (
    <div className="mr rmsteps email_confirmation brand_pitch_gpt">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={SMALL_LOGO} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>{question.h2Copy}</h2>
          </center>
          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields customer_align brand_pitch">
                {isLoading ? null : (
                  <>
                    <div className="form-field duo-input">
                      <BrandPitchSquareComponent useOne title="We Are:" subTitle="(Name, Brief Biz Description-10 words or less)" body={data?.whoWeAre ?? ''} />
                      <BrandPitchSquareComponent title="We Serve:" subTitle="(Ideal Customer)" body={data?.weServe ?? ''} />
                      <BrandPitchSquareComponent title="Who Need:" subTitle="(#1 Pain Point or amalgam of pain points)" body={data?.whoNeeds ?? ''} />
                      <BrandPitchSquareComponent title="Our Solutions:" subTitle="(How we feel the defined needs)" body={data?.ourSolution ?? ''} />
                    </div>
                    <div className="form-field duo-input">
                      <BrandPitchSquareComponent useOne title="Versus:" subTitle="(Competitors, Lessor Solutions)" body={data?.versus ?? ''} />
                      <BrandPitchSquareComponent
                        title="Short Narrative:"
                        subTitle="(Primary competitor(s); their position-ing; your competitive advantage)"
                        body={data?.shortNarrative ?? ''}
                      />
                      <BrandPitchSquareComponent
                        title="Pitch:"
                        subTitle="(Use data collected to write 30 seconds or less spoken pitch)"
                        body={data?.pitch ?? ''}
                      />
                    </div>
                  </>
                )}
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isLoading} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import {
  RS_COMMITMENT_ICON,
  RS_DELIGHT_ICON,
  RS_IMPRESSION_ICON,
  RS_LONG_TERM,
  RS_LOVERS_ICON,
  RS_PAIN_POINT_ICON,
  RS_RAPPORT_ICON,
  RS_RECOMMIT_ICON,
  RS_TRIBAL_ICON,
  RS_WIN_BACK
} from '../../../constants/ImageConstants'
import React, { FC, useEffect, useState } from 'react'

import { LoadingIconComponent } from '../../../components/loadingIcon/LoadingIconComponent'
import { PeopleWeLoveRowComponent } from '../../../components/PeopleWeLoveRowComponent'
import { RelationshipStageRowComponent } from '../../../components/RelationshipStageRowComponent'
import { RenderedAIEmails } from '../../../types/AppTypes'
import { useRampBuilder } from '../../../customHooks/useRampBuilder'

export const RelationshipStagesSceneComponent: FC = () => {
  const [isDownloading, setIsDownLoading] = useState(true)
  const { getEmailsByStage } = useRampBuilder()
  const [emails, setEmails] = useState<RenderedAIEmails | null>(null)

  const getData = async () => {
    const result = await getEmailsByStage('stage1')
    console.log('result', result)
    setEmails(result.emails)
    setIsDownLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])

  const renderMiddle = () => {
    if (isDownloading) {
      return (
        <div className="col-md-12">
          <LoadingIconComponent />
        </div>
      )
    }
    if (emails) {
      return (
        <div className="col-md-12">
          <RelationshipStageRowComponent title="Stage 1: First Impression" icon={RS_IMPRESSION_ICON} bigText={emails.stageOne} />
          <RelationshipStageRowComponent title="Stage 2: Pain Point Segmentation" icon={RS_PAIN_POINT_ICON} bigText={emails.stageTwo} />
          <RelationshipStageRowComponent title="Stage 3: Tribal Alignment" icon={RS_TRIBAL_ICON} bigText={emails.stageThree} />
          <RelationshipStageRowComponent title="Stage 4: Rapport" icon={RS_RAPPORT_ICON} bigText={emails.stageFour} />
          <RelationshipStageRowComponent title="Stage 5: Commitment" icon={RS_COMMITMENT_ICON} bigText={emails.stageFive} />
          <RelationshipStageRowComponent title="Stage 6: Customer Delight" icon={RS_DELIGHT_ICON} bigText={emails.stageSix} />
          <RelationshipStageRowComponent title="Stage 7: Lovers & Haters" icon={RS_LOVERS_ICON} bigText={emails.stageSeven} />
          <RelationshipStageRowComponent title="Stage 8: Recommitment" icon={RS_RECOMMIT_ICON} bigText={emails.stageEight} />
          <RelationshipStageRowComponent title="Stage 9: Winback " icon={RS_WIN_BACK} bigText={emails.stageNine} />
          <RelationshipStageRowComponent title="Stage 10: Long-Term Love" icon={RS_LONG_TERM} bigText={emails.stageTen} />
        </div>
      )
    }
    return null
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Relationship Stages</h1>
      </div>
      <div className="row main-row">
        <div className="col-md-12" />
        <div className="col-md-12">
          <div className="row inner-row c_list dashboard_content">
            <div className="col-md-12">
              <h2 className="">10 Relationship Stage of the RAMP</h2>
            </div>
            {renderMiddle()}
          </div>
        </div>
      </div>
    </div>
  )
}

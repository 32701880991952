import { gql } from 'graphql-request'
export const buildSalesObjectionOvercomeHelper = (idealCustomerId: string) => {
  return gql`
    query setObjectionSolutions {
      setObjectionSolutions (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

import { AGE_RANGE, AGE_RANGE_CUSTOMER } from '../constants/DropDownConstants'
import { AgeRange, Question } from '../types/AppTypes'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RadioButtonSquareComponent } from '../components/RadioButtonSquareComponent'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { getItemById } from '../utils/ArrayUtils'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

const NA = [{ label: 'N/A', value: { start: 0, end: 0 } }]
const AGE_RANGE_ARRAY = [...AGE_RANGE_CUSTOMER, ...NA]
export const AgeRangeScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const [question] = useState<Question>(getItemById(questions, id ?? ''))
  const { updateProgress, nextPage, goBack } = useScreenNavigation()
  const { isTalking, callServer } = usePillarCommunication()
  const ageRange = useSelector((state: RootState) => state.IdealCustomer.ageRange)
  const [answerValue, setAnswerValue] = useState(ageRange)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }
  const nextPageClick = async () => {
    const item = AGE_RANGE_ARRAY.find((item: AgeRange) => item.label === answerValue)
    if (!item) return
    const result = await callServer({ start: item.value.start, end: item.value.end, ageRange: answerValue }, question.propertyName)
    if (result && item) {
      dispatch(
        updateIdeal({
          ageRange: answerValue,
          ageRangeStart: item.value.start,
          ageRangeEnd: item.value.end
        })
      )
      nextPage()
    }
  }

  useEffect(() => {
    updateProgress(question.id)
  }, [])

  const renderButtons = () => {
    const items = AGE_RANGE_ARRAY.map((item: AgeRange, index: number) => {
      return <RadioButtonSquareComponent label={item.label} isSelected={answerValue === item.label} onClick={setAnswerValue} key={question.id + index} />
    })
    return items
  }
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>{question.h2Copy}</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field fw">
                  <div className="radio-buttons">{renderButtons()}</div>
                </div>
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

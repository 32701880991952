import { Colors } from '../../constants/Theme'

export const RampProgressBarStyles = {
  container: {
    width: '100%',
    height: '16px',
    display: 'flex',
    paddingLeft: 30,
    alignItems: 'center',
    flexDirection: 'column' as const
  },
  backBar: {
    height: '16px',
    width: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row' as const,
    position: 'relative' as const,
    top: -17
  },
  backBarProgress: {
    height: '16px',
    width: '600px',
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#85d8ed',
    justifyContent: 'space-between',
    flexDirection: 'row' as const
  },
  backBarText: {
    height: '16px',
    width: '650px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '9px',
    fontWeight: '600',
    flexDirection: 'row' as const,
    position: 'relative' as const,
    top: -75
  }
}

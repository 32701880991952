import { EmailObj } from '../../rampSlice'
import { gql } from 'graphql-request'
export const buildEmailsStageHelper = (blob: EmailObj) => {
  return gql`
  mutation ${blob.functionName}{
    ${blob.functionName}(rampId: "${blob.rampId}") {
      id
    }
  }
  `
}

import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EmpathyMapBoxComponent } from '../components/EmpathyMapBoxComponent'
import { NextButtonComponent } from '../components/buttons/NextButtonComponent'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { TransparentButtonComponent } from '../components/buttons/TransparentButtonComponent'
import { getItemById } from '../utils/ArrayUtils'
import { insertNames } from '../utils/StringUtils'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const EmpathyMapScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)

  const { nextPage, goBack, updateProgress } = useScreenNavigation()

  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const { getEmpathyMapSummaries } = usePillarCommunication()
  const { buyerSeekingSummary, buyerSayingSummary, buyerDoingSummary, buyerFeelingSummary } = useSelector((state: RootState) => {
    return {
      buyerSeekingSummary: state.IdealCustomer.buyerSeekingSummary,
      buyerSayingSummary: state.IdealCustomer.buyerSayingSummary,
      buyerDoingSummary: state.IdealCustomer.buyerDoingSummary,
      buyerFeelingSummary: state.IdealCustomer.buyerFeelingSummary
    }
  })
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPage()
  }
  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
    getData()
  }, [id])

  const getData = async () => {
    console.log('Async')
    const result = await getEmpathyMapSummaries()
    if (result) {
      console.log('I have all of it')
    }
  }

  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            {question.h2Copy ? <h2>{question.h2Copy}</h2> : null}
            {question.pCopy ? <p>{insertNames(question.pCopy, firstName, idealCustomerName)}</p> : null}
          </center>
          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field duo-input">
                  <EmpathyMapBoxComponent isLeft title="What the ideal customer is seeking" body={buyerSeekingSummary} />
                  <EmpathyMapBoxComponent title="What the ideal customer is saying" body={buyerSayingSummary} />
                </div>
                <div className="form-field duo-input">
                  <EmpathyMapBoxComponent isLeft title="What the ideal customer is Doing" body={buyerDoingSummary} />
                  <EmpathyMapBoxComponent title="What the ideal customer is Feeling" body={buyerFeelingSummary} />
                </div>
                <div className="grp-btns">
                  <TransparentButtonComponent onClick={goBack} />
                  <NextButtonComponent onClick={nextPage} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

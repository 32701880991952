import { CognitoUser } from 'amazon-cognito-identity-js'
import getCognitoUser from './getCognitoUser'

const verifyCognitoCode = async (username: string, code: string): Promise<any> => {
  return await new Promise(function(resolve, reject) {
    const cognitoUser: CognitoUser = getCognitoUser(username)

    cognitoUser.confirmRegistration(code, true, function(err, result) {
      if (err) {
        const error = JSON.parse(JSON.stringify(err))
        error.message = err.message
        if (error.name === 'NotAuthorizedException' && error.message.includes('Current status is CONFIRMED')) {
          resolve('SUCCESS')
        }
        reject(error)
      } else {
        resolve(result)
      }
    })
  }).catch(err => {
    console.log('throwError', err)
    return err
  })
}

export default verifyCognitoCode

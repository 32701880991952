import { CustomerAlignment, useLazyGetCustomerAlignmentByIdQuery } from '../api/caSlice'
import React, { FC, useEffect, useState } from 'react'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { CustomerAlignmentBoxComponent } from '../components/CustomerAlignmentBoxComponent'
import { LoadingIconComponent } from '../components/loadingIcon/LoadingIconComponent'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { getItemById } from '../utils/ArrayUtils'
import { insertNames } from '../utils/StringUtils'
import { isCustomerAlignmentDone } from '../utils/IdealCustomerUtil'
import { useLocation } from 'react-router-dom'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'
import { useSelector } from 'react-redux'

export const CustomerAlignmentScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const idealCustomerId = useSelector((state: RootState) => state.IdealCustomer.id)
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)
  const [getCustomerAlignmentById] = useLazyGetCustomerAlignmentByIdQuery()
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const [caData, setCaData] = useState<CustomerAlignment | undefined>()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPage()
  }
  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
  }, [id])
  const getData = async () => {
    try {
      const result = await getCustomerAlignmentById(idealCustomerId).unwrap()
      if (isCustomerAlignmentDone(result.getCustomerAlignmentById)) {
        setCaData(result.getCustomerAlignmentById)
        return
      }
      setTimeout(getData, 1000)
    } catch (e) {
      console.log('error', e)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  const renderMiddle = () => {
    if (caData) {
      return (
        <div className="form-fields customer_align">
          {!caData ? null : (
            <>
              <div className="form-field duo-input">
                <CustomerAlignmentBoxComponent isLeft title="Your Customers Value:" copy={caData.customerValue} />
                <CustomerAlignmentBoxComponent title="How your company aligns with their value:" copy={caData.weAlign} />
              </div>
              <div className="form-field duo-input">
                <CustomerAlignmentBoxComponent title="Your customers pain points:" copy={caData.painPoints} />
                <CustomerAlignmentBoxComponent title="How your company solves these:" copy={caData.solvesPainPoints} />
              </div>
              <div className="form-field duo-input">
                <CustomerAlignmentBoxComponent isLeft title="Your customer objection to sale:" copy={caData.saleObjections} />
                <CustomerAlignmentBoxComponent title="How your company solves these objections:" copy={caData.objectionSolutions} />
              </div>
              <div className="form-field duo-input">
                <CustomerAlignmentBoxComponent title="Your customers concern/prior bad experience/failed outcomes:" copy={caData.priorBadExperience} />
                <CustomerAlignmentBoxComponent title="How your company can assure them you can solve this for theme" copy={caData.solveBadExperience} />
              </div>
            </>
          )}

          <ButtonGroupComponent goBack={goBack} nextPageClick={nextPage} processing={!caData} />
        </div>
      )
    }
    return (
      <div className="grp-btns">
        <LoadingIconComponent />
      </div>
    )
    // setCaData(result.getCustomerAlignmentById)
  }

  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>{question.h2Copy}</h2>
            {question.pCopy ? <p>{insertNames(question.pCopy, firstName, idealCustomerName)}</p> : null}
          </center>
          <div className="form-elements">
            <form onSubmit={handleSubmit}>{renderMiddle()}</form>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { FC, useEffect, useState } from 'react'
import { createOptionsArray, getItemById } from '../utils/ArrayUtils'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { SelectionComponent } from '../components/SelectionComponent'
import { TextAreaFormField } from '../components/TextAreaFormFieldComponent'
import { insertNames } from '../utils/StringUtils'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const PainPointScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)

  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)

  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const { additionalFunctionCall, isTalking, callServer } = usePillarCommunication()

  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))

  const { startValue, startValue2 } = useSelector((state: RootState) => {
    return { startValue: state.IdealCustomer[question.propertyName], startValue2: state.IdealCustomer[question.propertyName2 ?? ''] }
  })

  const [answerValue, setAnswerValue] = useState(startValue)
  const [answerValue2, setAnswerValue2] = useState(startValue2)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    nextPageClick()
  }
  const nextPageClick = async () => {
    const result = await callServer({ problem: answerValue, solution: answerValue2 }, question.propertyName)
    if (result) {
      dispatch(
        updateIdeal({
          [question.propertyName]: answerValue,
          [question.propertyName2!]: answerValue2
        })
      )
      nextPage()
    }
  }
  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
    setAnswerValue(startValue)
    setAnswerValue2(startValue2)
    if (newQuestion.functionCall) {
      additionalFunctionCall(newQuestion.functionCall)
    }
  }, [id])

  useEffect(() => {
    setAnswerValue(startValue)
    setAnswerValue2(startValue2)
  }, [question.h2Copy])
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <img src={question.icon} alt="" />
            <h2 dangerouslySetInnerHTML={{ __html: insertNames(question.h2Copy, firstName, idealCustomerName) }} />
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field fw">
                  <SelectionComponent
                    value={answerValue}
                    options={createOptionsArray(question.options)}
                    label={question.placeholderText ?? ''}
                    onChange={setAnswerValue}
                  />
                  <TextAreaFormField label="&nbsp;" value={answerValue2} onChange={setAnswerValue2} placeHolder={question.placeholderText2 ?? ''} />
                </div>
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import { buildOurCompetitionHelper } from './jsonHelpers/brandPitchHelpers/buildOurCompetitionHelper'
import { buildOurSolutionHelper } from './jsonHelpers/brandPitchHelpers/buildOurSolutionHelper'
import { buildPitchHelper } from './jsonHelpers/brandPitchHelpers/buildPitchHelpter'
import { buildShortNarrativeHelper } from './jsonHelpers/brandPitchHelpers/buildShortNarrativeHelper'
import { buildWhoNeedsHelper } from './jsonHelpers/brandPitchHelpers/buildWhoNeedsHelper'
import { buildWhoWeAreHelper } from './jsonHelpers/brandPitchHelpers/buildWhoWeAreHelper'
import { buildWhoWeServeHelper } from './jsonHelpers/brandPitchHelpers/buildWhoWeServeHelper'
import { createApi } from '@reduxjs/toolkit/query/react'
import { getBrandPitchSummary } from './jsonHelpers/getBrandPitchHelper'
import { graphqlBaseQuery } from './graphqlBaseQuery'

export interface BrandPitch {
  id: string
  idealCustomerId: string
  ownerId: string
  whoWeAre: string
  weServe: string
  whoNeeds: string
  ourSolution: string
  versus: string
  shortNarrative: string
  pitch: string
}
interface BrandPitchGet {
  id: string
  idealCustomerId: string
  ownerId: string
  whoWeAre: string
  weServe: string
  whoNeeds: string
  ourSolution: string
  versus: string
  shortNarrative: string
  pitch: string
  getBrandPitchByOwner: BrandPitch
}
export const brandPitchSlice = createApi({
  reducerPath: 'brandPitchApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_SERVER!
  }),
  endpoints: builder => ({
    getBrandPitchData: builder.query<BrandPitchGet, number>({
      query: () => ({
        body: getBrandPitchSummary()
      })
    }),
    buildWhoWeAre: builder.query<BrandPitch, string>({
      query: arg => ({
        body: buildWhoWeAreHelper(arg)
      })
    }),
    buildWhoWeServe: builder.query<BrandPitch, string>({
      query: arg => ({
        body: buildWhoWeServeHelper(arg)
      })
    }),
    buildWhoNeeds: builder.query<BrandPitch, string>({
      query: arg => ({
        body: buildWhoNeedsHelper(arg)
      })
    }),
    buildOurCompetition: builder.query<BrandPitch, string>({
      query: arg => ({
        body: buildOurCompetitionHelper(arg)
      })
    }),
    buildOurSolution: builder.query<BrandPitch, string>({
      query: arg => ({
        body: buildOurSolutionHelper(arg)
      })
    }),
    buildOurShortNarrative: builder.query<BrandPitch, string>({
      query: arg => ({
        body: buildShortNarrativeHelper(arg)
      })
    }),
    buildPitch: builder.query<BrandPitch, string>({
      query: arg => ({
        body: buildPitchHelper(arg)
      })
    })
  })
})

export const {
  useLazyGetBrandPitchDataQuery,
  useLazyBuildOurCompetitionQuery,
  useLazyBuildOurShortNarrativeQuery,
  useLazyBuildOurSolutionQuery,
  useLazyBuildPitchQuery,
  useLazyBuildWhoNeedsQuery,
  useLazyBuildWhoWeAreQuery,
  useLazyBuildWhoWeServeQuery
} = brandPitchSlice

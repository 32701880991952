export const STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
]

export const COUNTRIES = [
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Åland Islands', value: 'Åland Islands' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'American Samoa', value: 'American Samoa' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Anguilla', value: 'Anguilla' },
  { label: 'Antarctica', value: 'Antarctica' },
  { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Aruba', value: 'Aruba' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Bermuda', value: 'Bermuda' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Bouvet Island', value: 'Bouvet Island' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory' },
  { label: 'Brunei Darussalam', value: 'Brunei Darussalam' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Cape Verde', value: 'Cape Verde' },
  { label: 'Cayman Islands', value: 'Cayman Islands' },
  { label: 'Central African Republic', value: 'Central African Republic' },
  { label: 'Chad', value: 'Chad' },
  { label: 'Chile', value: 'Chile' },
  { label: 'China', value: 'China' },
  { label: 'Christmas Island', value: 'Christmas Island' },
  { label: 'Cocos (Keeling) Islands', value: 'Cocos (Keeling) Islands' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Comoros', value: 'Comoros' },
  { label: 'Congo', value: 'Congo' },
  { label: 'Congo, The Democratic Republic of The', value: 'Congo, The Democratic Republic of The' },
  { label: 'Cook Islands', value: 'Cook Islands' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: "Cote D'ivoire", value: "Cote D'ivoire" },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Eritrea', value: 'Eritrea' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'Falkland Islands (Malvinas)', value: 'Falkland Islands (Malvinas)' },
  { label: 'Faroe Islands', value: 'Faroe Islands' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'French Guiana', value: 'French Guiana' },
  { label: 'French Polynesia', value: 'French Polynesia' },
  { label: 'French Southern Territories', value: 'French Southern Territories' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Gibraltar', value: 'Gibraltar' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Greenland', value: 'Greenland' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Guadeloupe', value: 'Guadeloupe' },
  { label: 'Guam', value: 'Guam' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guernsey', value: 'Guernsey' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guinea-bissau', value: 'Guinea-bissau' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Heard Island and Mcdonald Islands', value: 'Heard Island and Mcdonald Islands' },
  { label: 'Holy See (Vatican City State)', value: 'Holy See (Vatican City State)' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hong Kong', value: 'Hong Kong' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'India', value: 'India' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Iran, Islamic Republic of', value: 'Iran, Islamic Republic of' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Isle of Man', value: 'Isle of Man' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Jersey', value: 'Jersey' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: "Korea, Democratic People's Republic of", value: "Korea, Democratic People's Republic of" },
  { label: 'Korea, Republic of', value: 'Korea, Republic of' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { label: "Lao People's Democratic Republic", value: "Lao People's Democratic Republic" },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libyan Arab Jamahiriya', value: 'Libyan Arab Jamahiriya' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Macao', value: 'Macao' },
  { label: 'Macedonia, The Former Yugoslav Republic of', value: 'Macedonia, The Former Yugoslav Republic of' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Martinique', value: 'Martinique' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Mayotte', value: 'Mayotte' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Micronesia, Federated States of', value: 'Micronesia, Federated States of' },
  { label: 'Moldova, Republic of', value: 'Moldova, Republic of' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'Netherlands Antilles', value: 'Netherlands Antilles' },
  { label: 'New Caledonia', value: 'New Caledonia' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Niue', value: 'Niue' },
  { label: 'Norfolk Island', value: 'Norfolk Island' },
  { label: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Palestinian Territory, Occupied', value: 'Palestinian Territory, Occupied' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Pitcairn', value: 'Pitcairn' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Puerto Rico', value: 'Puerto Rico' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Reunion', value: 'Reunion' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russian Federation', value: 'Russian Federation' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saint Helena', value: 'Saint Helena' },
  { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia', value: 'Saint Lucia' },
  { label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon' },
  { label: 'Saint Vincent and The Grenadines', value: 'Saint Vincent and The Grenadines' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'South Georgia and The South Sandwich Islands', value: 'South Georgia and The South Sandwich Islands' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen' },
  { label: 'Swaziland', value: 'Swaziland' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Syrian Arab Republic', value: 'Syrian Arab Republic' },
  { label: 'Taiwan', value: 'Taiwan' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Tanzania, United Republic of', value: 'Tanzania, United Republic of' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Timor-leste', value: 'Timor-leste' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tokelau', value: 'Tokelau' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'United States', value: 'United States' },
  { label: 'United States Minor Outlying Islands', value: 'United States Minor Outlying Islands' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Viet Nam', value: 'Viet Nam' },
  { label: 'Virgin Islands, British', value: 'Virgin Islands, British' },
  { label: 'Virgin Islands, U.S.', value: 'Virgin Islands, U.S.' },
  { label: 'Wallis and Futuna', value: 'Wallis and Futuna' },
  { label: 'Western Sahara', value: 'Western Sahara' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' }
]

export const GENDER = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'LBGTQIA+', value: 'LBGTQIA' },
  { label: 'Nonbinary', value: 'Nonbinary' }
]

export const MARITAL_STATUS_LIST = [
  { label: 'Single', value: 'Single' },
  { label: 'Engaged', value: 'Engaged' },
  { label: 'Married', value: 'Married' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Widowed', value: 'Widowed' }
]

export const INDUSTRY = [
  { label: 'Accommodation and Food Services', value: 'Accommodation and Food Services' },
  { label: 'Administration, Business Support and Waste Management Services', value: 'Administration, Business Support and Waste Management Services' },
  { label: 'Agency: Advertising, Marketing, PR, Social', value: 'Agency: Advertising, Marketing, PR, Social' },
  { label: 'Agriculture, Forestry, Fishing and Hunting', value: 'Agriculture, Forestry, Fishing and Hunting' },
  { label: 'Arts, Entertainment and Recreation', value: 'Arts, Entertainment and Recreation' },
  { label: 'Attorney/Law Firm/Legal Services', value: 'Attorney/Law Firm/Legal Services' },
  { label: 'Authors/Book Publishing', value: 'Authors/Book Publishing' },
  { label: 'Business Franchising', value: 'Business Franchising' },
  { label: 'Childcare/Day Care', value: 'Childcare/Day Care' },
  { label: 'Coaching/Consulting/Online Courses', value: 'Coaching/Consulting/Online Courses' },
  { label: 'Construction/General Contracting', value: 'Construction/General Contracting' },
  { label: 'Consumer Goods and Services', value: 'Consumer Goods and Services' },
  { label: 'E-commerce: General', value: 'E-commerce: General' },
  { label: 'Eduction: Schools, Colleges', value: 'Eduction: Schools, Colleges' },
  { label: 'Educational Services', value: 'Educational Services' },
  { label: 'Electrician/Electrical Services', value: 'Electrician/Electrical Services' },
  { label: 'Events/Event Planning', value: 'Events/Event Planning' },
  { label: 'Finance and Insurance', value: 'Finance and Insurance' },
  { label: 'Fitness, Trainers, Gyms', value: 'Fitness, Trainers, Gyms' },
  { label: 'Graphic Designer/Graphic Design Firm', value: 'Graphic Designer/Graphic Design Firm' },
  { label: 'Healthcare and Social Assistance', value: 'Healthcare and Social Assistance' },
  { label: 'HVAC Services', value: 'HVAC Services' },
  { label: 'Industrial Machinery, Gas and Chemicals', value: 'Industrial Machinery, Gas and Chemicals' },
  { label: 'Information', value: 'Information' },
  { label: 'Landscaper/Landscaping Services', value: 'Landscaper/Landscaping Services' },
  { label: 'Life Sciences', value: 'Life Sciences' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Mining, Oil & Gas', value: 'Mining, Oil & Gas' },
  { label: 'Nonprofit', value: 'Nonprofit' },
  { label: 'Online Retail', value: 'Online Retail' },
  { label: 'Other Services (except Public Administration)', value: 'Other Services (except Public Administration)' },
  { label: 'Pool Builders, Suppliers, Services', value: 'Pool Builders, Suppliers, Services' },
  { label: 'Plumbing/Plumber', value: 'Plumbing/Plumber' },
  { label: 'Private Equity, Angel Investor, Venture Funding', value: 'Private Equity, Angel Investor, Venture Funding' },
  { label: 'Professional, Scientific and Technical Services', value: 'Professional, Scientific and Technical Services' },
  { label: 'Religious Services/Churches/Pastors', value: 'Religious Services/Churches/Pastors' },
  { label: 'Real Estate Agent', value: 'Real Estate Agent' },
  { label: 'Real Estate Rental and Leasing', value: 'Real Estate Rental and Leasing' },
  { label: 'Restaurants/Cafes/Fast Food', value: 'Restaurants/Cafes/Fast Food' },
  { label: 'Retail Trade', value: 'Retail Trade' },
  { label: 'Roofing & Roofing Services', value: 'Roofing & Roofing Services' },
  { label: 'SAAS', value: 'SAAS' },
  { label: 'Software Development', value: 'Software Development' },
  { label: 'Specialist Engineering, Infrastructure and Contractors', value: 'Specialist Engineering, Infrastructure and Contractors' },
  { label: 'Sports Instructors, Associations, Venues', value: 'Sports Instructors, Associations, Venues' },
  { label: 'Technology', value: 'Technology' },
  { label: 'Tour Operator/Tourism', value: 'Tour Operator/Tourism' },
  { label: 'Taxi/transportation Services', value: 'Taxi/transportation Services' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Warehousing', value: 'Warehousing' },
  { label: 'Wholesale Trade', value: 'Wholesale Trade' }
]

export const TITLES = [
  { label: 'Advisor/Board Member', value: 'Advisor/Board Member' },
  { label: 'CEO', value: 'CEO' },
  { label: 'C-Suite', value: 'C-Suite' },
  { label: 'Director', value: 'Director' },
  { label: 'Founder/Owner', value: 'Founder/Owner' },
  { label: 'Marketing Role', value: 'Marketing Role' },
  { label: 'Sales Role', value: 'Sales Role' },
  { label: 'Ops Role', value: 'Ops Role' },
  { label: 'Service Role', value: 'Service Role' },
  { label: 'Employee', value: 'Employee' },
  { label: 'Freelancer', value: 'Freelancer' }
]

export const REVENUE = [
  { label: '$0-$500K/year', value: '$0-$500K/year' },
  { label: '$501L-$1M/year', value: '$501L-$1M/year' },
  { label: '$1.1M-$5M/year', value: '$1.1M-$5M/year' },
  { label: '$5.1M-$10M/year', value: '$5.1M-$10M/year' },
  { label: '$10.1M-$25M/year', value: '$10.1M-$25M/year' },
  { label: '$25.1M-$50M/year', value: '$25.1M-$50M/year' },
  { label: '$50.1M-$100M/year', value: '$50.1M-$100M/year' },
  { label: '$100.1M++/year', value: '$100.1M++/year' }
]

export const AGE_RANGE = [
  { label: 'infant', value: { start: 0, end: 1 } },
  { label: 'toddler', value: { start: 1, end: 3 } },
  { label: 'preschool/childhood', value: { start: 3, end: 5 } },
  { label: 'grade school/middle childhood', value: { start: 6, end: 12 } },
  { label: 'teenager/adolescent', value: { start: 13, end: 17 } },
  { label: 'young adult', value: { start: 18, end: 24 } },
  { label: 'early adulthood ', value: { start: 25, end: 34 } },
  { label: 'early middle age', value: { start: 35, end: 44 } },
  { label: 'late middle age', value: { start: 45, end: 54 } },
  { label: 'late adulthood', value: { start: 54, end: 64 } },
  { label: 'senior', value: { start: 65, end: 120 } }
]

export const AGE_RANGE_CUSTOMER = [
  { label: 'teenager/adolescent', value: { start: 13, end: 17 } },
  { label: 'young adult', value: { start: 18, end: 24 } },
  { label: 'early adulthood ', value: { start: 25, end: 34 } },
  { label: 'early middle age', value: { start: 35, end: 44 } },
  { label: 'late middle age', value: { start: 45, end: 54 } },
  { label: 'late adulthood', value: { start: 54, end: 64 } },
  { label: 'senior', value: { start: 65, end: 120 } }
]

export const HOUSEHOLD_INCOME = [
  { label: '$0-$28,999', value: 'poverty line' },
  { label: '$29,000-$48,999', value: 'lower income' },
  { label: '$49,000-$78,999', value: 'lower middle class' },
  { label: '$79,000-98,999', value: 'middle class' },
  { label: '$99,000-$128,999', value: 'upper middle class' },
  { label: '$129,000-$148,999', value: 'top 1% middle class' },
  { label: '$149,000-$199,999', value: 'top 12%' },
  { label: '$200,000-$299,999', value: 'top 7%' },
  { label: '$300,000-$499,999', value: 'top 4%' },
  { label: '$500,000-$799,999', value: 'top 2%' },
  { label: '$800,000+', value: 'top 1%' }
]

import React, { FC } from 'react'

type Props = {
  title: string
  body: string
  image: string
  itemNumber: number
  onClick: (arg: string) => void
}

export const RampCompletionRowComponent: FC<Props> = ({ title, body, onClick, image, itemNumber }: Props) => {
  const click = () => {
    switch (itemNumber) {
      case 1:
        onClick('stageOne')
        break
      case 2:
        onClick('stageTwo')
        break
      case 3:
        onClick('stageThree')
        break
      case 4:
        onClick('stageFour')
        break
      case 5:
        onClick('stageFive')
        break
      case 6:
        onClick('stageSix')
        break
      case 7:
        onClick('stageSeven')
        break
      case 8:
        onClick('stageEight')
        break
      case 9:
        onClick('stageNine')
        break
      case 10:
        onClick('stageTen')
        break
    }
  }
  return (
    <li>
      <a href="#" onClick={click}>
        <div className="ramp_com_icon" style={{ backgroundImage: `url('${image}')` }} />
        <div className="ramp_com_content">
          <h3>{title}</h3>
          <p>{body}</p>
        </div>
      </a>
    </li>
  )
}

import React, { FC, useEffect, useState } from 'react'
import { addRemove, getItemById } from '../utils/ArrayUtils'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { CheckBoxComponent } from '../components/CheckBoxComponent'
import { NextButtonComponent } from '../components/buttons/NextButtonComponent'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { TransparentButtonComponent } from '../components/buttons/TransparentButtonComponent'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useLocation } from 'react-router-dom'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const UpSellScreenComponentOne: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  // const startValue = useSelector((state: RootState) => state.IdealCustomer[question.propertyName])
  // const [answerValue, setAnswerValue] = useState(startValue)
  const [multipleAnswerValue, setMultipleAnswerValue] = useState([question.options[question.defaultAnswerIndex]])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }

  const nextPageClick = () => {
    // const sendValue = multipleAnswerValue
    nextPage()
  }

  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
  }, [id])

  const updateMultipleValue = (arg: string) => {
    setMultipleAnswerValue(addRemove(arg, multipleAnswerValue))
  }

  const isSelected = (arg: string) => {
    if (multipleAnswerValue.includes(arg)) {
      return true
    }
    return false
  }
  const renderButtons = () => {
    const items = question.options.map((item: string, index: number) => {
      return <CheckBoxComponent label={item} isSelected={isSelected(item)} onClick={updateMultipleValue} key={question.id + index} />
    })
    return items
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            {question.icon ? <img src={question.icon} alt="" /> : null}
            <h2>{question.h2Copy}</h2>
          </center>
          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field fw">
                  <div className="radio-buttons">{renderButtons()}</div>
                </div>
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} label={question.button} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import { Colors } from '../../constants/Theme'

export const RampProgressStyles = {
  container: {
    width: 180,
    height: 16,
    display: 'flex'
  },
  progress: {
    width: '60%',
    height: 16,
    display: 'flex',
    backgroundColor: Colors.red
  }
}

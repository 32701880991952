import { gql } from 'graphql-request'
export const buildSeekingHelper = () => {
  return gql`
    query completeSeeking {
      completeSeeking {
        buyerDoingSummary
        buyerFeelingSummary
        buyerSayingSummary
        buyerSeekingSummary
      }
    }
  `
}

import { AD_6, AD_8 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import {
  changeIdealCustomerAgeRange,
  changeIdealCustomerChildren,
  changeIdealCustomerChildrenAgeRange,
  changeIdealCustomerGender,
  changeIdealCustomerMaritalStatus,
  changeIdealCustomerName,
  updateQuestion
} from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { RadioButtonSquareComponent } from '../../../../components/RadioButtonSquareComponent'
import { TextInputFullWidthComponent } from '../../../../components/TextInputFullWidthComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificSevenPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const idealCustomer = useSelector((state: RootState) => state.AdSpecific.idealCustomerDemographics)
  const next = () => {
    dispatch(updateQuestion(AD_8))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_6))
  }
  const changeAge = (arg: string) => {
    dispatch(changeIdealCustomerAgeRange(arg))
  }
  const changeGender = (arg: string) => {
    dispatch(changeIdealCustomerGender(arg))
  }
  const changeMaritalStatus = (arg: string) => {
    dispatch(changeIdealCustomerMaritalStatus(arg))
  }
  const changeChildrenNumber = (arg: string) => {
    dispatch(changeIdealCustomerChildren(arg))
  }
  const changeChildrenAgeRange = (arg: string) => {
    dispatch(changeIdealCustomerChildrenAgeRange(arg))
  }
  const changeName = (arg: string) => {
    dispatch(changeIdealCustomerName(arg))
  }

  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Identify The Ideal Customer</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <div className="form-field fw">
                <h4>Demographics</h4>
              </div>
              <TextInputFullWidthComponent value={idealCustomer.name} label="Name your ideal customer" onChange={changeName} placeholder="Enter the name" />
              <div className="form-field fw">
                <label>Age Range</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="Below 17" isSelected={idealCustomer.ageRange === 'Below 17'} onClick={changeAge} />
                  <RadioButtonSquareComponent label="18 - 21" isSelected={idealCustomer.ageRange === '18 - 21'} onClick={changeAge} />
                  <RadioButtonSquareComponent label="22 - 29" isSelected={idealCustomer.ageRange === '22 - 29'} onClick={changeAge} />
                  <RadioButtonSquareComponent label="30- 39" isSelected={idealCustomer.ageRange === '30- 39'} onClick={changeAge} />
                  <RadioButtonSquareComponent label="40 - 49" isSelected={idealCustomer.ageRange === '40 - 49'} onClick={changeAge} />
                  <RadioButtonSquareComponent label="50 - 60" isSelected={idealCustomer.ageRange === '50 - 60'} onClick={changeAge} />
                  <RadioButtonSquareComponent label="60 - 69" isSelected={idealCustomer.ageRange === '60 - 69'} onClick={changeAge} />
                  <RadioButtonSquareComponent label="70+" isSelected={idealCustomer.ageRange === '70+'} onClick={changeAge} />
                </div>
              </div>
              <div className="form-field fw">
                <label>Gender</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="Male" isSelected={idealCustomer.gender === 'Male'} onClick={changeGender} />
                  <RadioButtonSquareComponent label="Female" isSelected={idealCustomer.gender === 'Female'} onClick={changeGender} />
                  <RadioButtonSquareComponent label="LGBTQIA+" isSelected={idealCustomer.gender === 'LGBTQIA+'} onClick={changeGender} />
                  <RadioButtonSquareComponent label="Nonbinary" isSelected={idealCustomer.gender === 'Nonbinary'} onClick={changeGender} />
                </div>
              </div>
              <div className="form-field fw">
                <label>Marital Status</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="Single" isSelected={idealCustomer.maritalStatus === 'Single'} onClick={changeMaritalStatus} />
                  <RadioButtonSquareComponent label="Engaged" isSelected={idealCustomer.maritalStatus === 'Engaged'} onClick={changeMaritalStatus} />
                  <RadioButtonSquareComponent label="Married" isSelected={idealCustomer.maritalStatus === 'Married'} onClick={changeMaritalStatus} />
                  <RadioButtonSquareComponent label="Divorced" isSelected={idealCustomer.maritalStatus === 'Divorced'} onClick={changeMaritalStatus} />
                  <RadioButtonSquareComponent label="Widowed" isSelected={idealCustomer.maritalStatus === 'Widowed'} onClick={changeMaritalStatus} />
                </div>
              </div>
              <div className="form-field fw">
                <label>Children?</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="0" isSelected={idealCustomer.children === '0'} onClick={changeChildrenNumber} />
                  <RadioButtonSquareComponent label="1" isSelected={idealCustomer.children === '1'} onClick={changeChildrenNumber} />
                  <RadioButtonSquareComponent label="2" isSelected={idealCustomer.children === '2'} onClick={changeChildrenNumber} />
                  <RadioButtonSquareComponent label="3" isSelected={idealCustomer.children === '3'} onClick={changeChildrenNumber} />
                  <RadioButtonSquareComponent label="4" isSelected={idealCustomer.children === '4'} onClick={changeChildrenNumber} />
                  <RadioButtonSquareComponent label="5+" isSelected={idealCustomer.children === '5+'} onClick={changeChildrenNumber} />
                </div>
              </div>
              <div className="form-field fw">
                <label>Children Age Range</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="Infant" isSelected={idealCustomer.childrenAgeRange === 'Infant'} onClick={changeChildrenAgeRange} />
                  <RadioButtonSquareComponent label="Toddler" isSelected={idealCustomer.childrenAgeRange === 'Toddler'} onClick={changeChildrenAgeRange} />
                  <RadioButtonSquareComponent label="Teenager" isSelected={idealCustomer.childrenAgeRange === 'Teenager'} onClick={changeChildrenAgeRange} />
                  <RadioButtonSquareComponent
                    label="Young Adult"
                    isSelected={idealCustomer.childrenAgeRange === 'Young Adult'}
                    onClick={changeChildrenAgeRange}
                  />
                  <RadioButtonSquareComponent label="Adult" isSelected={idealCustomer.childrenAgeRange === 'Adult'} onClick={changeChildrenAgeRange} />
                </div>
              </div>
              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

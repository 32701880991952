import {
  AVATAR_1,
  AVATAR_10,
  AVATAR_11,
  AVATAR_12,
  AVATAR_13,
  AVATAR_14,
  AVATAR_15,
  AVATAR_16,
  AVATAR_17,
  AVATAR_18,
  AVATAR_19,
  AVATAR_2,
  AVATAR_20,
  AVATAR_21,
  AVATAR_22,
  AVATAR_23,
  AVATAR_24,
  AVATAR_25,
  AVATAR_26,
  AVATAR_27,
  AVATAR_28,
  AVATAR_3,
  AVATAR_4,
  AVATAR_5,
  AVATAR_6,
  AVATAR_7,
  AVATAR_8,
  AVATAR_9,
  RAMP_LOGO_MED
} from '../constants/ImageConstants'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AvatarContainerComponent } from '../components/AvatarContainerComponent'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { TransparentButtonComponent } from '../components/buttons/TransparentButtonComponent'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const ChooseAvatarScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const dispatch = useDispatch()
  const defaultAvatar = useSelector((state: RootState) => state.IdealCustomer.avatar)
  const [selectedAvatar, setSelectedAvatar] = useState(defaultAvatar)
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const { isTalking, callServer } = usePillarCommunication()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
  }

  const changeAvatar = async (arg: string) => {
    if (isTalking) return
    setSelectedAvatar(arg)
    const result = await callServer(arg, 'avatar')
    if (result) {
      dispatch(
        updateIdeal({
          avatar: selectedAvatar
        })
      )
      nextPage()
    }
  }

  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>Fantastic name! Let's now choose an icon for you ideal customer.</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field fw">
                  <div className="avatar_picker">
                    <ul>
                      <AvatarContainerComponent image={AVATAR_1} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_1} />
                      <AvatarContainerComponent image={AVATAR_2} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_2} />
                      <AvatarContainerComponent image={AVATAR_3} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_3} />
                      <AvatarContainerComponent image={AVATAR_4} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_4} />
                      <AvatarContainerComponent image={AVATAR_5} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_5} />
                      <AvatarContainerComponent image={AVATAR_6} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_6} />
                      <AvatarContainerComponent image={AVATAR_7} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_7} />
                      <AvatarContainerComponent image={AVATAR_8} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_8} />
                      <AvatarContainerComponent image={AVATAR_9} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_9} />
                      <AvatarContainerComponent image={AVATAR_10} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_10} />
                      <AvatarContainerComponent image={AVATAR_11} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_11} />
                      <AvatarContainerComponent image={AVATAR_12} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_12} />
                      <AvatarContainerComponent image={AVATAR_13} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_13} />
                      <AvatarContainerComponent image={AVATAR_14} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_14} />
                      <AvatarContainerComponent image={AVATAR_15} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_15} />
                      <AvatarContainerComponent image={AVATAR_16} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_16} />
                      <AvatarContainerComponent image={AVATAR_17} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_17} />
                      <AvatarContainerComponent image={AVATAR_18} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_18} />
                      <AvatarContainerComponent image={AVATAR_19} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_19} />
                      <AvatarContainerComponent image={AVATAR_20} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_20} />
                      <AvatarContainerComponent image={AVATAR_21} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_21} />
                      <AvatarContainerComponent image={AVATAR_22} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_22} />
                      <AvatarContainerComponent image={AVATAR_23} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_23} />
                      <AvatarContainerComponent image={AVATAR_24} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_24} />
                      <AvatarContainerComponent image={AVATAR_25} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_25} />
                      <AvatarContainerComponent image={AVATAR_26} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_26} />
                      <AvatarContainerComponent image={AVATAR_27} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_27} />
                      <AvatarContainerComponent image={AVATAR_28} onSelect={changeAvatar} isSelected={selectedAvatar === AVATAR_28} />
                    </ul>
                  </div>
                </div>

                <div className="grp-btns">
                  <TransparentButtonComponent onClick={goBack} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

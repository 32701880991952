import { CreateUserInput, UpdateUserInput, User } from '../api/usersSlice'

import { MeDef } from '../reducers/MeSlice'
import { StageEmail } from '../types/AppTypes'
import { v4 as uuid2 } from 'uuid'

export const generatePrimaryKey = (): string => {
  return uuid2()
}

export const convertMeToCreateUserInput = (arg: MeDef): CreateUserInput => {
  return {
    id: arg.id,
    firstName: arg.firstName,
    lastName: arg.lastName,
    email: arg.email,
    type: 'customer',
    companyName: arg.companyName,
    companyWebsite: arg.companyWebsite,
    title: arg.title,
    industry: arg.industry,
    revenue: arg.revenue,
    country: arg.country,
    state: arg.state,
    phone: arg.phone,
    ramps: arg.ramps,
    rampId: generatePrimaryKey(),
    idealCustomerId: generatePrimaryKey(),
    customerAlignmentId: generatePrimaryKey(),
    brandPitchId: generatePrimaryKey()
  }
}

export const processResponse = (arg: User) => {
  return { ...arg, ramps: JSON.parse(arg.ramps as string) }
}

export const createEmailText = (arg: StageEmail[]) => {
  let finalString = ''
  arg.forEach((item: StageEmail) => {
    finalString = finalString + item.email + '\n\n\n\n\n'
  })
  return finalString
}

import { CHECK_BOXES, DROP_DOWNS, RADIO_BUTTONS } from '../constants/QuestionConstants'
import { EmpathyQuestionData, Question } from '../types/AppTypes'
import React, { FC, useEffect, useState } from 'react'
import { addRemove, createOptionsArray, getItemById, pushReplace } from '../utils/ArrayUtils'
import { setBuyerFeeling, setBuyerIsDoing, setBuyerIsSaying, setBuyerIsSeeking, updateIdeal } from '../reducers/IdealCustomerSlice'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { CheckBoxComponent } from '../components/CheckBoxComponent'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RadioButtonSquareComponent } from '../components/RadioButtonSquareComponent'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { SelectionComponent } from '../components/SelectionComponent'
import { insertNames } from '../utils/StringUtils'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const CheckBoxChoiceScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)
  const { updateProgress, nextPage, goBack } = useScreenNavigation()
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const excludeValue = useSelector((state: RootState) => state.IdealCustomer[question.excludePropertyName ?? ''])
  const startValue = useSelector((state: RootState) => state.IdealCustomer[question.propertyName])
  const [answerValue, setAnswerValue] = useState(startValue)
  const [multipleAnswerValue, setMultipleAnswerValue] = useState(startValue)
  const { isTalking, callServer, additionalFunctionCall } = usePillarCommunication()
  const { buyerIsSeeking, buyerIsSaying, buyerIsDoing, buyerIsFeeling } = useSelector((state: RootState) => {
    return {
      buyerIsSeeking: state.IdealCustomer.buyerSeeking,
      buyerIsSaying: state.IdealCustomer.buyerSaying,
      buyerIsDoing: state.IdealCustomer.buyerDoing,
      buyerIsFeeling: state.IdealCustomer.buyerFeeling
    }
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }

  const nextPageClick = async () => {
    let result
    let newArray
    const newItemObj = {
      id: question.id,
      question: insertNames(question.h2Copy, firstName, idealCustomerName),
      answer: question.type === CHECK_BOXES ? multipleAnswerValue : answerValue
    }
    switch (question.propertyName) {
      case 'buyerFeeling':
        newArray = pushReplace(buyerIsFeeling as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerFeeling(newArray))
          nextPage()
        }
        break
      case 'buyerDoing':
        newArray = pushReplace(buyerIsDoing as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerIsDoing(newArray))
          nextPage()
        }
        break
      case 'buyerSaying':
        newArray = pushReplace(buyerIsSaying as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerIsSaying(newArray))
          nextPage()
        }
        break
      case 'buyerSeeking':
        newArray = pushReplace(buyerIsSeeking as EmpathyQuestionData[], newItemObj)
        result = await callServer(newArray, question.propertyName)
        if (result) {
          dispatch(setBuyerIsSeeking(newArray))
          nextPage()
        }
        break
      default:
        const sendValue = question.type === CHECK_BOXES ? multipleAnswerValue : answerValue
        result = await callServer(sendValue, question.propertyName)
        if (result) {
          dispatch(
            updateIdeal({
              [question.propertyName]: sendValue
            })
          )
          nextPage()
        }
        break
    }
  }

  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id)
    setQuestion(newQuestion)
    setAnswerValue(newQuestion.options[newQuestion.defaultAnswerIndex])
    setMultipleAnswerValue(startValue)
    if (newQuestion.functionCall) {
      additionalFunctionCall(newQuestion.functionCall)
    }
  }, [id])

  useEffect(() => {
    setAnswerValue(startValue)
    setMultipleAnswerValue(startValue)
  }, [question.h2Copy])

  const updateMultipleValue = (arg: string) => {
    setMultipleAnswerValue(addRemove(arg, multipleAnswerValue))
  }

  const isSelected = (arg: string) => {
    if (multipleAnswerValue.includes(arg)) {
      return true
    }
    return false
  }

  const renderButtons = () => {
    const filteredOptions = question.options.filter((arg: string) => arg !== excludeValue)
    if (question.type === DROP_DOWNS) {
      return <SelectionComponent value={answerValue} options={createOptionsArray(filteredOptions)} label="" onChange={setAnswerValue} />
    }
    if (question.type === RADIO_BUTTONS) {
      const items = filteredOptions.map((item: string, index: number) => {
        return <RadioButtonSquareComponent label={item} isSelected={answerValue === item} onClick={setAnswerValue} key={question.id + index} />
      })
      return <div className="radio-buttons">{items}</div>
    }
    const items = filteredOptions.map((item: string, index: number) => {
      return <CheckBoxComponent label={item} isSelected={isSelected(item)} onClick={updateMultipleValue} key={question.id + index} />
    })
    return <div className="radio-buttons">{items}</div>
  }
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>{insertNames(question.h2Copy, firstName, idealCustomerName)}</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field fw">{renderButtons()}</div>
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

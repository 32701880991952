import { AD_11, AD_9 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import {
  changeRelationshipQ1,
  changeRelationshipQ2,
  changeRelationshipQ3,
  changeRelationshipQ4,
  changeRelationshipQ5,
  updateQuestion
} from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { TextAreaLeftRightComponent } from '../../../../components/TextAreaLeftRightComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificTenPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { relationshipQuestionOne, relationshipQuestionTwo, relationshipQuestionThree, relationshipQuestionFour, relationshipQuestionFive } = useSelector(
    (state: RootState) => {
      return {
        relationshipQuestionOne: state.AdSpecific.relationshipQuestionOne,
        relationshipQuestionTwo: state.AdSpecific.relationshipQuestionTwo,
        relationshipQuestionThree: state.AdSpecific.relationshipQuestionThree,
        relationshipQuestionFour: state.AdSpecific.relationshipQuestionFour,
        relationshipQuestionFive: state.AdSpecific.relationshipQuestionFive
      }
    }
  )
  const next = () => {
    dispatch(updateQuestion(AD_11))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_9))
  }
  const changeQ1 = (arg: string) => {
    dispatch(changeRelationshipQ1(arg))
  }
  const changeQ2 = (arg: string) => {
    dispatch(changeRelationshipQ2(arg))
  }
  const changeQ3 = (arg: string) => {
    dispatch(changeRelationshipQ3(arg))
  }
  const changeQ4 = (arg: string) => {
    dispatch(changeRelationshipQ4(arg))
  }
  const changeQ5 = (arg: string) => {
    dispatch(changeRelationshipQ5(arg))
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Their Relationship To You</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <div className="form-field duo-input">
                <TextAreaLeftRightComponent
                  value={relationshipQuestionOne}
                  label="What kind of solutions are they looking for?"
                  placeholder="Enter Your Answer"
                  isLeft
                  onChange={changeQ1}
                />
                <TextAreaLeftRightComponent
                  value={relationshipQuestionTwo}
                  label="Have they had a bad experience with competitors products?"
                  placeholder="Enter Your Answer"
                  onChange={changeQ2}
                />
              </div>
              <div className="form-field duo-input">
                <TextAreaLeftRightComponent
                  value={relationshipQuestionThree}
                  label="Have they tried to fix this problem before and failed?"
                  placeholder="Enter Your Answer"
                  isLeft
                  onChange={changeQ3}
                />
                <TextAreaLeftRightComponent
                  value={relationshipQuestionFour}
                  label="How does purchasing your product/service define them?"
                  placeholder="Enter Your Answer"
                  onChange={changeQ4}
                />
              </div>
              <div className="form-field duo-input">
                <TextAreaLeftRightComponent
                  value={relationshipQuestionFive}
                  label="How does purchasing your product/service make them feel?"
                  placeholder="Enter Your Answer"
                  isLeft
                  onChange={changeQ5}
                />
                <div className="input in2" />
              </div>

              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

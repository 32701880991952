import { CreateUserInput } from '../../usersSlice'
import { gql } from 'graphql-request'
export const createUserHelper = (user: CreateUserInput) => {
  return gql`
  mutation createUser {
    createUser(user: {
      id: "${user.id}",
      firstName: "${user.firstName}",
      lastName: "${user.lastName}",
      email: "${user.email}",
      type: "${user.type}",
      companyName: "${user.companyName}",
      companyWebsite: "${user.companyWebsite}",
      title: "${user.title}",
      industry: "${user.industry}",
      revenue: "${user.revenue}",
      country: "${user.country}",
      state: "${user.state}",
      phone: "${user.phone}",
      rampId: "${user.rampId}",
      idealCustomerId: "${user.idealCustomerId}",
      customerAlignmentId: "${user.customerAlignmentId}",
      brandPitchId: "${user.brandPitchId}"
    }) {
      id
      firstName
      lastName
      email
      type
      ramps
    }
  }
`
}

import { gql } from 'graphql-request'
export const buildDoingHelper = () => {
  return gql`
    query completeDoing {
      completeDoing {
        buyerDoingSummary
        buyerFeelingSummary
        buyerSayingSummary
        buyerSeekingSummary
      }
    }
  `
}

import {
  RAMP_LOGO_MED,
  RS_COMMITMENT_ICON,
  RS_DELIGHT_ICON,
  RS_IMPRESSION_ICON,
  RS_LONG_TERM,
  RS_LOVERS_ICON,
  RS_PAIN_POINT_ICON,
  RS_RAPPORT_ICON,
  RS_RECOMMIT_ICON,
  RS_TRIBAL_ICON,
  RS_WIN_BACK
} from '../constants/ImageConstants'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RampReviewBoxComponent } from '../components/RampReviewBoxComponent'
import { RootState } from '../Store'
import { getItemById } from '../utils/ArrayUtils'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useLocation } from 'react-router-dom'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const RampReviewScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const dispatch = useDispatch()
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  useEffect(() => {
    updateProgress(id ?? '')
    const newQuestion = getItemById(questions, id ?? '')
    setQuestion(newQuestion)
  }, [id])
  return (
    <div className="mr rmsteps email_confirmation review_screen_container">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <div className="controls">
            <a href="#">
              {' '}
              <img src="../assets/Edit.svg" alt="" /> Edit
            </a>

            <a href="#">
              <img src="../assets/Collaborators.svg" alt="" /> Invite Collaborators
            </a>
          </div>
        </div>
        <div className="reviw_screen_modals">
          <RampReviewBoxComponent
            title="Stage 1: First Impression"
            titleIcon={RS_IMPRESSION_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 2: Pain Point Segmentation"
            titleIcon={RS_PAIN_POINT_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 3: Tribal Alignment"
            titleIcon={RS_TRIBAL_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 4: Rapport"
            titleIcon={RS_RAPPORT_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 5: Commitment"
            titleIcon={RS_COMMITMENT_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 6: Customer Delight"
            titleIcon={RS_DELIGHT_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 7: Lovers & Haters"
            titleIcon={RS_LOVERS_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 8: Recommitment"
            titleIcon={RS_RECOMMIT_ICON}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 9: Winback"
            titleIcon={RS_WIN_BACK}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
          <RampReviewBoxComponent
            title="Stage 10: Long-Term Love"
            titleIcon={RS_LONG_TERM}
            ideal={2}
            landingPage={2}
            max={2}
            video={1}
            email={3}
            phoneCalls={5}
            pdf={3}
            gift={1}
          />
        </div>
        <ButtonGroupComponent goBack={goBack} nextPageClick={nextPage} label={question.button} />
      </div>
    </div>
  )
}

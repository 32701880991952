import {
  RAMP_LOGO_MED,
  RC_STAGE_1,
  RC_STAGE_10,
  RC_STAGE_2,
  RC_STAGE_3,
  RC_STAGE_4,
  RC_STAGE_5,
  RC_STAGE_6,
  RC_STAGE_7,
  RC_STAGE_8,
  RC_STAGE_9
} from '../constants/ImageConstants'
import React, { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { RAMP_EDITING } from '../constants/RouteConstants'
import { RampCompletionRowComponent } from '../components/RampCompletionRowComponent'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { useDispatch } from 'react-redux'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const RampCompletionScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  useEffect(() => {
    updateProgress(id ?? '')
  }, [id])
  const itemClicked = (arg: string) => {
    // console.log('Clicked ', num)
    // RAMP_EDITING
    navigate(`${RAMP_EDITING}?stageName=${arg}&previousId=${id ?? ''}`)
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <div className="ramp_completion">
              <div className="ramp_completion_lists">
                <ul>
                  <RampCompletionRowComponent
                    title="Stage 1: First Impression"
                    body="Stage One is to deliver a first impression focused on value and delight."
                    itemNumber={1}
                    image={RC_STAGE_1}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 2: Pain Point Segmentation"
                    body="In this Stage, the goal is to identify and segment the prospect's primary pain and for us to offer the solution."
                    itemNumber={2}
                    image={RC_STAGE_2}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 3: Stage 3: Tribal Alignment"
                    body="In this stage we aim to create tribal alignment and further develop rapport with another gift."
                    itemNumber={3}
                    image={RC_STAGE_3}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 4: Rapport"
                    body="Focus is to develop further rapport and to demolish internal objections and perceived purchase risk"
                    itemNumber={4}
                    image={RC_STAGE_4}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 5: Commitment"
                    body="This stage's intent is to ensure that warm leads arrive for the consult so a formal proposal can be made."
                    itemNumber={5}
                    image={RC_STAGE_5}
                    onClick={itemClicked}
                  />
                </ul>
                <ul>
                  <RampCompletionRowComponent
                    title="Stage 6: Customer Delight"
                    body="We want to prevent buyer's remorse to create a great experience increasing odds of additional sales."
                    itemNumber={6}
                    image={RC_STAGE_6}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 7: Lovers & Haters"
                    body="After ensuring clients have a great onboarding, we strive to get referrals and repeat business."
                    itemNumber={7}
                    image={RC_STAGE_7}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 8: Recommitment"
                    body="We want to create the next sale for our past customers to continue along their path of ascension."
                    itemNumber={8}
                    image={RC_STAGE_8}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 9: Winback"
                    body="The Winback stage is designed to win back lost potential revenue from prospects and past customers."
                    itemNumber={9}
                    image={RC_STAGE_9}
                    onClick={itemClicked}
                  />
                  <RampCompletionRowComponent
                    title="Stage 10: Long-Term Love"
                    body="This stage delivers delight, value and offers to convert customers from prospects and raving fans from customers."
                    itemNumber={10}
                    image={RC_STAGE_10}
                    onClick={itemClicked}
                  />
                </ul>
              </div>
              <center>
                <p>Please review your completed RAMP by clicking into any stage or sequence.</p>
              </center>
            </div>
          </center>
        </div>
        <ButtonGroupComponent goBack={goBack} nextPageClick={nextPage} />
      </div>
    </div>
  )
}

import { GptEmail, updateCustomer } from '../reducers/ConsumerProjectSlice'
import { RAMP_LOGO_MED, SMALL_LOGO } from '../constants/ImageConstants'
import React, { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { LoadingIconComponent } from '../components/loadingIcon/LoadingIconComponent'
import { NextButtonComponent } from '../components/buttons/NextButtonComponent'
import { RAMP_COMPLETION } from '../constants/RouteConstants'
import { RampEditingPanelComponent } from '../components/RampEditingPanelComponent'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { useDispatch } from 'react-redux'
import { useRampBuilder } from '../customHooks/useRampBuilder'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const RampEditingScreenComponent: FC = () => {
  const navigate = useNavigate()
  const [emails, setEmails] = useState([])
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const stageName = query.get('stageName')
  const previousId = query.get('previousId')
  const { getEmailsByStage } = useRampBuilder()
  const getEmails = async () => {
    const result = await getEmailsByStage(stageName as string)
    console.log('result for emails', result.emails[stageName ?? ''])
    setEmails(result.emails[stageName ?? ''])
  }
  useEffect(() => {
    console.log('Stage Name', stageName)
    if (stageName && emails.length === 0) {
      getEmails()
    }
  }, [stageName])
  const renderList = () => {
    if (emails.length) {
      const items = emails.map((item: GptEmail, index: number) => {
        return <RampEditingPanelComponent step={index + 1} email={item?.email ?? ''} key={index} />
      })
      return items
    }
    return <LoadingIconComponent />
  }
  const goBack = () => {
    navigate(`${RAMP_COMPLETION}?id=${previousId ?? ''}`)
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <div className="pillar_4_commitment">{renderList()}</div>
          <ButtonGroupComponent goBack={goBack} backOnly />
        </div>
      </div>
    </div>
  )
}

import { AD_1, AD_10, AD_11, AD_2, AD_3, AD_4, AD_5, AD_6, AD_7, AD_8, AD_9 } from '../constants/AdSpecificConstants'
import { AdSpecificCompletionHash, IdealCustomerDemographics } from '../types/AppTypes'

import { createSlice } from '@reduxjs/toolkit'

export interface AdSpecific {
  currentQuestionScreen: string
  progressMeter: AdSpecificCompletionHash
  questionOne: string
  questionTwo: string
  questionThree: string
  questionFour: string
  questionFive: string
  questionSix: string
  questionSeven: string
  questionEight: string
  questionNine: string
  questionTen: string
  questionEleven: string
  questionTwelve: string
  // Starts page 7
  idealCustomerDemographics: IdealCustomerDemographics
  painPointOne: string
  painPointTwo: string
  painPointThree: string
  painPointFour: string
  relationshipQuestionOne: string
  relationshipQuestionTwo: string
  relationshipQuestionThree: string
  relationshipQuestionFour: string
  relationshipQuestionFive: string
  objectionOne: string
  objectionTwo: string
  objectionThree: string
  objectionFour: string
}

const getInitialState = (): AdSpecific => {
  return {
    currentQuestionScreen: AD_1,
    progressMeter: {
      [AD_1]: false,
      [AD_2]: false,
      [AD_3]: false,
      [AD_4]: false,
      [AD_5]: false,
      [AD_6]: false,
      [AD_7]: false,
      [AD_8]: false,
      [AD_9]: false,
      [AD_10]: false,
      [AD_11]: false
    },
    questionOne: '',
    questionTwo: 'USA',
    questionThree: '',
    questionFour: 'Yes',
    questionFive: 'Facebook',
    questionSix: '',
    questionSeven: '',
    questionEight: '',
    questionNine: '$0 - $499k',
    questionTen: '0 - 5',
    questionEleven: '',
    questionTwelve: '',
    idealCustomerDemographics: {
      name: '',
      ageRange: 'Below 17',
      gender: 'Male',
      maritalStatus: 'Single',
      children: '0',
      childrenAgeRange: 'Infant',
      interest: '',
      pets: 'None',
      levelOfEducation: 'Some College',
      ethnicity: 'None',
      annualIncome: '$76-99k'
    },
    painPointOne: '',
    painPointTwo: '',
    painPointThree: '',
    painPointFour: '',
    relationshipQuestionOne: '',
    relationshipQuestionTwo: '',
    relationshipQuestionThree: '',
    relationshipQuestionFour: '',
    relationshipQuestionFive: '',
    objectionOne: '',
    objectionTwo: '',
    objectionThree: '',
    objectionFour: ''
  }
}

export const adSpecificSlice = createSlice({
  name: 'AdSpecific',
  initialState: getInitialState(),
  reducers: {
    setInitialState: state => {
      return getInitialState()
    },
    updateQuestion: (state: AdSpecific, action) => {
      const key = state.currentQuestionScreen as keyof AdSpecificCompletionHash
      state.progressMeter[key] = true
      state.currentQuestionScreen = action.payload
    },
    answerQuestionOne: (state: AdSpecific, action) => {
      state.questionOne = action.payload
    },
    answerQuestionTwo: (state: AdSpecific, action) => {
      state.questionTwo = action.payload
    },
    answerQuestionThree: (state: AdSpecific, action) => {
      state.questionThree = action.payload
    },
    answerQuestionFour: (state: AdSpecific, action) => {
      state.questionFour = action.payload
    },
    answerQuestionFive: (state: AdSpecific, action) => {
      state.questionFive = action.payload
    },
    answerQuestionSix: (state: AdSpecific, action) => {
      state.questionSix = action.payload
    },
    answerQuestionSeven: (state: AdSpecific, action) => {
      state.questionSeven = action.payload
    },
    answerQuestionEight: (state: AdSpecific, action) => {
      state.questionEight = action.payload
    },
    answerQuestionNine: (state: AdSpecific, action) => {
      state.questionNine = action.payload
    },
    answerQuestionTen: (state: AdSpecific, action) => {
      state.questionTen = action.payload
    },
    answerQuestionEleven: (state: AdSpecific, action) => {
      state.questionEleven = action.payload
    },
    answerQuestionTwelve: (state: AdSpecific, action) => {
      state.questionTwelve = action.payload
    },
    changeIdealCustomerAgeRange: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.ageRange = action.payload
    },
    changeIdealCustomerGender: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.gender = action.payload
    },
    changeIdealCustomerMaritalStatus: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.maritalStatus = action.payload
    },
    changeIdealCustomerChildren: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.children = action.payload
    },
    changeIdealCustomerChildrenAgeRange: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.childrenAgeRange = action.payload
    },
    changeIdealCustomerName: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.name = action.payload
    },
    changeIdealCustomerInterest: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.interest = action.payload
    },
    changeIdealCustomerPets: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.pets = action.payload
    },
    changeIdealCustomerEducation: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.levelOfEducation = action.payload
    },
    changeIdealCustomerEthnicity: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.ethnicity = action.payload
    },
    changeIdealCustomerAnnualIncome: (state: AdSpecific, action) => {
      state.idealCustomerDemographics.annualIncome = action.payload
    },
    changePainPointOne: (state: AdSpecific, action) => {
      state.painPointOne = action.payload
    },
    changePainPointTwo: (state: AdSpecific, action) => {
      state.painPointTwo = action.payload
    },
    changePainPointThree: (state: AdSpecific, action) => {
      state.painPointThree = action.payload
    },
    changePainPointFour: (state: AdSpecific, action) => {
      state.painPointFour = action.payload
    },
    changeRelationshipQ1: (state: AdSpecific, action) => {
      state.relationshipQuestionOne = action.payload
    },
    changeRelationshipQ2: (state: AdSpecific, action) => {
      state.relationshipQuestionTwo = action.payload
    },
    changeRelationshipQ3: (state: AdSpecific, action) => {
      state.relationshipQuestionThree = action.payload
    },
    changeRelationshipQ4: (state: AdSpecific, action) => {
      state.relationshipQuestionFour = action.payload
    },
    changeRelationshipQ5: (state: AdSpecific, action) => {
      state.relationshipQuestionFive = action.payload
    },
    changeObjectionOne: (state: AdSpecific, action) => {
      state.objectionOne = action.payload
    },
    changeObjectionTwo: (state: AdSpecific, action) => {
      state.objectionTwo = action.payload
    },
    changeObjectionThree: (state: AdSpecific, action) => {
      state.objectionThree = action.payload
    },
    changeObjectionFour: (state: AdSpecific, action) => {
      state.objectionFour = action.payload
    }
  }
})

export const {
  setInitialState,
  updateQuestion,
  answerQuestionOne,
  answerQuestionTwo,
  answerQuestionThree,
  answerQuestionFour,
  answerQuestionFive,
  answerQuestionSix,
  answerQuestionSeven,
  answerQuestionEight,
  answerQuestionNine,
  answerQuestionTen,
  answerQuestionEleven,
  answerQuestionTwelve,
  changeIdealCustomerAgeRange,
  changeIdealCustomerGender,
  changeIdealCustomerMaritalStatus,
  changeIdealCustomerChildren,
  changeIdealCustomerChildrenAgeRange,
  changeIdealCustomerName,
  changeIdealCustomerInterest,
  changeIdealCustomerPets,
  changeIdealCustomerEducation,
  changeIdealCustomerEthnicity,
  changeIdealCustomerAnnualIncome,
  changePainPointOne,
  changePainPointTwo,
  changePainPointThree,
  changePainPointFour,
  changeRelationshipQ1,
  changeRelationshipQ2,
  changeRelationshipQ3,
  changeRelationshipQ4,
  changeRelationshipQ5,
  changeObjectionOne,
  changeObjectionTwo,
  changeObjectionThree,
  changeObjectionFour
} = adSpecificSlice.actions

export default adSpecificSlice.reducer

import React, { FC } from 'react'

import { DotStyles as styles } from './DotStyles'

type Props = {
  num: number
  isActive: boolean
}
export const DotComponent: FC<Props> = ({ num, isActive }: Props) => {
  return (
    <div style={styles.container}>
      <div style={isActive ? styles.selectedInner : styles.containerInner}>{num}</div>
    </div>
  )
}

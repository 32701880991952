import React, { FC } from 'react'

type Props = {
  email: string
  step: number
}

export const RampEditingPanelComponent: FC<Props> = ({ email, step }: Props) => {
  return (
    <div className="pillar_4">
      <div className="list_number">{step}</div>
      <div className="p4_delay p4_delay_css">{/* */}</div>
      <div className="p4_content">
        <p>{email}</p>
      </div>
    </div>
  )
}

/*
return (
    <div className="pillar_4">
      <div className="list_number">{step}</div>
      <div className="p4_delay p4_delay_css">
        <div className="checkboxOverride">
          <input type="checkbox" name="share" id="instant" value="1" />
          <label htmlFor="instant" />
          <span>Instant</span>
        </div>
        <div className="checkboxOverride delay_checkbox">
          <input type="checkbox" name="share" id="delay" value="1" />
          <label htmlFor="delay" />
          <span>
            Delay
            <select id="delay_dur" name="delay_dur">
              <option value="1">1 day</option>
              <option value="2">2 days</option>
              <option value="3">3 days</option>
              <option value="4">4 days</option>
              <option value="5">5 days</option>
            </select>
          </span>
        </div>
      </div>
      <div className="p4_delay p4_type">
        <ul>
          <li>
            <div className="checkboxOverride">
              <input type="checkbox" name="Email" id="email" value="1" />
              <label htmlFor="email" />
              <span>Email</span>
            </div>
          </li>
          <li>
            <div className="checkboxOverride">
              <input type="checkbox" name="Video" id="video" value="1" />
              <label htmlFor="video" />
              <span>Video</span>
            </div>
          </li>
          <li>
            <div className="checkboxOverride">
              <input type="checkbox" name="Phone" id="phone" value="1" />
              <label htmlFor="phone" />
              <span>Phone</span>
            </div>
          </li>
          <li>
            <div className="checkboxOverride">
              <input type="checkbox" name="pdf" id="pdf" value="1" />
              <label htmlFor="pdf" />
              <span>PDF</span>
            </div>
          </li>
          <li>
            <div className="checkboxOverride">
              <input type="checkbox" name="action" id="action" value="1" />
              <label htmlFor="action" />
              <span>Action</span>
            </div>
          </li>
          <li>
            <div className="checkboxOverride">
              <input type="checkbox" name="Zap" id="zap" value="1" />
              <label htmlFor="zap" />
              <span>Zap</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="p4_content">
        <p>
          [IMMEDIATELY AFTER SALE] Congratulations [First.Name]! You've successfully taken the first step towards successfully overcoming [pain point]. Here is
          how you access everything.
        </p>
      </div>
    </div>
  )
  */

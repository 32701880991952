import '../assets/style.css'

import * as React from 'react'

import { FC } from 'react'

type Props = {
  icon: string
  isActive?: boolean
  label: string
  value: string
}
const NavigationButtonComponent: FC<Props> = ({ value, label, icon, isActive = false }: Props) => {
  return (
    <li className={isActive ? 'active' : ''}>
      {' '}
      <a href={`#${value}`}>
        {' '}
        <img src={icon} alt="" /> {label}
      </a>{' '}
    </li>
  )
}

export { NavigationButtonComponent }

import React, { FC } from 'react'

import { RampDocument } from '../../types/AppTypes'

type Props = {
  item: RampDocument
}
export const DocumentRowComponent: FC<Props> = ({ item }: Props) => {
  return (
    <tr>
      <th scope="row">{item.name}</th>
      <td>{item.score}%</td>
      <td>{item.role}</td>
      <td>{item.status}</td>
      <td>
        <div className="tb-btns">
          <a href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="17.1" height="15.197" viewBox="0 0 17.1 15.197">
              <path
                id="Icon_awesome-edit"
                data-name="Icon awesome-edit"
                d="M11.952,2.474,14.63,5.152a.29.29,0,0,1,0,.41L8.146,12.045l-2.755.306a.577.577,0,0,1-.638-.638l.306-2.755,6.484-6.484A.29.29,0,0,1,11.952,2.474Zm4.809-.68L15.313.345a1.161,1.161,0,0,0-1.639,0L12.623,1.4a.29.29,0,0,0,0,.41L15.3,4.484a.29.29,0,0,0,.41,0l1.051-1.051a1.161,1.161,0,0,0,0-1.639ZM11.4,10.282V13.3H1.9V3.8H8.722a.365.365,0,0,0,.252-.1l1.188-1.188A.356.356,0,0,0,9.91,1.9H1.425A1.425,1.425,0,0,0,0,3.329v10.45A1.425,1.425,0,0,0,1.425,15.2h10.45A1.425,1.425,0,0,0,13.3,13.779V9.094a.357.357,0,0,0-.609-.252L11.5,10.03A.365.365,0,0,0,11.4,10.282Z"
                transform="translate(0 -0.007)"
                fill="#626262"
              />
            </svg>
          </a>
          <a href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.3" height="15.2" viewBox="0 0 13.3 15.2">
              <path
                id="Icon_awesome-trash"
                data-name="Icon awesome-trash"
                d="M12.825.95H9.263L8.983.395A.713.713,0,0,0,8.345,0H4.952a.7.7,0,0,0-.635.395L4.038.95H.475A.475.475,0,0,0,0,1.425v.95a.475.475,0,0,0,.475.475h12.35a.475.475,0,0,0,.475-.475v-.95A.475.475,0,0,0,12.825.95ZM1.579,13.864A1.425,1.425,0,0,0,3,15.2h7.3a1.425,1.425,0,0,0,1.422-1.336L12.35,3.8H.95Z"
                fill="#626262"
              />
            </svg>
          </a>
        </div>
      </td>
    </tr>
  )
}

export const SLIDER = 'Slider'
export const RADIO_BUTTONS = 'Radio Buttons'
export const DROP_DOWNS = 'Drop Downs'
export const CHECK_BOXES = 'Multi Select'
export const FILL_IN_THE_BLANK = 'Fill In the Blank'
export const YES_NO_EXPAND = 'Yes No and Expand'
export const SINGLE_LINE_IN_THE_BLANK = 'Single Line Fill In the Blank'
export const SUMMARY = 'Summary screen'
export const VIDEO = 'Video'
export const CUSTOM = 'Custom Screen'

import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import { answerQuestionFour, answerQuestionOne, answerQuestionThree, answerQuestionTwo, updateQuestion } from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { AD_2 } from '../../../../constants/AdSpecificConstants'
import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { RadioButtonSquareComponent } from '../../../../components/RadioButtonSquareComponent'
import { TextAreaFormField } from '../../../../components/TextAreaFormFieldComponent'

export const AdSpecificOnePanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { answerOne, answerTwo, answerThree, answerFour } = useSelector((state: RootState) => {
    return {
      answerOne: state.AdSpecific.questionOne,
      answerTwo: state.AdSpecific.questionTwo,
      answerThree: state.AdSpecific.questionThree,
      answerFour: state.AdSpecific.questionFour
    }
  })
  const answerQ2 = (arg: string) => {
    dispatch(answerQuestionTwo(arg))
  }
  const answerQ4 = (arg: string) => {
    dispatch(answerQuestionFour(arg))
  }
  const next = () => {
    if (answerOne !== '' && answerThree !== '') {
      dispatch(updateQuestion(AD_2))
    }
  }

  const changeAnswerOne = (arg: string) => {
    dispatch(answerQuestionOne(arg))
  }

  const changeAnswerThree = (arg: string) => {
    dispatch(answerQuestionThree(arg))
  }

  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">AD - Specific Questions</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <TextAreaFormField label="Who are your Main Competitors?" value={answerOne} onChange={changeAnswerOne} />
              <div className="form-field fw">
                <label>What's the Geographic Area you Sell to or Services?</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="USA" isSelected={answerTwo === 'USA'} onClick={answerQ2} />
                  <RadioButtonSquareComponent label="North America" isSelected={answerTwo === 'North America'} onClick={answerQ2} />
                  <RadioButtonSquareComponent label="South America" isSelected={answerTwo === 'South America'} onClick={answerQ2} />
                  <input
                    id="search-others"
                    type="text"
                    placeholder="Search Others"
                    style={{ width: '250px', height: '37px', marginLeft: '5px', marginBottom: '0px' }}
                  />
                </div>
              </div>
              <TextAreaFormField label="What is a client worth to you?" value={answerThree} onChange={changeAnswerThree} />
              <div className="form-field fw">
                <label>Follow up questions, Were they successful?</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="Yes" isSelected={answerFour === 'Yes'} onClick={answerQ4} />
                  <RadioButtonSquareComponent label="No" isSelected={answerFour === 'No'} onClick={answerQ4} />
                </div>
              </div>
              <div className="grp-btns">
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

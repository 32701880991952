import { gql } from 'graphql-request'
export const getRampByIdHelper = (rampId: string) => {
  return gql`
  query getCustomerRampById{
    getCustomerRampById(rampId: "${rampId}") {
      id
      idealCustomerId
      ownerId
      brandPitchId
      customerAssessmentId
      pillarOneProgress
      pillarTwoProgress
      pillarThreeProgress
      pillarFourProgress
      currentScreenIndex
      currentPillar
      currentScreenId
      rampCompleted
    }
  }
  `
}

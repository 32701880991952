import {
  AGE_RANGE,
  AI_IN_PROGRESS,
  BRAND_PITCH_GPT,
  CHOOSE_AVATAR,
  CODE_CONFIRMED_ROUTE,
  COMPANY_PURPOSE,
  COMPANY_PURPOSE_YES_NO,
  CORE_SERVICES,
  CORE_SERVICES_SL,
  CORE_SERVICES_START,
  CUSTOMER_ALIGNMENT,
  CUSTOMER_ALIGNMENT_START,
  CUSTOMER_CHILDREN,
  DASHBOARD_ROUTE,
  DASHBOARD_STEP_BY_STEP,
  EDUCATION,
  EMPATHY_MAP,
  ETHNICITY_ROUTE,
  FINISH_DEMOGRAPHICS,
  HOUSEHOLD_INCOME,
  INIT_EXPLAINER_ROUTE,
  LOGIN_ROUTE,
  MARITAL_STATUS,
  NEXT_STEPS,
  OWN_RENT,
  PARTNER_HIRED,
  PARTNER_RESULTS,
  PETS,
  PILLAR_COMPLETE,
  PILLAR_START_ROUTE,
  PREFERRED_MODE_COMMUNICATION,
  PREFERRED_MODE_COMMUNICATION_START,
  PRIMARY_GENDERS,
  RAMP_COMPLETION,
  RAMP_EDITING,
  RAMP_REVIEW,
  RAMP_SHIPPING,
  REGISTER_ROUTE,
  RESIDENCE,
  SECRET_SAUCE_ONE,
  SECRET_SAUCE_START,
  SECRET_SAUCE_THREE,
  SECRET_SAUCE_TWO,
  SECRET_SAUCE_VIDEO,
  SIGN_UP_ROUTE,
  SPECTRUM,
  STEPS_TO_SUCCESS,
  STEPS_TO_SUCCESS_COMPLETE,
  STEPS_TO_SUCCESS_START,
  UPGRADE_PAY_ROUTE,
  UPGRADE_ROUTE,
  UP_SELL_1,
  UP_SELL_2,
  VERIFY_ROUTE,
  VIDEO_WATCHED,
  WELCOME_ROUTE,
  WHAT_BUYER_IS_DOING,
  WHAT_BUYER_IS_DOING_INTRO,
  WHAT_BUYER_IS_FEELING,
  WHAT_BUYER_IS_FEELING_CB,
  WHAT_BUYER_IS_FEELING_CB_TEXT,
  WHAT_BUYER_IS_FEELING_INTRO,
  WHAT_BUYER_IS_FEELING_YES_NO,
  WHAT_BUYER_IS_SAYING,
  WHAT_BUYER_IS_SAYING_INTRO,
  WHAT_BUYER_IS_SAYING_PAIN,
  WHAT_BUYER_IS_SEEKING,
  WHAT_BUYER_IS_SEEKING_CB,
  WHAT_BUYER_IS_SEEKING_INTRO
} from '../constants/RouteConstants'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { AdminMainScreenComponent } from '../screens/admins/AdminMainScreenComponent'
import { AgeRangeScreenComponent } from '../screens/AgeRangeScreenComponent'
import { BrandGPTScreen } from '../screens/BrandGPTScreen'
import { CheckBoxChoiceScreenComponent } from '../screens/CheckBoxChoiceScreenComponent'
import { CheckBoxChoiceWithTextComponent } from '../screens/CheckBoxChoiceWithTextComponent'
import { ChooseAvatarScreenComponent } from '../screens/ChooseAvatarScreenComponent'
import { CongratulationsGenericScreen } from '../screens/CongratulationsGenericScreen'
import { CustomerAlignmentScreenComponent } from '../screens/CustomerAlignmentScreenComponent'
import { CustomerChildrenScreenComponent } from '../screens/CustomerChildrenScreenComponent'
import { EightStepProcessScreenComponent } from '../screens/admins/EightStepProcessScreenComponent'
import { EmailConfirmationScreenComponent } from '../screens/EmailConfirmationScreenComponent'
import { EmailGenerationGPTScreenComponent } from '../screens/EmailGenerationGPTScreenComponent'
import { EmpathyMapScreenComponent } from '../screens/EmpathyMapScreenComponent'
import { HomeScreenComponent } from '../screens/HomeScreenComponent'
import { LoginScreenComponent } from '../screens/LoginScreenComponent'
import { PainPointScreenComponent } from '../screens/PainPointScreenComponent'
import { PartnerHiredScreenComponent } from '../screens/PartnerHiredScreenComponent'
import { PartnerResultScreenComponent } from '../screens/PartnerResultScreenComponent'
import { PetsScreenComponent } from '../screens/PetsScreenComponent'
import { PillarCompletionScreenComponent } from '../screens/PillarCompletetionScreenComponent'
import { PillarFourNextStepsScreenComponent } from '../screens/PillarFourNextStepsScreenComponent'
import { PrimaryGenderScreenComponent } from '../screens/PrimaryGenderScreenComponent'
import { RampCompletionScreenComponent } from '../screens/RampCompletionScreenComponent'
import { RampEditingScreenComponent } from '../screens/RampEditingScreenComponent'
import { RampReviewScreenComponent } from '../screens/RampReviewScreenComponent'
import { RampShippingScreenComponent } from '../screens/RampShippingScreenComponent'
import React from 'react'
import { RegistrationScreenComponent } from '../screens/RegistrationScreenComponent'
import { ResidenceChoiceScreenComponent } from '../screens/ResidenceChoiceScreenComponent'
import { SecretSauceScreenV2Component } from '../screens/SecretSauceScreenV2Component'
import { SignUpScreenComponent } from '../screens/SignUpScreenComponent'
import { SingleLineGenericScreen } from '../screens/SingleLineGenericScreen'
import { SpectrumOfFormalityScreenComponent } from '../screens/SpectrumOfFormalityScreenComponent'
import { TextAreaGenericScreenComponent } from '../screens/TextAreaGenericScreenComponent'
import { UpSellScreenComponentOne } from '../screens/UpSellScreenComponentOne'
import { UpSellScreenComponentTwo } from '../screens/UpSellScreenComponentTwo'
import { UpgradeScreenComponent } from '../screens/UpgradeScreenComponent'
import { UpgradeStripeScreenComponent } from '../screens/UpgradeStripeScreenComponent'
import { VideoGenericScreen } from '../screens/VideoGenericScreen'
import { YesNoWithDetailGenericScreenComponent } from '../screens/YesNoWithDetailGenericScreenComponent'

export const NavigationRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path={WELCOME_ROUTE} element={<HomeScreenComponent />} />
        <Route path={SIGN_UP_ROUTE} element={<SignUpScreenComponent />} />
        <Route path={VERIFY_ROUTE} element={<EmailConfirmationScreenComponent />} />
        <Route path={REGISTER_ROUTE} element={<RegistrationScreenComponent />} />
        <Route path={LOGIN_ROUTE} element={<LoginScreenComponent />} />
        <Route path={CODE_CONFIRMED_ROUTE} element={<CongratulationsGenericScreen />} />
        <Route path={INIT_EXPLAINER_ROUTE} element={<VideoGenericScreen />} />
        <Route path={PILLAR_START_ROUTE} element={<VideoGenericScreen />} />
        <Route path={AGE_RANGE} element={<AgeRangeScreenComponent />} />
        <Route path={MARITAL_STATUS} element={<CheckBoxChoiceScreenComponent />} />
        <Route path={CUSTOMER_CHILDREN} element={<CustomerChildrenScreenComponent />} />
        <Route path={PETS} element={<PetsScreenComponent />} />
        <Route path={EDUCATION} element={<CheckBoxChoiceScreenComponent />} />
        <Route path={ETHNICITY_ROUTE} element={<CheckBoxChoiceScreenComponent />} />
        <Route path={HOUSEHOLD_INCOME} element={<CheckBoxChoiceScreenComponent />} />
        <Route path={OWN_RENT} element={<CheckBoxChoiceScreenComponent />} />
        <Route path={RESIDENCE} element={<ResidenceChoiceScreenComponent />} />
        <Route path={PRIMARY_GENDERS} element={<PrimaryGenderScreenComponent />} />
        <Route path={CHOOSE_AVATAR} element={<ChooseAvatarScreenComponent />} />
        <Route path={FINISH_DEMOGRAPHICS} element={<CongratulationsGenericScreen />} />

        <Route path={WHAT_BUYER_IS_SEEKING_INTRO} element={<CongratulationsGenericScreen />} />
        <Route path={WHAT_BUYER_IS_SEEKING} element={<TextAreaGenericScreenComponent />} />
        <Route path={WHAT_BUYER_IS_SEEKING_CB} element={<CheckBoxChoiceScreenComponent />} />

        <Route path={WHAT_BUYER_IS_SAYING_INTRO} element={<CongratulationsGenericScreen />} />
        <Route path={WHAT_BUYER_IS_SAYING} element={<SingleLineGenericScreen />} />
        <Route path={WHAT_BUYER_IS_SAYING_PAIN} element={<PainPointScreenComponent />} />
        <Route path={WHAT_BUYER_IS_DOING_INTRO} element={<CongratulationsGenericScreen />} />
        <Route path={WHAT_BUYER_IS_DOING} element={<CheckBoxChoiceScreenComponent />} />

        <Route path={WHAT_BUYER_IS_FEELING_INTRO} element={<CongratulationsGenericScreen />} />
        <Route path={WHAT_BUYER_IS_FEELING} element={<TextAreaGenericScreenComponent />} />
        <Route path={WHAT_BUYER_IS_FEELING_CB_TEXT} element={<CheckBoxChoiceWithTextComponent />} />
        <Route path={WHAT_BUYER_IS_FEELING_CB} element={<CheckBoxChoiceScreenComponent />} />
        <Route path={WHAT_BUYER_IS_FEELING_YES_NO} element={<YesNoWithDetailGenericScreenComponent />} />

        <Route path={PILLAR_COMPLETE} element={<PillarCompletionScreenComponent />} />

        <Route path={EMPATHY_MAP} element={<EmpathyMapScreenComponent />} />
        <Route path={SPECTRUM} element={<SpectrumOfFormalityScreenComponent />} />
        <Route path={COMPANY_PURPOSE} element={<TextAreaGenericScreenComponent />} />
        <Route path={COMPANY_PURPOSE_YES_NO} element={<YesNoWithDetailGenericScreenComponent />} />
        <Route path={CORE_SERVICES_START} element={<CongratulationsGenericScreen />} />
        <Route path={CORE_SERVICES_SL} element={<SingleLineGenericScreen />} />
        <Route path={CORE_SERVICES} element={<YesNoWithDetailGenericScreenComponent />} />
        <Route path={PREFERRED_MODE_COMMUNICATION_START} element={<CongratulationsGenericScreen />} />
        <Route path={PREFERRED_MODE_COMMUNICATION} element={<CheckBoxChoiceScreenComponent />} />
        <Route path={STEPS_TO_SUCCESS_START} element={<CongratulationsGenericScreen />} />
        <Route path={STEPS_TO_SUCCESS} element={<SingleLineGenericScreen />} />
        <Route path={STEPS_TO_SUCCESS_COMPLETE} element={<CongratulationsGenericScreen />} />
        <Route path={CUSTOMER_ALIGNMENT_START} element={<CongratulationsGenericScreen />} />
        <Route path={CUSTOMER_ALIGNMENT} element={<CustomerAlignmentScreenComponent />} />

        <Route path={SECRET_SAUCE_START} element={<CongratulationsGenericScreen />} />
        <Route path={SECRET_SAUCE_VIDEO} element={<VideoGenericScreen />} />
        <Route path={SECRET_SAUCE_ONE} element={<SecretSauceScreenV2Component />} />
        <Route path={SECRET_SAUCE_TWO} element={<SecretSauceScreenV2Component />} />
        <Route path={SECRET_SAUCE_THREE} element={<SecretSauceScreenV2Component />} />

        <Route path={BRAND_PITCH_GPT} element={<BrandGPTScreen />} />
        <Route path={UPGRADE_ROUTE} element={<UpgradeScreenComponent />} />
        <Route path={UPGRADE_PAY_ROUTE} element={<UpgradeStripeScreenComponent />} />
        <Route path={AI_IN_PROGRESS} element={<EmailGenerationGPTScreenComponent />} />
        <Route path={VIDEO_WATCHED} element={<CongratulationsGenericScreen />} />

        <Route path={RAMP_COMPLETION} element={<RampCompletionScreenComponent />} />
        <Route path={RAMP_EDITING} element={<RampEditingScreenComponent />} />
        <Route path={RAMP_REVIEW} element={<RampReviewScreenComponent />} />
        <Route path={RAMP_SHIPPING} element={<RampShippingScreenComponent />} />
        <Route path={UP_SELL_1} element={<UpSellScreenComponentOne />} />
        <Route path={UP_SELL_2} element={<UpSellScreenComponentTwo />} />
        <Route path={PARTNER_RESULTS} element={<PartnerResultScreenComponent />} />
        <Route path={PARTNER_HIRED} element={<PartnerHiredScreenComponent />} />
        <Route path={NEXT_STEPS} element={<PillarFourNextStepsScreenComponent />} />

        <Route path={DASHBOARD_ROUTE} element={<AdminMainScreenComponent />} />
        <Route path={DASHBOARD_STEP_BY_STEP} element={<EightStepProcessScreenComponent />} />
      </Routes>
    </Router>
  )
}

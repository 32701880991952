import { AuthenticationDetails } from 'amazon-cognito-identity-js'
import getCognitoUser from './getCognitoUser'

const loginCognitoUser = async (username: string, password: string): Promise<any> => {
  return await new Promise(function(resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    const currentUser = getCognitoUser(username)
    let response
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function(res: any) {
        response = res
        console.log('RESULT Resolve on login', res)
        resolve(response)
      },
      onFailure: function(err: any) {
        response = err
        console.log('ERROR from signInWithEmail:', err)
        resolve(err)
      }
    })
  }).catch(err => {
    throw err
  })
}

export default loginCognitoUser

import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID ?? ''
}
const userPool = new CognitoUserPool(poolData)

const signUpUserWithEmail = async (username: string, email: string, password: string): Promise<any> => {
  return await new Promise(function(resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email
      })
    ]

    userPool.signUp(username, password, attributeList, [], function(err, res) {
      if (err) {
        const error = JSON.parse(JSON.stringify(err))
        error.message = err.message
        reject(error)
      } else {
        console.log('res', res)
        resolve(res)
      }
    })
  }).catch(err => {
    console.log('throwError', err)
    return err
  })
}

export default signUpUserWithEmail

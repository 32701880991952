import { AD_1, AD_3 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import { answerQuestionFive, answerQuestionSix, updateQuestion } from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { RadioButtonSquareComponent } from '../../../../components/RadioButtonSquareComponent'
import { TextAreaFormField } from '../../../../components/TextAreaFormFieldComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificTwoPanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { answerFive, answerSix } = useSelector((state: RootState) => {
    return {
      answerFive: state.AdSpecific.questionFive,
      answerSix: state.AdSpecific.questionSix
    }
  })
  const next = () => {
    dispatch(updateQuestion(AD_3))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_1))
  }
  const changeAnswerSix = (arg: string) => {
    dispatch(answerQuestionSix(arg))
  }

  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">AD - Specific Questions</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <div className="form-field fw">
                <label>Follow up questions, what Platforms?</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="Facebook" isSelected={answerFive === 'Facebook'} onClick={answerQuestionFive} />
                  <RadioButtonSquareComponent label="Instagram" isSelected={answerFive === 'Instagram'} onClick={answerQuestionFive} />
                  <RadioButtonSquareComponent label="Google" isSelected={answerFive === 'Google'} onClick={answerQuestionFive} />
                  <RadioButtonSquareComponent label="YouTube" isSelected={answerFive === 'YouTube'} onClick={answerQuestionFive} />
                  <RadioButtonSquareComponent label="LinkedIn" isSelected={answerFive === 'LinkedIn'} onClick={answerQuestionFive} />
                  <RadioButtonSquareComponent label="Twitter" isSelected={answerFive === 'Twitter'} onClick={answerQuestionFive} />
                  <RadioButtonSquareComponent label="Others" isSelected={answerFive === 'Others'} onClick={answerQuestionFive} />
                  <input
                    id="search-others"
                    type="text"
                    placeholder="Search Others"
                    style={{ width: '250px', height: '37px', marginLeft: '5px', marginBottom: '0px' }}
                  />
                </div>
              </div>
              <TextAreaFormField label="What is your Customer lifetime value?" value={answerSix} onChange={changeAnswerSix} />
              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

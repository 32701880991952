import '../assets/style.css'

import * as React from 'react'

import { FC } from 'react'

type Props = {
  name: string
  icon: string
}
const IdealCustomerPersonComponent: FC<Props> = ({ name, icon }: Props) => {
  return (
    <div className="row">
      <img src={icon} alt="" style={{ width: 75, height: 75, marginRight: -15 }} />
      <div className="col-md-2">{name}</div>
    </div>
  )
}

export { IdealCustomerPersonComponent }

import { gql } from 'graphql-request'
export const updateModeOfCommunicationTwo = (arg: string) => {
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          modeOfCommunicationTwo: "${arg}",
        }) {
          id
        }
      }
    `
}

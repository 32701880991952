import { gql } from 'graphql-request'
export const getBrandPitchSummary = () => {
  return gql`
    query getBrandPitchByOwner {
      getBrandPitchByOwner {
        id
        idealCustomerId
        ownerId
        whoWeAre
        weServe
        whoNeeds
        ourSolution
        versus
        shortNarrative
        pitch
      }
    }
  `
}

import { gql } from 'graphql-request'
export const updateAgeRange = (start: number, end: number, ageRange: string) => {
  return gql`
      mutation updateAgeRange {
        updateAgeRange (idealCustomer: {
          ageRange: "${ageRange}",
          ageRangeStart: ${start},
          ageRangeEnd: ${end},
        }) {
          id
        }
      }
    `
}

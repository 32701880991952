import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { GENDER } from '../constants/DropDownConstants'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { SelectionComponent } from '../components/SelectionComponent'
import { TextInputFullWidthComponent } from '../components/TextInputFullWidthComponent'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const PrimaryGenderScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const { ig, nm } = useSelector((state: RootState) => {
    return {
      ig: state.IdealCustomer.gender,
      nm: state.IdealCustomer.customerName
    }
  })
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const { isTalking, callServer } = usePillarCommunication()

  const [gender, setGender] = useState(ig)
  const [customerName, setCustomerName] = useState(nm)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }

  const nextPageClick = async () => {
    // TODO: FIgure out how to throw an error for not filled out
    const result = await callServer(
      {
        customerName,
        gender
      },
      'name'
    )
    if (result) {
      dispatch(
        updateIdeal({
          customerName,
          gender
        })
      )
      nextPage()
    }
  }
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>Let's give your ideal customer a name and gender.</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <TextInputFullWidthComponent label="Name:" onChange={setCustomerName} value={customerName} />
                <div className="form-field fw">
                  <SelectionComponent value={gender} options={GENDER} label="Gender:" onChange={setGender} />
                </div>
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import { gql } from 'graphql-request'
export const buildShortNarrativeHelper = (idealCustomerId: string) => {
  return gql`
    query completeShortNarrative {
      completeShortNarrative (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

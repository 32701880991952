import { gql } from 'graphql-request'
export const objectionThreeHelper = (objectionOne: string, solution: string) => {
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          objectionThree: "${objectionOne}",
          objectionThreeOvercome: "${solution}",
        }) {
          id
        }
      }
    `
}

import { gql } from 'graphql-request'
export const getRampEmailStatusHelper = (rampId: string) => {
  return gql`
  query getCustomerRampEmailStatusId{
    getCustomerRampEmailStatusId(rampId: "${rampId}") {
      emailsGenerated
    }
  }
  `
}

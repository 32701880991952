import React, { FC, useEffect, useState } from 'react'

type Props = {
  isMain?: boolean
  title: string
  inProgress?: boolean
  isComplete?: boolean
  number?: number
}
export const StepNavigationItemComponent: FC<Props> = ({ inProgress = false, isComplete = false, isMain = false, title, number }: Props) => {
  const [cln, setCln] = useState('v-progress-item')

  useEffect(() => {
    if (isComplete) {
      setCln('v-progress-item completed')
      return
    }
    if (inProgress) {
      setCln('v-progress-item inprogress')
      return
    }
    setCln('v-progress-item')
  }, [inProgress, isComplete])

  if (isMain) {
    if (isComplete) {
      return (
        <li className="main-progress">
          <span className="v-progress-number completed">{number}</span> {title}
        </li>
      )
    }
    return (
      <li className="main-progress">
        <span className="v-progress-number">{number}</span> {title}
      </li>
    )
  }
  return <li className={cln}>{title}</li>
}

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { FC, useEffect, useState } from 'react'

import { SingleButtonSmartComponent } from '../buttons/SingleButtonSmartComponent'
import { useLazyCompleteTransactionQuery } from '../../api/stripeSlice'
import { useScreenNavigation } from '../../customHooks/useScreenNavigation'

type Props = {
  clientSecret: string
  transactionId: string
}
export const UpgradeCheckoutFormComponent: FC<Props> = ({ clientSecret, transactionId }: Props) => {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [paymentSubmitted, setPaymentSubmitted] = useState(false)
  const [upgradeTransaction] = useLazyCompleteTransactionQuery()
  const { nextPage } = useScreenNavigation()

  useEffect(() => {
    if (!paymentSubmitted) return
    if (!stripe) {
      return
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log('PaymentIntent', paymentIntent)
      if (!paymentIntent) return
      switch (paymentIntent.status) {
        case 'succeeded':
          // setMessage('Payment succeeded!')
          setPaymentSubmitted(false)
          transactionSuccess(paymentIntent.id)
          console.log('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          console.log('Your payment is processing.')
          break
        case 'requires_payment_method':
          // setMessage('Your payment was not successful, please try again.')
          // console.log('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          console.log('Your payment was not successful, please try again.')
          break
      }
      setIsProcessing(false)
    })
  }, [stripe, isProcessing, paymentSubmitted])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    payClick()
  }
  const transactionSuccess = async (id: string) => {
    try {
      const result = await upgradeTransaction(id).unwrap()
      console.log('result', result)
      nextPage()
      setIsProcessing(false)
    } catch (e) {
      console.log('Error Processing', e)
    }
  }

  const payClick = async () => {
    if (!stripe || !elements) {
      return
    }
    setIsProcessing(true)
    setPaymentSubmitted(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page upgradePay
        // return_url: `${window.location.origin}/gptInProgress?id=p4s2&transactionId=${transactionId}`
        return_url: `${window.location.origin}/upgradePay`
      },
      redirect: 'if_required'
    })

    if (error) {
      console.log('we have an error')
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message ?? 'error')
      } else {
        setMessage('An unexpected error occurred.')
      }
      setIsProcessing(false)
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <SingleButtonSmartComponent nextPageClick={payClick} processing={paymentSubmitted} />
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

import { EmpathyQuestionData } from '../../types/AppTypes'
import { gql } from 'graphql-request'
export const updateBuyerFeeling = (arg: EmpathyQuestionData[]) => {
  const newArray: EmpathyQuestionData[] = [...arg]
  const json2 = JSON.stringify(JSON.stringify(newArray))

  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          buyerFeeling: ${json2},
        }) {
          id
        }
      }
    `
}

import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { CheckBoxComponent } from '../components/CheckBoxComponent'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RadioButtonSquareComponent } from '../components/RadioButtonSquareComponent'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { addRemove } from '../utils/ArrayUtils'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const PetsScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const { hp, p } = useSelector((state: RootState) => {
    return {
      hp: state.IdealCustomer.hasPets,
      p: state.IdealCustomer.pets
    }
  })
  const { isTalking, callServer } = usePillarCommunication()
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const [hasPets, setHasPets] = useState(hp)
  const [pets, setPets] = useState(p)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }
  const nextPageClick = async () => {
    const result = await callServer(
      {
        hasPets,
        pets
      },
      'hasPets'
    )
    if (result) {
      dispatch(
        updateIdeal({
          hasPets,
          pets
        })
      )
      nextPage()
    }
  }

  const isSelected = (arg: string) => {
    if (pets.includes(arg)) {
      return true
    }
    return false
  }

  const selectPet = (arg: string) => {
    setPets(addRemove(arg, pets as string[]))
  }

  const renderPetChoices = () => {
    if (hasPets === 'Yes') {
      return (
        <div className="form-field fw">
          <div className="radio-buttons">
            <CheckBoxComponent label="Dogs" isSelected={isSelected('Dogs')} onClick={selectPet} group="pets" />
            <CheckBoxComponent label="Cats" isSelected={isSelected('Cats')} onClick={selectPet} group="pets" />
            <CheckBoxComponent label="Reptiles" isSelected={isSelected('Reptiles')} onClick={selectPet} group="pets" />
            <CheckBoxComponent label="Fish" isSelected={isSelected('Fish')} onClick={selectPet} group="pets" />
            <CheckBoxComponent label="Birds" isSelected={isSelected('Birds')} onClick={selectPet} group="pets" />
            <CheckBoxComponent label="Livestock" isSelected={isSelected('Livestock')} onClick={selectPet} group="pets" />
            <CheckBoxComponent label="Horses" isSelected={isSelected('Horses')} onClick={selectPet} group="pets" />
            <CheckBoxComponent label="Exotic" isSelected={isSelected('Exotic')} onClick={selectPet} group="pets" />
          </div>
        </div>
      )
    }
    return null
  }
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>Do they have pets? If so, what type?</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className="form-field fw">
                  <div className="radio-buttons">
                    <RadioButtonSquareComponent label="Yes" isSelected={hasPets === 'Yes'} onClick={setHasPets} />
                    <RadioButtonSquareComponent label="No" isSelected={hasPets === 'No'} onClick={setHasPets} />
                    <RadioButtonSquareComponent label="N/A" isSelected={hasPets === 'N/A'} onClick={setHasPets} />
                  </div>
                </div>
                {renderPetChoices()}
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect, useRef } from 'react'
// import {  } from 'react-gsap'
import gsap, { TweenMax } from 'gsap'

import Draggable from 'gsap/Draggable'

gsap.registerPlugin(Draggable)
export const DashboardSliderComponent: FC = () => {
  const knob = useRef<HTMLInputElement>(null)
  const volumeBar = useRef<HTMLInputElement>(null)
  const range = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const updateRange = () => {
      if (!knob.current || !volumeBar.current) return
      const volRect = volumeBar.current.getBoundingClientRect()
      const knobRect = knob.current.getBoundingClientRect()
      TweenMax.set(range.current, { width: knobRect.left + knobRect.width - volRect.left })
    }

    const updatePosition = function(this: Draggable) {
      if (!knob.current || !volumeBar.current || !range.current) return
      const knobRect = knob.current.getBoundingClientRect()
      const volRect = volumeBar.current.getBoundingClientRect()
      TweenMax.set(knob.current, { x: this.pointerX - volRect.left - knobRect.width / 2 })
      TweenMax.set(range.current, { width: knobRect.left + knobRect.width - volRect.left })
    }

    const draggableConfig = {
      type: 'x' as const, // Change this line
      edgeResistance: 1,
      lockAxis: true,
      cursor: 'pointer',
      onDrag: updateRange,
      onPress: updatePosition
    }

    if (!knob.current || !volumeBar.current) return
    Draggable.create(knob.current, { ...draggableConfig, trigger: knob.current, bounds: volumeBar.current })
    // Repeat this for each knob/volumeBar combination
  }, [])

  return (
    <div ref={volumeBar} id="volume-bar1" className="volume-bar">
      <div ref={knob} id="knob1" className="knob2" />
      <div ref={range} id="range1" className="range2" />
    </div>
  )
}

import React, { FC } from 'react'

interface Props {
  name: string
  copy: string
}
export const SauceItem: FC<Props> = ({ name, copy }: Props) => {
  return (
    <tr>
      <td className="fontSize-12">
        <strong className="bbl-red">{`${name}:`}</strong>
      </td>
      <td>
        <p>{copy}</p>
      </td>
    </tr>
  )
}

import {
  AGE_RANGE,
  AI_IN_PROGRESS,
  BRAND_PITCH_GPT,
  CHOOSE_AVATAR,
  CODE_CONFIRMED_ROUTE,
  COMPANY_PURPOSE,
  COMPANY_PURPOSE_YES_NO,
  CORE_SERVICES,
  CORE_SERVICES_SL,
  CORE_SERVICES_START,
  CUSTOMER_ALIGNMENT,
  CUSTOMER_ALIGNMENT_START,
  CUSTOMER_CHILDREN,
  EDUCATION,
  EMPATHY_MAP,
  ETHNICITY_ROUTE,
  FINISH_DEMOGRAPHICS,
  HOUSEHOLD_INCOME,
  INIT_EXPLAINER_ROUTE,
  MARITAL_STATUS,
  NEXT_STEPS,
  OWN_RENT,
  PARTNER_HIRED,
  PARTNER_RESULTS,
  PETS,
  PILLAR_COMPLETE,
  PILLAR_START_ROUTE,
  PREFERRED_MODE_COMMUNICATION,
  PREFERRED_MODE_COMMUNICATION_START,
  PRIMARY_GENDERS,
  RAMP_COMPLETION,
  RAMP_EDITING,
  RAMP_REVIEW,
  RAMP_SHIPPING,
  RESIDENCE,
  SECRET_SAUCE_ONE,
  SECRET_SAUCE_START,
  SECRET_SAUCE_THREE,
  SECRET_SAUCE_TWO,
  SECRET_SAUCE_VIDEO,
  SPECTRUM,
  STEPS_TO_SUCCESS,
  STEPS_TO_SUCCESS_COMPLETE,
  STEPS_TO_SUCCESS_START,
  UPGRADE_ROUTE,
  UP_SELL_1,
  UP_SELL_2,
  VIDEO_WATCHED,
  WHAT_BUYER_IS_DOING,
  WHAT_BUYER_IS_DOING_INTRO,
  WHAT_BUYER_IS_FEELING,
  WHAT_BUYER_IS_FEELING_CB,
  WHAT_BUYER_IS_FEELING_CB_TEXT,
  WHAT_BUYER_IS_FEELING_INTRO,
  WHAT_BUYER_IS_FEELING_YES_NO,
  WHAT_BUYER_IS_SAYING,
  WHAT_BUYER_IS_SAYING_INTRO,
  WHAT_BUYER_IS_SAYING_PAIN,
  WHAT_BUYER_IS_SEEKING,
  WHAT_BUYER_IS_SEEKING_CB,
  WHAT_BUYER_IS_SEEKING_INTRO
} from '../constants/RouteConstants'
import {
  ASCENSION_PLAN,
  COLLABORATORS,
  CREATE_BADGE,
  EXPLAINER_ONE,
  PARTNER_HIRED_ICON,
  PILLAR_BADGE_1,
  PILLAR_COMPLETION_FOUR,
  PILLAR_COMPLETION_ONE,
  PILLAR_COMPLETION_THREE,
  PILLAR_COMPLETION_TWO,
  SECRET_SAUCE_ICON,
  STEPS_0,
  STEPS_1,
  STEPS_2,
  STEPS_3,
  SUCCESS,
  UPGRADE,
  UP_SELL
} from '../constants/ImageConstants'
import {
  CHECK_BOXES,
  CUSTOM,
  DROP_DOWNS,
  FILL_IN_THE_BLANK,
  RADIO_BUTTONS,
  SINGLE_LINE_IN_THE_BLANK,
  SLIDER,
  SUMMARY,
  VIDEO,
  YES_NO_EXPAND
} from '../constants/QuestionConstants'

import { DASHBOARD } from '../constants/SceneKeys'
import { Question } from '../types/AppTypes'

export const questions: Question[] = [
  {
    id: 'p0',
    route: CODE_CONFIRMED_ROUTE,
    h2Copy: 'Success! Now the real fun begins, $FirstName',
    orderInPillar: -1,
    type: SUMMARY,
    pillar: 0,
    options: [],
    button: 'SIT BACK, RELAX, AND WATCH THE VIDEO ON THE NEXT PAGE',
    defaultAnswerIndex: 0,
    icon: CREATE_BADGE,
    propertyName: ''
  },
  {
    id: 'p0s2',
    route: INIT_EXPLAINER_ROUTE,
    h2Copy: "It's time to learn how the RAMP will revolutionize your marketing.",
    orderInPillar: -1,
    type: VIDEO,
    pillar: 0,
    options: [],
    button: "LET'S BEGIN PILLAR ONE",
    defaultAnswerIndex: 0,
    icon: EXPLAINER_ONE,
    propertyName: '',
    videoLink: 'https://vimeo.com/760683038/6df57faf3a?share=copy'
  },
  {
    id: 'p1s0',
    route: PILLAR_START_ROUTE,
    h2Copy: 'Marketing RAMP® Pillar One: People You Love',
    orderInPillar: 0,
    type: VIDEO,
    pillar: 1,
    options: [],
    button: 'Skip',
    icon: PILLAR_BADGE_1,
    defaultAnswerIndex: 0,
    propertyName: '',
    videoLink: 'https://vimeo.com/763887793/29e353645d?share=copy'
  },
  {
    id: 'p1s1',
    route: AGE_RANGE,
    h2Copy: 'Please select the age range of your Ideal Customer',
    orderInPillar: 0,
    type: SLIDER,
    pillar: 1,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'ageRangeStart'
  },
  {
    id: 'p1s2',
    route: MARITAL_STATUS,
    h2Copy: 'Select the marital status of your Ideal Customer?',
    orderInPillar: 3,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: ['Single', 'Married', 'Divorced', 'Widowed', 'N/A'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'maritalStatus'
  },
  {
    id: 'p1s3',
    route: CUSTOMER_CHILDREN,
    h2Copy: 'Does your Ideal Customer have children?',
    orderInPillar: 4,
    type: CUSTOM,
    pillar: 1,
    options: ['Yes', 'No'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'customersChildren'
  },
  {
    id: 'p1s7',
    route: EDUCATION,
    h2Copy: 'What is their level of education?',
    orderInPillar: 7,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: ['Some HS', 'HS Grad', 'Trade School', 'Some College', 'College Grad', 'MBA', 'PHD', 'N/A'],
    button: '',
    defaultAnswerIndex: 4,
    propertyName: 'education'
  },
  {
    id: 'p1s8',
    route: ETHNICITY_ROUTE,
    h2Copy: 'Is there a specific ethnicity of your Ideal Customer?',
    orderInPillar: 8,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: ['N/A', 'White', 'Hispanic', 'Mexican', 'African American', 'Pacific Islander', 'Native American', 'Asian'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'ethnicity'
  },
  {
    id: 'p1s9',
    route: HOUSEHOLD_INCOME,
    h2Copy: 'What is their average annual household income?',
    orderInPillar: 9,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: ['$0-$48K', '$49K-$98K', '$99K-$148K', '$149K-$249K', '$250K-$499K', '$500K+'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'houseHoldIncome'
  },
  {
    id: 'p1s10',
    route: OWN_RENT,
    h2Copy: 'Do they own or rent their home?',
    orderInPillar: 10,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: ['Own', 'Rent', 'N/A'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'ownRent'
  },
  {
    id: 'p1s11',
    route: RESIDENCE,
    h2Copy: 'Where do they live?',
    orderInPillar: 11,
    type: CUSTOM,
    pillar: 1,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'residence'
  },
  {
    id: 'p1s12',
    route: PRIMARY_GENDERS,
    h2Copy: 'You know what we need now? We need a name for your Ideal Customer. Please type in a name below.',
    orderInPillar: 12,
    type: CUSTOM,
    pillar: 1,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'customerName'
  },
  {
    id: 'p1s13',
    route: CHOOSE_AVATAR,
    h2Copy: "Fantastic name! Let's now choose an icon for your Ideal Customer.",
    orderInPillar: 13,
    type: CUSTOM,
    pillar: 1,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'avatar'
  },
  {
    id: 'p1s14',
    route: FINISH_DEMOGRAPHICS,
    h2Copy: 'Nice work, $FirstName! Your Ideal Customer demographics is complete! Now, we move to what your ideal customers are seeking.',
    orderInPillar: 14,
    type: SUMMARY,
    pillar: 1,
    options: [],
    defaultAnswerIndex: 0,
    button: 'Next',
    propertyName: '',
    functionCall: 'buildWhoWeServe'
  },
  {
    id: 'p1s15',
    route: WHAT_BUYER_IS_SEEKING_INTRO,
    h2Copy:
      "We are now going to get into the psychology of buying for your Ideal Customer named (Ideal.Customer.Name). Understanding this will help us craft the best messaging and offers. Let's get started!",
    orderInPillar: 14,
    type: CUSTOM,
    pillar: 1,
    options: [],
    defaultAnswerIndex: 0,
    button: "Let's do this!",
    propertyName: ''
  },
  {
    id: 'p1s16',
    route: WHAT_BUYER_IS_SEEKING_CB,
    h2Copy: 'What does (Ideal.Customer.Name) want in regards to the solution(s) you offer?',
    orderInPillar: 15,
    type: DROP_DOWNS,
    pillar: 1,
    options: [
      'Select One',
      'Satisfaction',
      'Trust',
      'Confidence',
      'Convenience',
      'Security',
      'Value',
      'Excitement',
      'Status or Prestige',
      'Solution to a Problem',
      'Fulfillment of Needs',
      'Long-term Benefits',
      'Emotional Connection',
      'Social Proof',
      'Personalization',
      'Peace of Mind'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerSeeking'
  },
  {
    id: 'p1s17',
    route: WHAT_BUYER_IS_SEEKING_CB,
    h2Copy: 'What types of solutions is (Ideal.Customer.Name) looking for?',
    orderInPillar: 16,
    type: DROP_DOWNS,
    pillar: 1,
    options: [
      'Select One',
      'Problem-Solving Solutions',
      'Convenience Solutions',
      'Financial Solutions',
      'Health and Wellness Solutions',
      'Information and Education Solutions',
      'Entertainment and Leisure Solutions',
      'Technological Solutions',
      'Home and Living Solutions',
      'Transportation Solutions',
      'Social and Relationship Solutions',
      'Environmental Solutions',
      'Career and Professional Development Solutions',
      'Travel and Adventure Solutions',
      'Food and Beverage Solutions',
      'Financial Security Solutions',
      'Safety and Security Solutions',
      'Self-Improvement Solutions',
      'Fashion and Style Solutions'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerSeeking'
  },
  {
    id: 'p1s18',
    route: WHAT_BUYER_IS_SEEKING_CB,
    h2Copy: 'What does (Ideal.Customer.Name) value in choosing your company over a competitor?',
    orderInPillar: 16,
    type: DROP_DOWNS,
    pillar: 1,
    options: [
      'Select One',
      'Quality',
      'Price',
      'Brand Reputation',
      'Customer Reviews',
      'Brand Loyalty',
      'Innovation and Uniqueness',
      'Customer Service',
      'Convenience',
      'Ethical Considerations',
      'Word of Mouth',
      'Experience and Expertise',
      'Customization and Personalization',
      'Cultural or Regional Affiliation'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerSeeking'
  },
  {
    id: 'p1s19',
    route: WHAT_BUYER_IS_SEEKING,
    h2Copy: 'What does success in regards to this solution look like for (Ideal.Customer.Name)?',
    orderInPillar: 18,
    type: FILL_IN_THE_BLANK,
    pillar: 1,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerSeeking'
  },
  {
    id: 'p1s20',
    route: WHAT_BUYER_IS_SEEKING_CB,
    h2Copy: 'What must (Ideal.Customer.Name) first do in order to buy ',
    orderInPillar: 19,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: ['Book Consult/Appt.', 'Buy Online', 'Purchase In-Person', 'Call or Email', 'Request a Quote'],
    button: '',
    defaultAnswerIndex: 1,
    propertyName: 'buyerSeeking'
  },
  {
    id: 'p1s21',
    route: WHAT_BUYER_IS_SEEKING,
    h2Copy: 'What prevents (Ideal.Customer.Name) from achieving what they want?',
    orderInPillar: 20,
    type: FILL_IN_THE_BLANK,
    pillar: 1,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerSeeking',
    functionCall: 'buildCustomerValue'
  },
  {
    id: 'p1s22',
    route: WHAT_BUYER_IS_SAYING_INTRO,
    h2Copy:
      "We are now going to get into the psychology of what your Ideal Customer (Ideal.Customer.Name) is saying. This will help us hone in on the best possible messaging. Let's get started!",
    orderInPillar: 21,
    type: CUSTOM,
    pillar: 1,
    options: [],
    button: "Let's Do This",
    defaultAnswerIndex: 0,
    propertyName: '',
    functionCall: 'buildSeeking'
  },
  {
    id: 'p1s23',
    route: WHAT_BUYER_IS_SAYING_PAIN,
    h2Copy: "What is (Ideal.Customer.Name)'s primary pain point and how do you address it?",
    orderInPillar: 22,
    type: SINGLE_LINE_IN_THE_BLANK,
    pillar: 1,
    options: [
      'Select One',
      'Food & Water',
      'Shelter/Housing',
      'Sleep',
      'Clothing',
      'Personal safety',
      'Financial security',
      'Health and well-being',
      'Stability',
      'Friendship',
      'Intimacy',
      'Family relationships',
      'Community',
      'Self-esteem',
      'Recognition/Respect',
      'Creativity',
      'Problem-solving',
      'Morality',
      'Acceptance of facts and reality',
      'Peak experiences'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'painPointOne',
    propertyName2: 'painPointOneSolution',
    placeholderText: 'Pain Point',
    placeholderText2: 'Please explain how your solution overcomes and solves this pain point.'
  },
  {
    id: 'p1s24',
    route: WHAT_BUYER_IS_SAYING_PAIN,
    h2Copy: "What is (Ideal.Customer.Name)'s secondary pain point and how do you address it?",
    orderInPillar: 23,
    type: SINGLE_LINE_IN_THE_BLANK,
    pillar: 1,
    options: [
      'Select One',
      'Food & Water',
      'Shelter/Housing',
      'Sleep',
      'Clothing',
      'Personal safety',
      'Financial security',
      'Health and well-being',
      'Stability',
      'Friendship',
      'Intimacy',
      'Family relationships',
      'Community',
      'Self-esteem',
      'Recognition/Respect',
      'Creativity',
      'Problem-solving',
      'Morality',
      'Acceptance of facts and reality',
      'Peak experiences'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'painPointTwo',
    propertyName2: 'painPointTwoSolution',
    placeholderText: 'Pain Point',
    placeholderText2: 'Please explain how your solution overcomes and solves this pain point.'
  },
  {
    id: 'p1s26',
    route: WHAT_BUYER_IS_SAYING_PAIN,
    h2Copy: "What is (Ideal.Customer.Name)'s first objection to the sale and how do you overcome it?",
    orderInPillar: 25,
    type: SINGLE_LINE_IN_THE_BLANK,
    pillar: 1,
    options: [
      'Select One',
      'Price Objections',
      'Value Objections',
      'Trust and Credibility Objections',
      'Competitor Comparison Objections',
      'Timing Objections',
      'Risk Objections',
      'Product Fit Objections',
      'Lack of Information Objections',
      'Past Negative Experiences Objections',
      'Authority Objections',
      'Budget Constraints',
      'Perceived Complexity Objections',
      'Lack of Urgency Objections',
      'Fear of Change Objections',
      'Sustainability and Ethical Concerns',
      'Compatibility Objections',
      'Uncertainty About ROI (Return on Investment)',
      'Privacy and Security Concerns',
      'Delivery or Service Timeline Objections'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'objectionOne',
    propertyName2: 'objectionOneOvercome',
    placeholderText: 'Objection',
    placeholderText2: 'Please explain how your solution overcomes and solves this objection.',
    functionCall: 'buildPainPoints'
  },
  {
    id: 'p1s27',
    route: WHAT_BUYER_IS_SAYING_PAIN,
    h2Copy: "What is (Ideal.Customer.Name)'s second objection to the sale and how do you overcome it?",
    orderInPillar: 26,
    type: SINGLE_LINE_IN_THE_BLANK,
    pillar: 1,
    options: [
      'Select One',
      'Price Objections',
      'Value Objections',
      'Trust and Credibility Objections',
      'Competitor Comparison Objections',
      'Timing Objections',
      'Risk Objections',
      'Product Fit Objections',
      'Lack of Information Objections',
      'Past Negative Experiences Objections',
      'Authority Objections',
      'Budget Constraints',
      'Perceived Complexity Objections',
      'Lack of Urgency Objections',
      'Fear of Change Objections',
      'Sustainability and Ethical Concerns',
      'Compatibility Objections',
      'Uncertainty About ROI (Return on Investment)',
      'Privacy and Security Concerns',
      'Delivery or Service Timeline Objections'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'objectionTwo',
    propertyName2: 'objectionTwoOvercome',
    placeholderText: 'Objection',
    placeholderText2: 'Please explain how your solution overcomes and solves this objection.'
  },
  {
    id: 'p1s29',
    route: WHAT_BUYER_IS_DOING_INTRO,
    h2Copy:
      "We now need to think about what your Ideal Customer (Ideal.Customer.Name) is doing. This will help us develop relevant content for them. Let's get started!",
    orderInPillar: 28,
    type: CUSTOM,
    pillar: 1,
    options: [],
    button: "Let's Do this",
    defaultAnswerIndex: 0,
    propertyName: '',
    functionCall: 'buildSaying'
  },
  {
    id: 'p1s31',
    route: WHAT_BUYER_IS_DOING,
    h2Copy: 'Who or what is the biggest influence in your customers buying decision?',
    orderInPillar: 30,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: [
      'Boss/Manager',
      'Influencer/Celebrity',
      'Spouse or Partner',
      'Family Member',
      'Friend',
      'Co-worker/Employee',
      'Online Reviews',
      'Consultant/Agency',
      'Social Network'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerDoing',
    functionCall: 'buildSaleObjections'
  },
  {
    id: 'p1s32',
    route: WHAT_BUYER_IS_DOING,
    h2Copy: 'What type of content should/will the buyer want to read or research before buying from you with regardless of what pain they have?',
    orderInPillar: 31,
    type: DROP_DOWNS,
    pillar: 1,
    options: [
      'Select One',
      'Product Ratings',
      'Comparison Charts',
      'Product Specifications',
      'Customer Testimonials',
      'Expert Opinions',
      'Online Forums',
      'Blogs and Articles',
      'Video Reviews',
      'Company Websites',
      'FAQs (Frequently Asked Questions)',
      'Case Studies',
      'Brochures and Catalogs',
      'Webinars and Tutorials'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerDoing'
  },
  {
    id: 'p1s36',
    route: WHAT_BUYER_IS_FEELING_INTRO,
    h2Copy: "Its time we think about what your Ideal Customer (Ideal.Customer.Name) is feeling. This will help us develop empathy for them. Let's get started!",
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 1,
    options: [],
    button: "Let's Do This",
    defaultAnswerIndex: 0,
    propertyName: '',
    functionCall: 'buildDoing'
  },
  {
    id: 'p1s37',
    route: WHAT_BUYER_IS_FEELING_CB,
    h2Copy: 'How does the buyer perceive themselves before their problem is solved?',
    orderInPillar: 0,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: [
      'Select One',
      'Dissatisfied',
      'Frustrated',
      'Uncertain',
      'Afraid',
      'Overwhelmed',
      'Insecure',
      'Embarrassed',
      'Fiscally On Edge',
      'Defeated',
      'Uncomfortable',
      'Guilty',
      'Stressed',
      'Unhealthy/Sick'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerFeeling'
  },
  {
    id: 'p1s38',
    route: WHAT_BUYER_IS_FEELING_YES_NO,
    h2Copy: 'Have they tried to resolve this issue before and failed?',
    orderInPillar: 0,
    type: YES_NO_EXPAND,
    pillar: 1,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerFeeling'
  },
  {
    id: 'p1s39',
    route: WHAT_BUYER_IS_FEELING_CB_TEXT,
    h2Copy: 'What have their experiences been with competitors products or solutions?',
    orderInPillar: 0,
    type: RADIO_BUTTONS,
    pillar: 1,
    options: [
      'Select One',
      'No Previous Experience',
      'Poor Quality',
      'Lack of Reliability',
      'Poor Results',
      'High Price',
      'Bad Customer Service',
      'Limited Features',
      'Complexity',
      'Inadequate Support',
      'Safety Concerns',
      'Incompatibility',
      'Unsatisfactory User Experience',
      'Hidden Costs',
      'Delays or Long Wait Times',
      'Low Durability',
      'Difficulty in Use or Setup',
      'Limited Availability',
      'Lack of Innovation',
      'Privacy Concerns',
      'Negative Reputation',
      'Subpar Warranty or Guarantee'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerFeeling',
    placeholderText: 'Please Explain'
  },
  {
    id: 'p1s40',
    route: WHAT_BUYER_IS_FEELING_CB,
    h2Copy: 'How does purchasing your product/service make them feel?',
    orderInPillar: 0,
    type: DROP_DOWNS,
    pillar: 1,
    options: [
      'Select One',
      'Satisfied',
      'Relieved',
      'Happy',
      'Excited',
      'Grateful',
      'Confident',
      'Content',
      'Fulfilled',
      'Proud',
      'Elated',
      'Eager',
      'Appreciative',
      'Peace of Mind',
      'Accomplished',
      'Connected',
      'Loyal',
      'Motivated',
      'Comfortable',
      'Hopeful'
    ],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'buyerFeeling'
  },
  {
    id: 'p1s41',
    route: PILLAR_COMPLETE,
    h2Copy: 'Congratulations $FirstName!',
    orderInPillar: 0,
    pCopy: 'You have completed the People You Love Section and created your Ideal Customer. You are 25% of the way there! Begin Pillar Two Your Love Language',
    type: SUMMARY,
    pillar: 1,
    options: [],
    gif: 'https://lottie.host/94a66bc5-cf4e-407d-b7da-61293f9b9865/QajpdiVOTA.json',
    button: 'Begin our love language',
    defaultAnswerIndex: 0,
    propertyName: '',
    functionCall: 'buildFeeling'
  },
  {
    id: 'p2s0',
    route: PILLAR_START_ROUTE,
    h2Copy: 'Marketing RAMP® Pillar Two: Your Love Language',
    orderInPillar: 0,
    type: VIDEO,
    pillar: 2,
    options: [],
    button: 'Skip',
    defaultAnswerIndex: 0,
    propertyName: '',
    videoLink: 'https://vimeo.com/760684118/7f67b79cc5?share=copy',
    functionCall: 'buildPriorBadExperiences'
  },
  {
    id: 'p2s1',
    route: EMPATHY_MAP,
    h2Copy: 'Empathy Map',
    pCopy: "$FirstName, We've created the Empathy Map of what your Ideal Customer is seeking, saying, doing and feeling. Take a look and then, let's move on.",
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 2,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p2s2',
    route: SPECTRUM,
    h2Copy: 'Spectrum Of Formality',
    pCopy:
      "In order for us to truly connect with your Ideal Customers, we must identify how your company's communications sound and feel using the Spectrum of Formality. Please adjust your brand's tone for each of the 4 parts. ",
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 2,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'spectrumCasual'
  },
  {
    id: 'p2s3',
    route: COMPANY_PURPOSE,
    h2Copy: 'What does your company do?',
    orderInPillar: 0,
    type: FILL_IN_THE_BLANK,
    pillar: 2,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'companyCorePurposeOne'
  },
  {
    id: 'p2s8',
    route: CORE_SERVICES_START,
    h2Copy: 'We are now going to outline your Customer Ascension Plan for your Ideal Customer, (Ideal.Customer.Name).',
    orderInPillar: 0,
    type: SUMMARY,
    gif: 'https://lottie.host/78a454f7-30d4-43df-9c39-70aaeba53200/Xf6aGlNj2N.json',
    pillar: 2,
    options: [],
    button: "Let's get Rocking'!",
    defaultAnswerIndex: 0,
    propertyName: '',
    functionCall: 'buildWeAlign'
  },
  {
    id: 'p2s9',
    route: CORE_SERVICES_SL,
    h2Copy: 'What is the primary product you want your Ideal Customer to buy?',
    orderInPillar: 0,
    type: SINGLE_LINE_IN_THE_BLANK,
    pillar: 2,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'coreServiceOne'
  },
  {
    id: 'p2s10',
    route: CORE_SERVICES_SL,
    h2Copy: 'What is the secondary product you want your Ideal Customer to buy?',
    orderInPillar: 0,
    type: SINGLE_LINE_IN_THE_BLANK,
    pillar: 2,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'coreServiceTwo'
  },
  {
    id: 'p2s11',
    route: CORE_SERVICES,
    h2Copy: 'Do you offer a guarantee? If so, describe it briefly below.',
    orderInPillar: 0,
    type: FILL_IN_THE_BLANK,
    pillar: 2,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'coreServiceThree'
  },
  {
    id: 'p2s17',
    route: STEPS_TO_SUCCESS_START,
    h2Copy:
      "$FirstName, It's now time we develop some really simple steps to success for your Ideal Customer. This will allow us to quickly communicate with them what to do and why. ",
    orderInPillar: 0,
    type: SUMMARY,
    pillar: 2,
    icon: STEPS_0,
    options: [],
    button: 'Create Your Steps to Success',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p2s18',
    route: STEPS_TO_SUCCESS,
    h2Copy:
      'The first step is always the first action the Ideal Customer must do to embark on their journey of transformation with your product/service. Enter a short statement describing what they must do below.',
    orderInPillar: 0,
    type: SINGLE_LINE_IN_THE_BLANK,
    icon: STEPS_1,
    pillar: 2,
    options: [],
    button: '',
    placeholderText: 'Book your consult/Get your free quote/View the collection',
    defaultAnswerIndex: 0,
    propertyName: 'successStepOne'
  },
  {
    id: 'p2s19',
    route: STEPS_TO_SUCCESS,
    h2Copy:
      'The second step is a concise and simple way to sum up what your product/service does to get the end result. Enter a short statement describing this below.',
    orderInPillar: 0,
    type: SINGLE_LINE_IN_THE_BLANK,
    icon: STEPS_2,
    pillar: 2,
    options: [],
    button: '',
    placeholderText: "We'll develop a strategy/proprietary formula will improve your/order will be delivered",
    defaultAnswerIndex: 0,
    propertyName: 'successStepTwo'
  },
  {
    id: 'p2s20',
    route: STEPS_TO_SUCCESS,
    h2Copy: 'The third step is always the transformation with their pain now removed. Enter a short statement describing their success.',
    orderInPillar: 0,
    type: SINGLE_LINE_IN_THE_BLANK,
    icon: STEPS_3,
    pillar: 2,
    options: [],
    button: '',
    placeholderText: "your business will grow/you'll feel better/the problem will now be solved",
    defaultAnswerIndex: 0,
    propertyName: 'successStepThree'
  },
  {
    id: 'p2s21',
    route: STEPS_TO_SUCCESS_COMPLETE,
    h2Copy: "Booyah!!! We've now get our 3 incredibly simple steps to success for \nyour Ideal Customers! Let's move on to the next section!",
    orderInPillar: 0,
    type: SUMMARY,
    pillar: 2,
    options: [],
    icon: SUCCESS,
    button: "Let's make some magic!",
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p2s22',
    route: CUSTOMER_ALIGNMENT_START,
    h2Copy:
      "You're now going to see a side-by-side comparison of what your Ideal Customers' value and how your company aligns with them. Please review and make any adjustments you see fit. ",
    orderInPillar: 0,
    type: SUMMARY,
    pillar: 2,
    options: [],
    button: 'Show me what we got',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p2s23',
    route: CUSTOMER_ALIGNMENT,
    h2Copy: 'Review the customer alignments below and adjust as you see fit.Your Customers Your Company',
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 2,
    options: [],
    button: 'Next',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p2s24',
    route: SECRET_SAUCE_START,
    h2Copy:
      "It's time, $FirstName that we get into what makes your company great. That's your secret sauce and we've got a proprietary way to find it for you. Ready to dive in?",
    orderInPillar: 0,
    type: SUMMARY,
    pillar: 2,
    options: [],
    icon: SECRET_SAUCE_ICON,
    button: "Let's make my secret sauce",
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p2s25',
    route: SECRET_SAUCE_VIDEO,
    h2Copy: "Your Company's Secret Sauce",
    orderInPillar: 0,
    type: VIDEO,
    pillar: 2,
    options: [],
    button: 'Skip/Next',
    defaultAnswerIndex: 0,
    propertyName: '',
    videoLink: 'https://vimeo.com/760684740/aa51f67ace?share=copy'
  },
  {
    id: 'p2s26',
    route: SECRET_SAUCE_ONE,
    h2Copy:
      'Select the item your company is the best at. Describe why. Then tell us about your top two competitors and how they are with your first superpower',
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 2,
    options: ['Time', 'Price', 'Unique Features', 'Innovation', 'Experience', 'Success'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'secretSauce'
  },
  {
    id: 'p2s27',
    route: SECRET_SAUCE_TWO,
    h2Copy:
      'Select the item your company is your company’s second superpower. Describe why. Then tell us about your two top competitors and how they compare to you. ',
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 2,
    options: ['Time', 'Price', 'Unique Features', 'Innovation', 'Experience', 'Success'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'secretSauce'
  },
  {
    id: 'p2s28',
    route: SECRET_SAUCE_THREE,
    h2Copy:
      'Select the item your company is your third superpower. Describe why. Then tell us about your two top competitors and how they perform at your this superpower. ',
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 2,
    options: ['Time', 'Price', 'Unique Features', 'Innovation', 'Experience', 'Success'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: 'secretSauce'
  },
  {
    id: 'p2s29',
    route: PILLAR_COMPLETE,
    h2Copy: 'Congratulations $FirstName!',
    pCopy: ' You have completed the second pillar of the RAMP: Your Love Language. \nYou are 50% of the way there! Begin Pillar Three Your Proposal',
    orderInPillar: 0,
    type: SUMMARY,
    pillar: 2,
    options: [],
    button: 'BEGIN YOUR PROPOSAL',
    icon: PILLAR_COMPLETION_TWO,
    defaultAnswerIndex: 0,
    propertyName: '',
    functionCall: 'buildOurShortNarrative'
  },
  {
    id: 'p3s0',
    route: PILLAR_START_ROUTE,
    h2Copy: 'Marketing RAMP® Pillar Three: Your Proposal',
    orderInPillar: 0,
    type: VIDEO,
    pillar: 3,
    options: [],
    button: 'Skip/Next',
    defaultAnswerIndex: 0,
    propertyName: '',
    videoLink: 'https://vimeo.com/760685190/d24a621ea4?share=copy'
  },
  {
    id: 'p3s1',
    route: BRAND_PITCH_GPT,
    h2Copy: 'Our Artificial Intelligence has created your Brand Pitch for you. Please take a moment to review it and then make any revisions you see fit.',
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 3,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p3s2',
    route: PILLAR_COMPLETE,
    h2Copy: 'Hey $FirstName, ',
    pCopy:
      "it's time for a virtual high five! You have successfully completed the third pillar of the RAMP and are 75% finished! It's now time to dig into the most powerful part of the RAMP: Pillar 4: The Relationship Stages.",
    orderInPillar: 0,
    type: SUMMARY,
    pillar: 3,
    options: [],
    icon: PILLAR_COMPLETION_THREE,
    button: "Let's build my RAMP!",
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p4s0',
    route: PILLAR_START_ROUTE,
    h2Copy: 'Marketing RAMP® Pillar Four: Your Relationship Stages',
    orderInPillar: 0,
    type: VIDEO,
    pillar: 4,
    options: [],
    button: 'Skip/Next',
    defaultAnswerIndex: 0,
    propertyName: '',
    videoLink: ''
  },
  {
    id: 'p4s1',
    route: UPGRADE_ROUTE,
    h2Copy:
      "$FirstName, You've got options on how to finish the most powerful part of the RAMP. We suggest you let our expertly tuned AI to create your entire Marketing RAMP® and write all your communications for you. This will save you time and get you done in a matter of minutes.",
    pCopy:
      'We suggest you let our expertly tuned AI to create your entire Marketing RAMP® and write all your communications for you. This will save you time and get you done in a matter of minutes.',
    orderInPillar: 0,
    type: CUSTOM,
    pillar: 4,
    options: [],
    gif: 'https://lottie.host/61c81455-69cd-4582-bbd3-4d5af95d172a/1vj6a6SmN4.json',
    button: 'Upgrade for $97',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p4s2',
    route: AI_IN_PROGRESS,
    h2Copy: 'Magic is underway, $FirstName!',
    pCopy:
      "Currently our AI is busy following our proprietary RAMP framework and creating all ten stages if the buyer's journey, writing your communications. In the meantime, please watch this brief video to understand what is being created for you.",
    orderInPillar: 0,
    type: VIDEO,
    pillar: 4,
    options: [],
    button: 'Next',
    defaultAnswerIndex: 0,
    propertyName: '',
    videoLink: ''
  },
  {
    id: 'p4s3',
    route: VIDEO_WATCHED,
    h2Copy: 'Your custom-made RAMP is fresh out of the oven! Click here to review the goods.',
    orderInPillar: 0,
    type: SUMMARY,
    pillar: 4,
    options: [],
    button: '',
    gif: 'https://lottie.host/294095f9-4251-41a7-96b5-953211845983/xYrG9crfj3.json',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  {
    id: 'p4s4',
    route: RAMP_COMPLETION,
    h2Copy: '',
    orderInPillar: 0,
    type: '',
    pillar: 4,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: ''
  },
  /* {
    id: 'p4s6',
    route: RAMP_REVIEW,
    h2Copy: '',
    orderInPillar: 0,
    type: '',
    pillar: 4,
    options: [],
    button: 'Find Experts That Match My Needs',
    defaultAnswerIndex: 0,
    propertyName: ''
  }, */
  /* {
    id: 'p4s7',
    route: RAMP_SHIPPING,
    h2Copy: 'Do you want to add any collaborators or viewers into your RAMP?',
    orderInPillar: 0,
    type: '',
    pillar: 4,
    options: [],
    button: 'ADD COLLABORATORS',
    icon: COLLABORATORS,
    defaultAnswerIndex: 0,
    propertyName: ''
  }, */
  /* {
    id: 'p4s8',
    route: UP_SELL_1,
    h2Copy: 'Would you like us to instantly create the following for you? Website wireframe Advertising Audience with ad copy SEO keywords for your website',
    orderInPillar: 0,
    type: '',
    icon: UP_SELL,
    pillar: 4,
    options: ['Website wireframe', 'Advertising audience with ad copy', 'SEO keywords for your website'],
    button: 'Upgrade for $147',
    defaultAnswerIndex: 0,
    propertyName: 'Check Out'
  }, */
  /* {
    id: 'p4s9',
    route: UP_SELL_2,
    h2Copy: "It's now time to build your RAMP using a CRM.",
    orderInPillar: 0,
    pCopy: "Would you like us to help you build the RAMP inside your CRM? If you don't have one, don't worry, we can get you set up in a jiffy.",
    type: '',
    icon: UP_SELL,
    pillar: 4,
    options: [],
    button: 'Find Experts That Match My Needs',
    defaultAnswerIndex: 0,
    propertyName: ''
  }, */
  /* {
    id: 'p4s10',
    route: PARTNER_RESULTS,
    h2Copy: "Here's Certified Experts that will ensure your ramp success!",
    orderInPillar: 0,
    type: '',
    pillar: 4,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: ''
  }, */
  /* {
    id: 'p4s11',
    route: PARTNER_HIRED,
    h2Copy: 'A match made in Heaven $FirstName',
    pCopy:
      'Securely share your RAMP with your trusted partner by clicking the button below. This will give access to your partner to view your RAMP. You can always remove this permission later.',
    orderInPillar: 0,
    type: '',
    icon: PARTNER_HIRED_ICON,
    pillar: 4,
    options: ['Share My RAMP To My Trusted Partner'],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: ''
  }, */
  {
    id: 'p4s12',
    route: NEXT_STEPS,
    h2Copy: 'Congratulations $FirstName',
    pCopy:
      "You've successfully completed the RAMP. It's now time to start building. \nWe'll be following up with your progress via email over the next few weeks. \nIf you need any assistance, we are here for you!",
    orderInPillar: 0,
    type: '',
    pillar: 4,
    icon: PILLAR_COMPLETION_FOUR,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: ''
  } /* ,
  {
    id: 'p5s0',
    route: DASHBOARD,
    h2Copy: '',
    orderInPillar: 0,
    type: '',
    pillar: 5,
    options: [],
    button: '',
    defaultAnswerIndex: 0,
    propertyName: ''
  } */
]

import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AGE_RANGE } from '../constants/DropDownConstants'
import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { CheckBoxComponent } from '../components/CheckBoxComponent'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RadioButtonSquareComponent } from '../components/RadioButtonSquareComponent'
import { RampProgressBarComponent } from '../components/rampProgressBar/RampProgressBarComponent'
import { RootState } from '../Store'
import { addRemove } from '../utils/ArrayUtils'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const CustomerChildrenScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const { hc, ageRange, numChildren } = useSelector((state: RootState) => {
    return {
      hc: state.IdealCustomer.hasChildren,
      ageRange: state.IdealCustomer.childrenAgeRange,
      numChildren: state.IdealCustomer.numberOfChildren
    }
  })
  const { isTalking, callServer } = usePillarCommunication()
  const { nextPage, goBack, updateProgress } = useScreenNavigation()
  const [hasChildren, setHasChildren] = useState(hc)
  const [childrenAgeRange, setChildrenAgeRange] = useState(ageRange)
  const [numberOfChildren, setNumberOfChildren] = useState(numChildren)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault() // Prevent the form submission
    nextPageClick()
  }
  const nextPageClick = async () => {
    const result = await callServer(
      {
        hasChildren,
        numberOfChildren,
        childrenAgeRange
      },
      'customersChildren'
    )
    if (result) {
      dispatch(
        updateIdeal({
          hasChildren,
          numberOfChildren,
          childrenAgeRange
        })
      )
      nextPage()
    }
  }

  const checkSelectedCheckbox = (arg: string) => {
    setChildrenAgeRange(addRemove(arg, childrenAgeRange as string[]))
  }

  const renderAgeRanges = () => {
    const ranges = AGE_RANGE.map((range, index) => {
      return <CheckBoxComponent label={range.label} onClick={checkSelectedCheckbox} key={range.label + index} isSelected={isCheckBoxSelected(range.label)} />
    })
    return ranges
  }

  const onChangeNumberOfChildren = (arg: string) => {
    setNumberOfChildren(Number(arg))
  }

  const isCheckBoxSelected = (arg: string) => {
    if (childrenAgeRange.includes(arg)) {
      return true
    }
    return false
  }

  const onChangeHasChildren = (arg: string) => {
    setHasChildren(arg)
    if (arg === 'Yes') {
      setNumberOfChildren(1)
      setNumberOfChildren(numChildren)
      return
    }
    setNumberOfChildren(0)
    setChildrenAgeRange([])
  }

  const renderKidInfo = () => {
    if (hasChildren === 'Yes') {
      return (
        <>
          <p>How many children?</p>
          <div className="radio-buttons">
            <RadioButtonSquareComponent label="1" isSelected={numberOfChildren === 1} onClick={onChangeNumberOfChildren} />
            <RadioButtonSquareComponent label="2" isSelected={numberOfChildren === 2} onClick={onChangeNumberOfChildren} />
            <RadioButtonSquareComponent label="3" isSelected={numberOfChildren === 3} onClick={onChangeNumberOfChildren} />
            <RadioButtonSquareComponent label="4" isSelected={numberOfChildren === 4} onClick={onChangeNumberOfChildren} />
            <RadioButtonSquareComponent label="5" isSelected={numberOfChildren === 5} onClick={onChangeNumberOfChildren} />
          </div>
          <p>Children's age ranges?</p>
          <div className="radio-buttons">{renderAgeRanges()}</div>
        </>
      )
    }
    return null
  }
  return (
    <div className="mr rmsteps pillar_css">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <RampProgressBarComponent id={id ?? ''} />
        <div className="mr-content">
          <center>
            <h2>Your Ideal Customer's children?</h2>
          </center>

          <div className="form-elements">
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <p>Do they have children?</p>
                <div className="form-field fw">
                  <div className="radio-buttons">
                    <RadioButtonSquareComponent label="Yes" isSelected={hasChildren === 'Yes'} onClick={onChangeHasChildren} group="yesno" />
                    <RadioButtonSquareComponent label="No" isSelected={hasChildren === 'No'} onClick={onChangeHasChildren} group="yesno" />
                    <RadioButtonSquareComponent label="N/A" isSelected={hasChildren === 'N/A'} onClick={onChangeHasChildren} group="yesno" />
                  </div>
                </div>
                {renderKidInfo()}
                <ButtonGroupComponent goBack={goBack} nextPageClick={nextPageClick} processing={isTalking} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

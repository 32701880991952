import { createApi } from '@reduxjs/toolkit/query/react'
import { createUserHelper } from './jsonHelpers/userHelpers/createUserHelper'
import { getUserByIdHelper } from './jsonHelpers/userHelpers/getUserByIdHelper'
import { gql } from 'graphql-request'
import { graphqlBaseQuery } from './graphqlBaseQuery'
import { processResponse } from '../utils/UserUtils'
import { upgradeUserHelper } from './jsonHelpers/userHelpers/upgradeUserHelper'
export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  type: string
  companyName: string
  companyWebsite: string
  title: string
  industry: string
  revenue: string
  country: string
  state: string
  phone: string
  ramps: string[] | string
}

export interface CreateUserInput {
  id: string
  firstName: string
  lastName: string
  email: string
  type: string
  companyName: string
  companyWebsite: string
  title: string
  industry: string
  revenue: string
  country: string
  state: string
  phone: string
  ramps: string[]
  rampId: string
  idealCustomerId: string
  customerAlignmentId: string
  brandPitchId: string
}

export interface UpdateUserInput {
  id: string
  firstName: string
  lastName: string
  email: string
  type: string
  companyName: string
  companyWebsite: string
  title: string
  industry: string
  revenue: string
  country: string
  state: string
  phone: string
  ramps: string[]
  hasUpgraded: boolean
}

export interface CreateUserResponse {
  data: {
    createUser: User
  }
}

export interface GetUserByIdResponse {
  getUserById: User
}
export interface UpdateUserResponse {
  data: {
    updateUser: User
  }
}

const isGetUserByIdResponse = (obj: any): obj is GetUserByIdResponse => {
  const process = 'getUserById' in obj
  return process
}

const createUserResponse = (obj: any): obj is CreateUserResponse => {
  return 'data' in obj && 'createUser' in obj.data
}

const updateUserResponse = (obj: any): obj is UpdateUserResponse => {
  return 'data' in obj && 'loginUser' in obj.data
}

export const usersSlice = createApi({
  reducerPath: 'usersApi',
  baseQuery: graphqlBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_SERVER!
  }),
  endpoints: builder => ({
    getUserById: builder.query<User, string>({
      query: userId => ({
        body: getUserByIdHelper(userId)
      }),
      transformResponse: (response: GetUserByIdResponse | User): User => (isGetUserByIdResponse(response) ? processResponse(response.getUserById) : response)
    }),
    createUser: builder.query<User, CreateUserInput>({
      query: user => ({
        body: createUserHelper(user)
      }),
      transformResponse: (response: CreateUserResponse) => (createUserResponse(response) ? response.data.createUser : response)
    }),
    updateUser: builder.query<User, UpdateUserInput>({
      query: user => ({
        body: gql`
          mutation updateUser {
            updateUser(user: {
              id: "${user.id}",
              firstName: "${user.firstName}",
              lastName: "${user.lastName}",
              email: "${user.email}",
              type: "${user.type}",
            }) {
              id
              firstName
              lastName
              email
              type
            }
          }
        `
      }),
      transformResponse: (response: UpdateUserResponse) => (updateUserResponse(response) ? response.data.updateUser : response)
    }),
    upgradeUser: builder.query<User, string>({
      query: id => ({
        body: upgradeUserHelper(id)
      })
    })
  })
})

export const { useLazyGetUserByIdQuery, useLazyCreateUserQuery, useLazyUpdateUserQuery, useLazyUpgradeUserQuery } = usersSlice

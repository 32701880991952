import { ChildrenUpdate } from '../icSlice'
import { gql } from 'graphql-request'
export const updateChildren = (arg: ChildrenUpdate) => {
  if (arg.hasChildren === 'Yes') {
    const newArray: string[] = [...arg.childrenAgeRange]
    const json2 = JSON.stringify(JSON.stringify(newArray))
    return gql`
    mutation updateIdealCustomer {
      updateIdealCustomer (idealCustomer: {
        hasChildren: "${arg.hasChildren}",
        numberOfChildren: ${arg.numberOfChildren},
        childrenAgeRange: ${json2},
      }) {
        id
      }
    }
  `
  }
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          hasChildren: "${arg.hasChildren}",
        }) {
          id
        }
      }
    `
}

import { AD_2, AD_4 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import { answerQuestionEight, answerQuestionNine, answerQuestionSeven, answerQuestionTen, updateQuestion } from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { RadioButtonSquareComponent } from '../../../../components/RadioButtonSquareComponent'
import { TextAreaFormField } from '../../../../components/TextAreaFormFieldComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificThreePanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { answerSeven, answerEight, answerNine, answerTen } = useSelector((state: RootState) => {
    return {
      answerSeven: state.AdSpecific.questionSeven,
      answerEight: state.AdSpecific.questionEight,
      answerNine: state.AdSpecific.questionNine,
      answerTen: state.AdSpecific.questionTen
    }
  })
  const next = () => {
    dispatch(updateQuestion(AD_4))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_2))
  }
  const changeAnswerSeven = (arg: string) => {
    dispatch(answerQuestionSeven(arg))
  }
  const changeAnswerEight = (arg: string) => {
    dispatch(answerQuestionEight(arg))
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">B2B Ideal Target Business Supplementary Questions</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <TextAreaFormField label="Your Ideal customer's role in the company" value={answerSeven} onChange={changeAnswerSeven} />
              <TextAreaFormField label="How long is their average buying cycle?" value={answerEight} onChange={changeAnswerEight} />

              <div className="form-field fw">
                <label>Annual revenue this year?</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="$0 - $499k" isSelected={answerNine === '$0 - $499k'} onClick={answerQuestionNine} />
                  <RadioButtonSquareComponent label="$500 - $999k" isSelected={answerNine === '$500 - $999k'} onClick={answerQuestionNine} />
                  <RadioButtonSquareComponent label="$1M - $4.9M" isSelected={answerNine === '$1M - $4.9M'} onClick={answerQuestionNine} />
                  <RadioButtonSquareComponent label="$5M - $9.9k" isSelected={answerNine === '$5M - $9.9k'} onClick={answerQuestionNine} />
                </div>
              </div>
              <div className="form-field fw">
                <label>Year in business?</label>
                <div className="radio-buttons">
                  <RadioButtonSquareComponent label="0 - 5" isSelected={answerTen === '0 - 5'} onClick={answerQuestionTen} />
                  <RadioButtonSquareComponent label="6 - 10" isSelected={answerTen === '6 - 10'} onClick={answerQuestionTen} />
                  <RadioButtonSquareComponent label="11 - 15" isSelected={answerTen === '11 - 15'} onClick={answerQuestionTen} />
                  <RadioButtonSquareComponent label="16 - 20" isSelected={answerTen === '16 - 20'} onClick={answerQuestionTen} />
                  <RadioButtonSquareComponent label="20+" isSelected={answerTen === '20+'} onClick={answerQuestionTen} />
                </div>
              </div>

              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

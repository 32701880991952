import React, { FC } from 'react'

type Props = {
  percentageOne: number
  percentageTwo: number
  nameOne: string
  nameTwo: string
  color: string
}
export const SpectrumItem: FC<Props> = ({ percentageOne, percentageTwo, nameOne, nameTwo, color }: Props) => {
  const name = percentageOne > percentageTwo ? nameOne : nameTwo
  const percentage = percentageOne > percentageTwo ? percentageOne : percentageTwo

  return (
    <div className="item-formality">
      <div className="row">
        <div className="col-md-4">{name}</div>
        <div className="col-md-8" style={{ textAlign: 'right' }}>
          <span>{`${percentage}%`}</span>
        </div>
      </div>
      <div className="progress" style={{ height: '4px' }} />
      <div className="progress" style={{ height: '4px', top: '-4px', position: 'relative', backgroundColor: `${color}`, width: `${percentage}%` }} />
    </div>
  )
}

import React, { FC } from 'react'

type Props = {
  label: string
  id?: string
  value: string
  placeholder?: string
  onChange: (arg: string) => void
}
export const TextInputFullWidthComponent: FC<Props> = ({ label, placeholder = '', onChange, value, id = 'singleId' }: Props) => {
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className="form-field fw">
      <label htmlFor="acc-name">{label}</label>
      <input type="text" name={label} id={id} placeholder={placeholder} onChange={onChanged} value={value} autoComplete="off" />
    </div>
  )
}

import { Question, ScreenTracker } from '../types/AppTypes'

import { createSlice } from '@reduxjs/toolkit'

export interface GptEmail {
  id: string
  email: string
  isComplete: boolean
  prompt: string
  template: string
}
export interface ConsumerProjectDef {
  id: string
  idealCustomerId: string
  ownerId: string
  brandPitchId: string
  customerAssessmentId: string
  questions: Question[]
  pillarOneQuestions: Question[]
  pillarTwoQuestions: Question[]
  pillarThreeQuestions: Question[]
  pillarFourQuestions: Question[]
  pillarOneProgress: string
  pillarTwoProgress: string
  pillarThreeProgress: string
  pillarFourProgress: string
  pillarOneTotalSlides: number
  pillarTwoTotalSlides: number
  pillarThreeTotalSlides: number
  pillarFourTotalSlides: number
  screens: ScreenTracker[]
  currentScreenIndex: number
  currentScreenId: string
  currentPillar: number
  emailsGenerated: boolean
  emails?: GptEmail[] | string
  rampCompleted: boolean
}

const getInitialState = (): ConsumerProjectDef => {
  return {
    id: '',
    idealCustomerId: '',
    ownerId: '',
    brandPitchId: '',
    customerAssessmentId: '',
    questions: [],
    pillarOneQuestions: [],
    pillarTwoQuestions: [],
    pillarThreeQuestions: [],
    pillarFourQuestions: [],
    pillarOneProgress: '0%',
    pillarTwoProgress: '0%',
    pillarThreeProgress: '0%',
    pillarFourProgress: '0%',
    pillarOneTotalSlides: 0,
    pillarTwoTotalSlides: 0,
    pillarThreeTotalSlides: 0,
    pillarFourTotalSlides: 0,
    screens: [],
    currentScreenIndex: 0,
    currentPillar: 1,
    currentScreenId: '',
    emailsGenerated: false,
    rampCompleted: false
  }
}

export const consumerProjectSlice = createSlice({
  name: 'ConsumerProject',
  initialState: getInitialState(),
  reducers: {
    setInitialState: state => {
      return getInitialState()
    },
    completeRamp: state => {
      state.rampCompleted = true
    },
    updateCustomer: (state: ConsumerProjectDef, action) => {
      console.log('Update Customer ', action.payload)
      return { ...state, ...action.payload }
    },
    reset: (state: ConsumerProjectDef) => {
      return getInitialState()
    },
    nextScreen: (state: ConsumerProjectDef) => {
      state.screens[state.currentScreenIndex].status = 'done'
      state.currentScreenIndex++
      if (state.currentScreenIndex > state.pillarOneQuestions.length) {
        state.currentPillar = 1
      }
      if (state.currentScreenIndex >= state.pillarOneQuestions.length && state.currentScreenIndex < state.pillarTwoQuestions.length) {
        state.currentPillar = 2
      }
      if (state.currentScreenIndex >= state.pillarTwoQuestions.length && state.currentScreenIndex < state.pillarThreeQuestions.length) {
        state.currentPillar = 3
      }
      if (state.currentScreenIndex > state.pillarThreeQuestions.length) {
        state.currentPillar = 4
      }
    },
    lastScreen: (state: ConsumerProjectDef) => {
      state.currentScreenIndex--
    }
  }
})

export const { setInitialState, updateCustomer, reset, nextScreen, lastScreen, completeRamp } = consumerProjectSlice.actions
export default consumerProjectSlice.reducer

// going to get all questions in the DB - file first
// lets see how big the json file is to download at once.
// set up a Query slice. - go get the questions from generic
// get answers from project -
/// set up this slice

import { AppDispatch, RootState } from '../Store'
import { CODE_CONFIRMED_ROUTE, DASHBOARD_ROUTE, REGISTER_ROUTE, VERIFY_ROUTE } from '../constants/RouteConstants'
import { MeDef, resetStartPasswordRecovery, startResettingPassword, updateMe } from '../reducers/MeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLazyCreateUserQuery, useLazyGetUserByIdQuery } from '../api/usersSlice'
import validate, { validatePassword } from '../utils/validation/validate_wrapper'

import { CognitoResponse } from '../types/AppTypes'
import { addIdToRoute } from '../utils/RouteUtils'
import confirmCognitoPassword from '../utils/cognito/confirmCognitoPassword'
import { convertMeToCreateUserInput } from '../utils/UserUtils'
import loginCognitoUser from '../utils/cognito/loginCognitoUser'
import resendCognitoCode from '../utils/cognito/resendCognitoCode'
import sendCognitoPasswordCode from '../utils/cognito/sendCognitoPasswordCode'
import signUpUserWithEmail from '../utils/cognito/signUpCognitoUserWithEmail'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { useNavigate } from 'react-router-dom'
import verifyCognitoCode from '../utils/cognito/verifyCognitoCode'

export const useCognito = (): {
  email: string
  firstName: string
  lastName: string
  password: string
  registrationError: string
  errorMessage: string
  loginError: string
  forgotPasswordError: string
  verificationError: string
  verificationCode: string
  submittingRegistration: boolean
  loggingIn: boolean
  verifying: boolean
  setEmail: (arg: string) => void
  setFirstName: (arg: string) => void
  setLastName: (arg: string) => void
  setPassword: (arg: string) => void
  setVerificationCode: (arg: string) => void
  register: () => Promise<void>
  validateCurrentPassword: () => void
  validateEmail: () => void
  login: () => Promise<string | null>
  resendCode: () => void
  verify: () => Promise<void>
  forgot: () => Promise<void>
  changePassword: () => Promise<void>
  refreshTheToken: (arg: string) => void
} => {
  const user: MeDef = useSelector((state: RootState) => state.Me)
  const [createUser] = useLazyCreateUserQuery()
  const dispatch = useDispatch<AppDispatch>()
  const [email, setEmail] = useState(user.email)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState(user.password)
  const [verificationCode, setVerificationCode] = useState('')

  const [submittingRegistration, setSubmittingRegistration] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [registrationError, setRegistrationError] = useState('')
  const [loginError, setLoginError] = useState('')
  const [verificationError, setVerificationError] = useState('')
  const [forgotPasswordError, setForgotPasswordError] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    //
  }, [])

  const register = async () => {
    // validate email
    const emailValid = validate('email', email)
    if (emailValid === 'Email is invalid') {
      setErrorMessage('Please use valid email')
      return
    }
    if (!validatePassword(password) && password !== '') {
      setErrorMessage('Password must contain 8 characters. Passwords should container uppercase, lowercase, numbers and special characters')
      return
    }
    setSubmittingRegistration(true)
    const cognitoResponse: CognitoResponse = await signUpUserWithEmail(email, email, password)
    if (cognitoResponse.code) {
      setSubmittingRegistration(false)
      setRegistrationError(cognitoResponse.code)
      switch (cognitoResponse.code) {
        case 'UsernameExistsException':
          // navigation.navigate(LOGIN)
          setErrorMessage('This email already exists, goto login')
          return
        case 'ResourceNotFoundException':
          setErrorMessage('Resource not found exception')
          console.log('ResourceNotFoundException', JSON.stringify(cognitoResponse))

          return
        default:
          console.log('WE HAVE A FAILURE NOT HANDLED', JSON.stringify(cognitoResponse))
          setErrorMessage('Something went wrong please try again')
      }
      return
    }
    dispatch(
      updateMe({
        id: cognitoResponse.userSub,
        firstName: firstName,
        lastName: lastName,
        email: email.toLowerCase(),
        password,
        signUpSent: true,
        verifiedUser: false
      })
    )
    setSubmittingRegistration(false)
    navigate(REGISTER_ROUTE)
  }

  const login = async () => {
    // make sure to make async
    setLoggingIn(true)
    console.log('Logging in')
    try {
      const cognitoResponse: CognitoResponse = await loginCognitoUser(email, password)
      if (cognitoResponse.code) {
        if (cognitoResponse.code === 'UserNotConfirmedException') {
          dispatch(updateMe({ registrationSent: true }))
          setErrorMessage(cognitoResponse.message)
          setLoggingIn(false)
        }
        setLoginError(cognitoResponse.message)
        setLoggingIn(false)
        return null
      }
      // update token
      dispatch(
        updateMe({
          jwtToken: cognitoResponse.accessToken.jwtToken,
          refreshToken: cognitoResponse.refreshToken.token
        })
      )
      return cognitoResponse.accessToken.payload.username
    } catch (e) {
      setLoggingIn(false)
      if (e instanceof Error) {
        setLoginError('Could Login User instance of error ')
        console.log('Error on create user', e.message)
      } else {
        setLoginError('user Doesnt exists problem')
        console.log('We are in error', e)
      }
      return null
    }
  }

  const changePassword = async () => {
    if (!email) {
      setErrorMessage('No email address. Something went wrong')
    }
    if (!validatePassword(password) && password !== '') {
      setErrorMessage('Password must contain 8 characters. Passwords should container uppercase, lowercase, numbers and special characters')
    } else {
      const cognitoResponse = await confirmCognitoPassword(email, password, verificationCode)
      console.log(cognitoResponse)
      dispatch(resetStartPasswordRecovery())
    }
  }

  const validateEmail = () => {
    validate('email', email) !== 'Email is invalid' ? setErrorMessage('') : setErrorMessage('Email address is not a valid format')
  }

  const validateCurrentPassword = () => {
    if (!validatePassword(password) && password !== '') {
      setErrorMessage('Password must contain 8 characters. 1 uppercase,  1 lowercase, 1 number and 1 special character')
    } else {
      setErrorMessage('')
    }
  }

  const resendCode = async () => {
    // resendCognitoCode
    console.log('Resending the code')
    try {
      const result = await resendCognitoCode(email)
      console.log('Resend Code Result', result)
    } catch (e) {
      console.log('Could not resend the code')
    }
  }

  const verify = async () => {
    setVerifying(true)
    setVerificationError('')
    const cognitoVerifyResponse = await verifyCognitoCode(email, verificationCode)
    console.log('Cognito Verify Response', cognitoVerifyResponse)
    if (cognitoVerifyResponse === 'SUCCESS') {
      try {
        const cognitoLoginResponse = await loginCognitoUser(email, password)
        dispatch(
          updateMe({
            jwtToken: cognitoLoginResponse.accessToken.jwtToken,
            refreshToken: cognitoLoginResponse.refreshToken.token,
            registrationSent: true,
            companyInfoSent: true,
            verifiedUser: true
          })
        )
      } catch (e) {
        console.log('Logging in failed Differently')
        setVerifying(false)
        return
      }
      try {
        console.log('Creating the user')
        const createdUserData = convertMeToCreateUserInput(user)
        console.log('Next item ')
        const createUserResponse = await createUser(createdUserData).unwrap()
        console.log('Response')
        dispatch(updateMe(createUserResponse))
        console.log('Updated Me')
        dispatch(
          updateIdeal({
            id: createdUserData.idealCustomerId
          })
        )
        console.log('updateIdeal')
        dispatch(
          updateCustomer({
            id: createdUserData.rampId,
            idealCustomerId: createdUserData.idealCustomerId,
            ownerId: createdUserData.id,
            brandPitchId: createdUserData.brandPitchId,
            customerAssessmentId: createdUserData.customerAlignmentId
          })
        )
        console.log('getReady to Navigate')
        navigate(addIdToRoute(CODE_CONFIRMED_ROUTE, 'p0'))
      } catch (e) {
        setVerifying(false)
        console.log('THIS IS AN ERROR')
        if (e instanceof Error) {
          setVerificationError('Could not create the user ')
          console.log('Error on create user', e.message)
        } else {
          setVerificationError(
            'Your Validation Code is Correct. Your user has been created. We are having technical difficulties. We will email you when the bug is fixed.'
          )
          console.log('We are in error', e)
        }
        // we have a problem with create user.
      }

      return
    }
    setVerificationError(cognitoVerifyResponse.message)
    setVerifying(false)
  }

  const forgot = async () => {
    if (validate('email', email) === 'Email is invalid') {
      setForgotPasswordError('Email is invalid, enter a valid email address')
      return
    }
    dispatch(
      updateMe({
        email: email
      })
    )
    const cognitoResponse = await sendCognitoPasswordCode(email.toLowerCase())
    if (!cognitoResponse.CodeDeliveryDetails) {
      setErrorMessage(cognitoResponse.message)
    } else {
      dispatch(startResettingPassword())
    }
  }

  const refreshTheToken = (token: string) => {
    //
  }

  return {
    validateCurrentPassword,
    validateEmail,
    verificationError,
    registrationError,
    loginError,
    forgotPasswordError,
    email,
    setEmail,
    setPassword,
    submittingRegistration,
    loggingIn,
    verifying,
    register,
    firstName,
    lastName,
    password,
    setFirstName,
    setLastName,
    errorMessage,
    login,
    forgot,
    resendCode,
    verificationCode,
    setVerificationCode,
    verify,
    changePassword,
    refreshTheToken
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import request, { ClientError } from 'graphql-request'

import { store } from '../Store'

export const graphqlBaseQuery = ({ baseUrl }: { baseUrl: string }) => async ({ body }: { body: string }) => {
  const token: string = store.getState().Me.jwtToken
  console.log('token', token)
  console.log('baseUrl', baseUrl)
  console.log('graphqlBaseQuery', body)
  try {
    const result = await request({
      url: baseUrl,
      document: body,
      requestHeaders: {
        authorization: token
      }
    })
    return { data: result }
  } catch (error) {
    if (error instanceof ClientError) {
      console.log('ERR', error)
      return { error: { status: error.response.status, data: error } }
    }
    return { error: { status: 500, data: error } }
  }
}

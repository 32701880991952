import React, { FC } from 'react'

type Props = {
  isLeft?: boolean
  label: string
  placeholder?: string
  value: string
  type?: 'tel' | 'url' | 'text' | 'email' | 'password' | 'number'
  onChange: (arg: string) => void
}
export const TextInputLeftRightComponent: FC<Props> = ({ isLeft, label, placeholder, value, type = 'text', onChange }: Props) => {
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className={isLeft ? 'input in1' : 'input in2'}>
      <label htmlFor="title">{label}</label>
      <input id="title" type={type} value={value} placeholder={placeholder ?? label} onChange={update} />
    </div>
  )
}

import { ConsumerProjectDef } from '../reducers/ConsumerProjectSlice'
import { GetRampByIdResponse } from '../api/rampSlice'

export const processRamp = (arg: ConsumerProjectDef) => {
  if (arg.emails) {
    const em: string = arg.emails as string
    return { ...arg, emails: JSON.parse(em) }
  }
  return arg
}

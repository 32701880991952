import React, { FC } from 'react'

interface Props {
  title: string
  copy: string
}

export const BrandPitchItem: FC<Props> = ({ title, copy }: Props) => {
  return (
    <tr>
      <td className="fontSize-12">
        <strong className="bbl-red">{`${title}:`}</strong>
      </td>
      <td>
        <p>{copy}</p>
      </td>
    </tr>
  )
}

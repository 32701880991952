import { Question, ScreenTracker, SecretSauce } from '../types/AppTypes'

import { addIdToRoute } from './RouteUtils'
import { calculatePercentageAsString } from './MathUtils'

export const getPillarQuestions = (questions: Question[]) => {
  const pillarOneQuestions = questions.filter((question: Question) => question.id.includes('p1'))
  const pillarTwoQuestions = questions.filter((question: Question) => question.id.includes('p2'))
  const pillarThreeQuestions = questions.filter((question: Question) => question.id.includes('p3'))
  const pillarFourQuestions = questions.filter((question: Question) => question.id.includes('p4'))
  return {
    pillarOneQuestions,
    pillarTwoQuestions,
    pillarThreeQuestions,
    pillarFourQuestions
  }
}

export const getPillarProgress = (id: string, currentArray: Question[]) => {
  // findIndex(myArray, itemToFind)
  const ids = currentArray.map((question: Question) => question.id)
  const index = ids.indexOf(id)
  return calculatePercentageAsString(index + 1, currentArray.length)
}

export const getSecretSauceData = (array: SecretSauce[], id: string) => {
  const index = array.findIndex(data => data.id === id)
  if (index !== -1) {
    // If item with the same id exists, replace it in the array
    return {
      answer: array[index].answer,
      superPower: array[index].superPower,
      competitorOne: array[index].competitorOne,
      competitorTwo: array[index].competitorTwo
    }
  }
  return {
    answer: '',
    superPower: '',
    competitorOne: '',
    competitorTwo: ''
  }
}

export const createScreensFromQuestions = (questions: Question[]): ScreenTracker[] => {
  const screens = questions.map((question: Question) => {
    return {
      route: addIdToRoute(question.route, question.id),
      pillar: question.pillar,
      questionId: question.id,
      status: ''
    }
  })
  return screens
}

import { gql } from 'graphql-request'
export const getCustomerAlignmentByIdHelper = (idealCustomerId: string) => {
  return gql`
    query getCustomerAlignmentById {
      getCustomerAlignmentById (idealCustomerId: "${idealCustomerId}") {
        id
        idealCustomerId
        ownerId
        customerValue
        weAlign
        painPoints
        solvesPainPoints
        saleObjections
        objectionSolutions
        priorBadExperience
        solveBadExperience
      }
    }
  `
}

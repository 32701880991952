import React, { FC } from 'react'

type Props = {
  title?: string
  isSelected?: boolean
}

export const PartnerDisplayBoxComponent: FC<Props> = ({ title, isSelected }: Props) => {
  if (isSelected) {
    return (
      <div className="cert_exp hired">
        <div className="cert_img">
          <img src="../assets/cert_expert_image/Image.png" alt="" />
        </div>
        <div className="cert_details">
          <ul>
            <li>
              <img src="../assets/cert_expert_image/Partner Name.svg" alt="" /> <span>Partner Name</span>
            </li>
            <li>
              <img src="../assets/cert_expert_image/Location.svg" alt="" /> <span>San Diego, CA</span>
            </li>
            <li>
              <img src="../assets/cert_expert_image/Website.svg" alt="" />{' '}
              <span>
                <a href="#">https://websiteurl.com</a>
              </span>
            </li>
          </ul>
        </div>
        <div className="hired_message">
          <img src="../assets/checkmark_blue.svg" alt="" /> <strong>Hire Certified Expert</strong>
        </div>
      </div>
    )
  }
  return (
    <div className="cert_exp">
      <div className="cert_img">
        <img src="../assets/cert_expert_image/Image.png" alt="" />
      </div>
      <div className="cert_details">
        <ul>
          <li>
            <img src="../assets/cert_expert_image/Partner Name.svg" alt="" /> <span>Partner Name</span>
          </li>
          <li>
            <img src="../assets/cert_expert_image/Location.svg" alt="" /> <span>San Diego, CA</span>
          </li>
          <li>
            <img src="../assets/cert_expert_image/Website.svg" alt="" />{' '}
            <span>
              <a href="#">https://websiteurl.com</a>
            </span>
          </li>
        </ul>
      </div>
      <div className="hired_message">
        <img src="../assets/checkmark_blue.svg" alt="" /> <strong>Hire Certified Expert</strong>
      </div>
    </div>
  )
}

import * as LottiePlayer from '@lottiefiles/lottie-player'

import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { Question } from '../types/AppTypes'
import { RootState } from '../Store'
import { SMALL_LOGO } from '../constants/ImageConstants'
import { STEPS_TO_SUCCESS_COMPLETE } from '../constants/RouteConstants'
import { getItemById } from '../utils/ArrayUtils'
import { insertNames } from '../utils/StringUtils'
import { useLocation } from 'react-router-dom'
import { usePillarCommunication } from '../customHooks/usePillarCommunication'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const CongratulationsGenericScreen: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const location = useLocation()
  const currentPath = location.pathname
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const idealCustomerName = useSelector((state: RootState) => state.IdealCustomer.customerName)
  const { goBack, nextPage, updateProgress } = useScreenNavigation()
  const { additionalFunctionCall } = usePillarCommunication()
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    // check here to see if there is an additional function,.
    updateProgress(newQuestion.id)
    if (newQuestion.functionCall) {
      additionalFunctionCall(newQuestion.functionCall)
    }
    // if there is, call it.
    setQuestion(newQuestion)
  }, [id])

  useEffect(() => {
    if (currentPath === STEPS_TO_SUCCESS_COMPLETE) {
      // we should call some data here
    }
  }, [currentPath])
  const renderImage = () => {
    if (question.icon) {
      return <img src={question.icon} alt="" />
    }
    if (question.gif) {
      return (
        <div className="lottie_container">
          <lottie-player loop autoplay background="transparent" speed="0.5" style={{ width: 600, height: 650 }} src={question.gif} />
        </div>
      )
    }
    return null
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img className="logo" src={SMALL_LOGO} alt="Marketing RAMP" />
        </div>
        <div className="mr-content">
          <center>
            {renderImage()}
            <h2 dangerouslySetInnerHTML={{ __html: insertNames(question.h2Copy, firstName, idealCustomerName) }} />
            <ButtonGroupComponent goBack={goBack} nextPageClick={nextPage} />
          </center>
        </div>
      </div>
    </div>
  )
}

import { AD_10, AD_8 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import { changePainPointFour, changePainPointOne, changePainPointThree, changePainPointTwo, updateQuestion } from '../../../../reducers/AdSpecificSlice'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { TextAreaLeftRightComponent } from '../../../../components/TextAreaLeftRightComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'

export const AdSpecificNinePanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { painPointOne, painPointTwo, painPointThree, painPointFour } = useSelector((state: RootState) => {
    return {
      painPointOne: state.AdSpecific.painPointOne,
      painPointTwo: state.AdSpecific.painPointTwo,
      painPointThree: state.AdSpecific.painPointThree,
      painPointFour: state.AdSpecific.painPointFour
    }
  })
  const next = () => {
    dispatch(updateQuestion(AD_10))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_8))
  }
  const changePPOne = (arg: string) => {
    dispatch(changePainPointOne(arg))
  }
  const changePPTwo = (arg: string) => {
    dispatch(changePainPointTwo(arg))
  }
  const changePPThree = (arg: string) => {
    dispatch(changePainPointThree(arg))
  }
  const changePPFour = (arg: string) => {
    dispatch(changePainPointFour(arg))
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Pain Points</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <div className="form-field duo-input">
                <TextAreaLeftRightComponent value={painPointOne} label="Pain Point #1:" onChange={changePPOne} isLeft placeholder="Enter Your Answer" />
                <TextAreaLeftRightComponent value={painPointTwo} label="Pain Point #2:" onChange={changePPTwo} placeholder="Enter Your Answer" />
              </div>
              <div className="form-field duo-input">
                <TextAreaLeftRightComponent value={painPointThree} label="Pain Point #3:" onChange={changePPThree} isLeft placeholder="Enter Your Answer" />
                <TextAreaLeftRightComponent value={painPointFour} label="Pain Point #4:" onChange={changePPFour} placeholder="Enter Your Answer" />
              </div>
              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

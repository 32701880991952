import React, { FC } from 'react'

type Props = {
  label: string
  isDisabled?: boolean
  placeholder?: string
  value: string
  type?: 'tel' | 'url' | 'text' | 'email' | 'password' | 'number'
  onChange: (arg: string) => void
}
export const TextInputContainerComponent: FC<Props> = ({ label, placeholder, value, type = 'text', onChange, isDisabled = false }: Props) => {
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className="inputContainer">
      <label htmlFor="title" className="label">
        {label}
      </label>
      <input className="input" id="title" type={type} value={value} placeholder={placeholder ?? label} onChange={update} disabled={isDisabled} />
    </div>
  )
}

import { EmailStage } from '../../rampSlice'
import { gql } from 'graphql-request'
export const getRampEmailsByStageHelper = (obj: EmailStage) => {
  return gql`
  query getCustomerRampById{
    getCustomerRampById(rampId: "${obj.rampId}") {
      id
      emails
    }
  }
  `
}

import { LL_COMPANY_ALIGN, LL_COMPANY_CORE, LL_EMPATHY_MAP, LL_PROCESS, LL_SPECTRUM } from '../../../constants/ImageConstants'
import React, { FC } from 'react'

import { PeopleWeLoveRowComponent } from '../../../components/PeopleWeLoveRowComponent'

export const LoveLanguageSceneComponent: FC = () => {
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Our Love Language</h1>
      </div>
      <div className="row main-row">
        <div className="col-md-12" />
        <div className="col-md-12">
          <div className="row inner-row c_list dashboard_content">
            <div className="col-md-12">
              <h2 className="">Key Parts of Our Love Language</h2>
            </div>
            <div className="col-md-12">
              <PeopleWeLoveRowComponent title="Company Core Purpose" icon={LL_COMPANY_CORE} />
              <PeopleWeLoveRowComponent title="Customer Alignment" icon={LL_COMPANY_ALIGN} />
              <PeopleWeLoveRowComponent title="Process / Transformation" icon={LL_PROCESS} />
              <PeopleWeLoveRowComponent title="Empathy Map" icon={LL_EMPATHY_MAP} />
              <PeopleWeLoveRowComponent title="Spectrum Of Formality" icon={LL_SPECTRUM} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { FC } from 'react'

import { AVATAR } from '../constants/ImageConstants'

export const AccountInfoPaneComponent: FC = () => {
  return (
    <div className="bg-light col-xl-5 col-lg-12 p-5">
      <h4>Information</h4>
      <div className="form-elements">
        <form action="#">
          <div className="form-fields">
            <div className="form-field fw">
              <div className="acc-image">
                <img src={AVATAR} alt="John Doe" />
                <span>Add or update your avatar</span>
              </div>
            </div>
            <div className="form-field fw">
              <label htmlFor="acc-name">Name:</label>
              <input id="acc-name" type="text" value="John Doe" />
            </div>
            <div className="form-field fw">
              <label htmlFor="acc-email">Email:</label>
              <input id="acc-email" type="email" value="123@email.com" />
            </div>
            <div className="form-field fw">
              <label htmlFor="acc-psw">Password:</label>
              <input id="acc-psw" type="password" value="1234567" />
            </div>
            <div className="form-field fw">
              <label htmlFor="acc-company">Company:</label>
              <input id="acc-company" type="text" value="Built By Love" />
            </div>
            <input type="button" className="btn-red" value="Save" />
          </div>
        </form>
      </div>
    </div>
  )
}

import getCognitoUser from './getCognitoUser'

const sendCognitoPasswordCode = async (username: string): Promise<any> => {
  return await new Promise(function(resolve, reject) {
    const currentUser = getCognitoUser(username)
    currentUser.forgotPassword({
      onSuccess: function(data) {
        resolve(data)
      },
      onFailure: function(err) {
        resolve(err.message || JSON.stringify(err))
      }
    })
  }).catch(err => {
    return err
  })
}

export default sendCognitoPasswordCode

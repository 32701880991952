import React, { FC } from 'react'

type Props = {
  label: string
  value?: string
  isSelected: boolean
  group?: string
  onClick: (arg: string) => void
}
export const RadioButtonSquareComponent: FC<Props> = ({ label, isSelected, onClick, group, value = label }: Props) => {
  const onPress = () => {
    onClick(value)
  }
  return (
    <>
      <input type="radio" name={group ? group + label : label} id={group ? group + label : label} value="small" checked={isSelected} onChange={onPress} />
      <label htmlFor={group ? group + label : label}>{label}</label>
    </>
  )
}

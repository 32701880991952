import React, { FC, useEffect, useState } from 'react'
import { cleanLineBreaks, insertName } from '../utils/StringUtils'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import { DASHBOARD_ROUTE } from '../constants/RouteConstants'
import { Question } from '../types/AppTypes'
import { RAMP_LOGO_MED } from '../constants/ImageConstants'
import { RootState } from '../Store'
import { completeRamp } from '../reducers/ConsumerProjectSlice'
import { getItemById } from '../utils/ArrayUtils'
import { useLazyCompleteRampQuery } from '../api/rampSlice'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const PillarFourNextStepsScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { updateProgress } = useScreenNavigation()
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const [question] = useState<Question>(getItemById(questions, id ?? ''))
  const [completeRampAPI] = useLazyCompleteRampQuery()
  const [isProcessing, setIsProcessing] = useState(false)
  const rampId = useSelector((state: RootState) => state.ConsumerProject.id)
  useEffect(() => {
    updateProgress(id ?? '')
  }, [id])

  const finishRamp = async () => {
    setIsProcessing(true)
    dispatch(completeRamp())
    const result = await completeRampAPI({ id: rampId, rampCompleted: true })
    console.log('Result', result)
    setIsProcessing(false)
    if (result) {
      navigate(DASHBOARD_ROUTE)
    }
  }

  const renderButtons = () => {
    return <ButtonGroupComponent nextPageClick={finishRamp} processing={isProcessing} nextOnly />
  }
  return (
    <div className="mr rmsteps pillar4nextsteps">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={RAMP_LOGO_MED} alt="Marketing RAMP" />
        </div>
        <div className="mr-content">
          <center>
            <img className="img_pillar_four" src={question.icon} alt="" />
            <h2>{insertName(question.h2Copy, firstName)}</h2>
            <p dangerouslySetInnerHTML={{ __html: cleanLineBreaks(question.pCopy ?? '') }} />
            {renderButtons()}
          </center>
        </div>
      </div>
    </div>
  )
}

import React, { FC } from 'react'

import { RootState } from '../../Store'
import { calculatePercentageAsString } from '../../utils/MathUtils'
import { RampProgressStyles as styles } from './ProgressSegmentStyles'
import { useSelector } from 'react-redux'

type Props = {
  pillar: number
  id: string
}
export const ProgressSegmentComponent: FC<Props> = ({ pillar, id }: Props) => {
  const progress = useSelector((state: RootState) => {
    switch (pillar) {
      case 2:
        return state.ConsumerProject.pillarTwoProgress
      case 3:
        return state.ConsumerProject.pillarThreeProgress
      case 4:
        return state.ConsumerProject.pillarFourProgress
      default:
        return state.ConsumerProject.pillarOneProgress
    }
  })

  return (
    <div style={styles.container}>
      <div style={{ ...styles.progress, width: progress }} />
    </div>
  )
}

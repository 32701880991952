import { RR_DURATION, RS_EMAIL, RS_GIFT, RS_LANDING_PAGE, RS_PDF, RS_PHONE, RS_VIDEO } from '../constants/ImageConstants'
import React, { FC } from 'react'

type Props = {
  title: string
  titleIcon: string
  ideal: number
  landingPage: number
  max: number
  video: number
  email: number
  phoneCalls: number
  pdf: number
  gift: number
}

export const RampReviewBoxComponent: FC<Props> = ({ title, titleIcon, ideal, landingPage, max, video, email, phoneCalls, pdf, gift }: Props) => {
  return (
    <div className="review_m_stage">
      <div className="m_title">
        <img src={titleIcon} alt="" /> &nbsp;&nbsp;{`${title}`}
      </div>
      <div className="duration">
        <div className="b_title">
          <img src={RR_DURATION} alt="" /> Duration
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="review_cont">
              Ideal:{' '}
              <span className="stage_cont" contentEditable="true">
                {ideal}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="review_cont">
              Max:{' '}
              <span className="stage_cont" contentEditable="true">
                {max}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="duration assets_">
        <div className="b_title">
          <img src={RR_DURATION} alt="" /> Assets
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="review_cont">
              {' '}
              <img src={RS_LANDING_PAGE} alt="" /> Landing Page:{' '}
              <span className="stage_cont" contentEditable="true">
                {landingPage}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="review_cont">
              <img src={RS_VIDEO} alt="" /> Video:{' '}
              <span className="stage_cont" contentEditable="true">
                {video}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="review_cont">
              {' '}
              <img src={RS_EMAIL} alt="" /> Email:{' '}
              <span className="stage_cont" contentEditable="true">
                {email}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="review_cont">
              <img src={RS_PHONE} alt="" /> Phone Calls:{' '}
              <span className="stage_cont" contentEditable="true">
                {phoneCalls}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="review_cont">
              {' '}
              <img src={RS_PDF} alt="" /> PDF:{' '}
              <span className="stage_cont" contentEditable="true">
                {pdf}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="review_cont">
              <img src={RS_GIFT} alt="" /> Gift:{' '}
              <span className="stage_cont" contentEditable="true">
                {gift}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { EmpathyQuestionData } from '../types/AppTypes'

export const paginate = (array: any, pageNumber: number, pageSize: number) => {
  const startIndex = (pageNumber - 1) * pageSize
  return array.slice(startIndex, startIndex + pageSize)
}

export const getItemById = (arg: any[], id: string) => {
  return arg.find(item => item.id === id)
}

export const addRemove = (newItem: string, array: string[]) => {
  if (array.includes(newItem)) {
    return array.filter((item: string) => item !== newItem)
  }
  const newArray = [...array]
  newArray.push(newItem)
  return newArray
}

export const pushReplace = (array: any[], item: { id: string }): any[] => {
  console.log('Main Array length', array.length)
  const index = array.findIndex(data => data.id === item.id)
  console.log('INDEX', index)
  if (index !== -1) {
    console.log('NO Item')
    const newArray = [...array]
    // If item with the same id exists, replace it in the array
    newArray[index] = item
    return newArray
  } else if (array.length === 0) {
    return [item]
  } else {
    console.log('Here we go in there for push')
    const newArray = [...array]
    newArray.push(item)
    return newArray
  }
}

export const getAnswerData = (array: EmpathyQuestionData[], id: string) => {
  const index = array.findIndex(data => data.id === id)
  if (index !== -1) {
    // If item with the same id exists, replace it in the array
    return array[index].answer
  }
  return ''
}

export const createOptionsArray = (stringArray: string[]) => {
  const options = stringArray.map((arg: string) => {
    return {
      label: arg,
      value: arg
    }
  })
  return options
}

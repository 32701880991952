import React, { FC } from 'react'

import { Colors } from '../../constants/Theme'
import { ThreeDots } from 'react-loader-spinner'

export const LoadingIconComponent: FC = () => {
  return (
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color={Colors.loader}
      ariaLabel="three-dots-loading"
      wrapperStyle={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      wrapperClass=""
      visible
    />
  )
}

import React, { FC } from 'react'

import { RoundButtonComponent } from './buttons/RoundAddButtonComponent'

type Props = {
  title?: string
}

export const AddPersonEmailComponent: FC<Props> = ({ title }: Props) => {
  const addEmail = () => {
    //
  }
  const removeEmail = () => {
    //
  }
  return (
    <div className="dynamic_form_input">
      <form className="form-horizontal">
        <div id="items" className="form-group">
          <div className="col-md-12 margin-bottom">
            <input id="textinput" name="textinput" type="text" placeholder="Username or Email" className="form-control input-md" />
          </div>
        </div>
      </form>
      <RoundButtonComponent onClick={addEmail} />
      <button className="delete btn button-white uppercase" onClick={removeEmail}>
        Remove
      </button>
    </div>
  )
}

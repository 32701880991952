import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? '',
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID ?? ''
}
const userPool = new CognitoUserPool(poolData)

const getCognitoUser = (username: string) => {
  console.log('', process.env.REACT_APP_USER_POOL_ID)
  console.log('', process.env.REACT_APP_USER_POOL_CLIENT_ID)
  const userData = {
    Username: username,
    Pool: userPool
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser
}

export default getCognitoUser

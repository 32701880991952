import React, { FC } from 'react'

type Props = {
  label: string
  value: string
  isLeft?: boolean
  placeholder?: string
  onChange: (arg: string) => void
}
export const TextAreaLeftRightComponent: FC<Props> = ({ value, label, isLeft, placeholder, onChange }: Props) => {
  const update = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className={isLeft ? 'input in1' : 'input in2'}>
      <label htmlFor="pp1">{label}</label>
      <textarea id="pp2" cols={30} rows={10} placeholder={placeholder ?? label} onChange={update} value={value} />
    </div>
  )
}

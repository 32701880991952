import { CompleteRampInfo } from '../../rampSlice'
import { gql } from 'graphql-request'
export const completeRampHelper = (obj: CompleteRampInfo) => {
  return gql`
      mutation updateCustomerRampById {
        updateCustomerRampById (customerRamp: {
          id: "${obj.id}",
          rampCompleted: ${obj.rampCompleted},
        }) {
          id
        }
      }
    `
}

import React, { FC } from 'react'

type Props = {
  useOne?: boolean
  title: string
  subTitle: string
  body: string
}

export const BrandPitchSquareComponent: FC<Props> = ({ useOne = false, title, subTitle, body }: Props) => {
  const mainClassName = useOne ? 'input in1' : 'input in2'
  const subClassName = useOne ? 'customer_seek cs1' : 'customer_seek cs2'
  return (
    <div className={mainClassName}>
      <div className={subClassName}>
        <div className="cs_label">
          {title}
          <span>{subTitle}</span>
        </div>
        <div className="cs_content">{body}</div>
      </div>
    </div>
  )
}

import { AD_4, AD_6 } from '../../../../constants/AdSpecificConstants'
import { AppDispatch, RootState } from '../../../../Store'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NextButtonComponent } from '../../../../components/buttons/NextButtonComponent'
import { TextInputLeftRightComponent } from '../../../../components/TextInputLeftRightComponent'
import { TransparentButtonComponent } from '../../../../components/buttons/TransparentButtonComponent'
import { updateQuestion } from '../../../../reducers/AdSpecificSlice'

export const AdSpecificFivePanel: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { firstName, lastName, email, password, companyName, website, phone, title } = useSelector((state: RootState) => {
    return {
      firstName: state.Me.firstName,
      lastName: state.Me.lastName,
      email: state.Me.email,
      password: state.Me.password,
      title: state.Me.title,
      phone: state.Me.phone,
      website: state.Me.companyWebsite,
      companyName: state.Me.companyName
    }
  })
  const next = () => {
    dispatch(updateQuestion(AD_6))
  }
  const goBack = () => {
    dispatch(updateQuestion(AD_4))
  }
  const changeFirstName = (arg: string) => {
    //
  }
  const changeLastName = (arg: string) => {
    //
  }
  const changeCompanyName = (arg: string) => {
    //
  }
  const changeTitle = (arg: string) => {
    //
  }
  const changeWebsite = (arg: string) => {
    //
  }
  const changePhone = (arg: string) => {
    //
  }
  const changeEmail = (arg: string) => {
    //
  }
  const changePassword = (arg: string) => {
    //
  }
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">My Account</h1>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-elements">
            <div className="form-fields">
              <div className="form-field duo-input">
                <TextInputLeftRightComponent label="First name*" value={firstName} onChange={changeFirstName} isLeft />
                <TextInputLeftRightComponent label="Last name*" value={lastName} onChange={changeLastName} />
              </div>
              <div className="form-field duo-input">
                <TextInputLeftRightComponent label="Company Name" placeholder="Your Company" value={companyName} onChange={changeCompanyName} isLeft />
                <TextInputLeftRightComponent label="Your Title" placeholder="CEO" value={title} onChange={changeTitle} />
              </div>
              <div className="form-field duo-input">
                <TextInputLeftRightComponent label="Company Website" type="url" value={website} onChange={changeWebsite} placeholder="company.com" isLeft />
                <TextInputLeftRightComponent label="Mobile Phone*" type="tel" value={phone} onChange={changePhone} placeholder="Phone" />
              </div>
              <div className="form-field duo-input">
                <TextInputLeftRightComponent label="Email*" type="email" value={email} onChange={changeEmail} placeholder="name@domain.com" isLeft />
                <TextInputLeftRightComponent label="Password*" type="password" value={password} onChange={changePassword} placeholder="*********" />
              </div>

              <div className="grp-btns">
                <TransparentButtonComponent onClick={goBack} />
                <NextButtonComponent onClick={next} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { gql } from 'graphql-request'
export const updateEducation = (arg: string) => {
  return gql`
    mutation updateIdealCustomer {
      updateIdealCustomer (idealCustomer: {
        education: "${arg}",
      }) {
        id
      }
    }
  `
}

import React, { FC } from 'react'

type Props = {
  image: string
  onSelect: (arg: string) => void
  isSelected: boolean
}
export const AvatarContainerComponent: FC<Props> = ({ image, onSelect, isSelected }: Props) => {
  const onClick = () => {
    onSelect(image)
  }
  return (
    <li>
      <a href="#" onClick={onClick}>
        <img src={image} alt="" />
      </a>
    </li>
  )
}

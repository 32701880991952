import { PASSWORD_ICON, RAMP_LOGO_BIG, USERNAME_ICON } from '../constants/ImageConstants'
import React, { FC, useEffect, useState } from 'react'

import { DASHBOARD } from '../constants/SceneKeys'
import { DASHBOARD_ROUTE } from '../constants/RouteConstants'
import { LoadingIconComponent } from '../components/loadingIcon/LoadingIconComponent'
import { updateCustomer } from '../reducers/ConsumerProjectSlice'
import { updateIdeal } from '../reducers/IdealCustomerSlice'
import { updateMe } from '../reducers/MeSlice'
import { useCognito } from '../customHooks/useCognito'
import { useDispatch } from 'react-redux'
import { useLazyGetIdealCustomerQuery } from '../api/icSlice'
import { useLazyGetRampByIdQuery } from '../api/rampSlice'
import { useLazyGetUserByIdQuery } from '../api/usersSlice'
import { useNavigate } from 'react-router-dom'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'

export const LoginScreenComponent: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const [loggingIn, setLoggingIn] = useState(false)
  const { email, setEmail, login, password, setPassword, loginError, loggingIn } = useCognito()
  const { initRamp, getProgressPage } = useScreenNavigation()
  const [getUser] = useLazyGetUserByIdQuery()
  const [getRamp] = useLazyGetRampByIdQuery()
  const [getIdealCustomer] = useLazyGetIdealCustomerQuery()
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    // This will prevent the default form submission action
    event.preventDefault()
    sendData()
  }

  const sendData = async () => {
    // setLoggingIn(true)
    const result = await login()
    if (result) {
      // lets get the ramp
      try {
        const user = await getUser(result).unwrap()
        console.log('userResponse', user)
        dispatch(
          updateMe({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            type: user.type,
            passwordResetInProgress: false,
            startPasswordRecovery: false
          })
        )
        const ramp = await getRamp(user.ramps[0]).unwrap()
        dispatch(updateCustomer(ramp))
        const idealCustomer = await getIdealCustomer(ramp.idealCustomerId).unwrap()
        dispatch(updateIdeal(idealCustomer))
        const route = getProgressPage(ramp.currentScreenId)
        // setLoggingIn(false)
        if (ramp.rampCompleted) {
          navigate(DASHBOARD_ROUTE)
          return
        }
        navigate(route)
      } catch (e) {
        console.log('BIG error', e)
        // setLoggingIn(false)
      }
      // return
    }
    // setLoggingIn(true)
  }
  const renderButton = () => {
    if (loggingIn) {
      return (
        <div className="grp-btns">
          <LoadingIconComponent />
        </div>
      )
    }
    return <input type="submit" className="btn-red" value="LOGIN" />
  }

  useEffect(() => {
    initRamp()
  }, [])

  return (
    <div className="mr mr-login">
      <div className="mr-container">
        <div className="mr-bnr">
          <div className="mr-bnr-cont">
            <h1>Welcome to</h1>
            <div className="bnr-head">
              <img className="logo" src={RAMP_LOGO_BIG} alt="Marketing RAMP" />
            </div>
            <div className="form-elements login-screen">
              <form onSubmit={submit}>
                <div className="form-fields">
                  <div className="form-field fw">
                    <span className="icon">
                      <img src={USERNAME_ICON} alt="" />
                    </span>
                    <input id="acc-name" type="text" placeholder="Email" value={email} onChange={handleEmailChange} />
                  </div>

                  <div className="form-field fw">
                    <span className="icon">
                      <img src={PASSWORD_ICON} alt="" />
                    </span>
                    <input id="acc-psw" type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                  </div>
                  <label className="container">
                    Keep me logged in
                    <input type="checkbox" checked />
                    <span className="checkmark" />
                  </label>
                  {loginError ? <p>{loginError}</p> : null}
                  {renderButton()}
                </div>
              </form>
            </div>

            <p>
              <a href="#">Forgot your password?</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

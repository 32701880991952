import React, { FC } from 'react'

export const AccountCurrentPlanComponent: FC = () => {
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="row">
        <div className="bg-light col-md-12 p-5">
          <h4>Current Plan</h4>
          <div className="row mt-4">
            <div id="plan-txt" className="col-xl-6 col-lg-12">
              <span>
                <strong>Subscription: </strong> Personal Use
              </span>
              <span>
                <strong>Subscription Renewal Date: </strong> January 1, 2023
              </span>
            </div>
            <div className="col-xl-6 col-lg-12">
              <a className="plan_upgrage_btn btn-red" href="#" target="_blank" rel="noopener noreferrer">
                Upgrade Plan
              </a>
            </div>
          </div>
        </div>
        <div className="bg-light col-md-12 p-5">
          <div className="row">
            <div className="col-md-6">
              <h4>Card In Use</h4>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <a href="#">Change Details</a>
                </div>
                <div className="col-md-6">
                  <a href="#">Add New Card</a>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="row ">
                <div className="col-md-7">
                  <div className="form-elements">
                    <form action="#">
                      <div className="form-fields">
                        <div className="form-field fw">
                          <label htmlFor="card-num">Card Number:</label>
                          <input id="card-num" type="text" value="3769 **** **** 9010" />
                        </div>
                        <div className="form-field fw">
                          <label htmlFor="card-holder">Card Holder Name:</label>
                          <input id="card-holder" type="text" value="John Doe" />
                        </div>
                        <div className="form-field duo-input">
                          <div className="input in1">
                            <label htmlFor="expiry">Expiry:</label>
                            <input id="expiry" type="text" value="MM / YY" />
                          </div>
                          <div className="input in2">
                            <label htmlFor="card-cvv">CVV:</label>
                            <input id="card-cvv" type="text" value="***" />
                          </div>
                        </div>
                        <div className="form-field duo-input">
                          <div className="input in1">
                            <label htmlFor="states">Country:</label>
                            <select name="states" id="states">
                              <option value="United States">United States</option>
                              <option value="United States">Philippines</option>
                            </select>
                          </div>
                          <div className="input in2">
                            <label htmlFor="zip">Zip Code:</label>
                            <input id="zip" type="text" value="1234" />
                          </div>
                        </div>
                        <input type="button" className="btn-red" value="Pay $99.99" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="card-image">
                    <span className="card-type">Master Card</span>
                    <span className="card-num">3769 **** **** 9010</span>
                    <span className="card-holder">John Doe</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

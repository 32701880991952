import * as React from 'react'

import { FC } from 'react'

const HelpCenterSceneComponent: FC = () => {
  return (
    <div className="main-content">
      <div className="row">
        <h1 className="mod-title">Help Center</h1>
      </div>
      <div className="row main-row">
        <div className="col-md-12">
          <div className="row inner-row p-5 dashboard_content hc">
            <h4>Contact Us</h4>
            <p>
              <ul>
                <li>Lorem ipsum dolor sit amet ut officiis phaedrum usu. Eos nominati invenire assueverit in vel erat mentitum ei.</li>
                <li>Ad est persius discere. No quaeque omittantur eum, eu fabulas democritum reprimique his.</li>
                <li>Mucius quaerendum an sit, no graeci luptatum intellegebat vel, affert molestie pertinax at est.</li>
              </ul>
            </p>
            <h4>Email</h4>
            <p>
              <ul>
                <li>Lorem ipsum dolor sit amet ut officiis phaedrum usu. Eos nominati invenire assueverit in vel erat mentitum ei.</li>
                <li>Ad est persius discere. No quaeque omittantur eum, eu fabulas democritum reprimique his.</li>
                <li>Mucius quaerendum an sit, no graeci luptatum intellegebat vel, affert molestie pertinax at est.</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export { HelpCenterSceneComponent }

import { PetsUpdate } from '../icSlice'
import { gql } from 'graphql-request'
export const updatePets = (arg: PetsUpdate) => {
  if (arg.hasPets === 'Yes') {
    const newArray: string[] = [...arg.pets]
    const json2 = JSON.stringify(JSON.stringify(newArray))
    return gql`
    mutation updateIdealCustomer {
      updateIdealCustomer (idealCustomer: {
        hasPets: "${arg.hasPets}",
        pets: ${json2},
      }) {
        id
      }
    }
  `
  }
  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          hasChildren: "${arg.hasPets}",
        }) {
          id
        }
      }
    `
}

import { gql } from 'graphql-request'
export const buildPainPointsHelper = (idealCustomerId: string) => {
  return gql`
    query setPainPoints {
      setPainPoints (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

import { SecretSauce } from '../../types/AppTypes'
import { gql } from 'graphql-request'
export const updateSecretSauce = (arg: SecretSauce[]) => {
  const newArray: SecretSauce[] = [...arg]
  const json2 = JSON.stringify(JSON.stringify(newArray))

  return gql`
      mutation updateIdealCustomer {
        updateIdealCustomer (idealCustomer: {
          secretSauce: ${json2},
        }) {
          id
        }
      }
    `
}

import React, { FC, useEffect, useState } from 'react'

import { ButtonGroupComponent } from '../components/buttons/ButtonGroupComponent'
import ProgressBar from '@ramonak/react-progress-bar'
import { Question } from '../types/AppTypes'
import { RootState } from '../Store'
import { SMALL_LOGO } from '../constants/ImageConstants'
import { getItemById } from '../utils/ArrayUtils'
import { insertName } from '../utils/StringUtils'
import { useLocation } from 'react-router-dom'
import { useRampBuilder } from '../customHooks/useRampBuilder'
import { useScreenNavigation } from '../customHooks/useScreenNavigation'
import { useSelector } from 'react-redux'

export const EmailGenerationGPTScreenComponent: FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const id = query.get('id')
  const questions = useSelector((state: RootState) => state.ConsumerProject.questions)
  const firstName = useSelector((state: RootState) => state.Me.firstName)
  const { updateProgress, goBack, nextPage } = useScreenNavigation()
  const [question, setQuestion] = useState<Question>(getItemById(questions, id ?? ''))
  const { processingEmailStatus, buildEmails, emailsCompleted } = useRampBuilder()

  useEffect(() => {
    const newQuestion = getItemById(questions, id ?? '')
    updateProgress(newQuestion.id, true)
    setQuestion(newQuestion)
    buildEmails()
  }, [])
  const renderProgress = () => {
    if (processingEmailStatus) {
      console.log('EMAILS emailsCompleted ', emailsCompleted)
      return (
        <div style={{ alignItems: 'flex-start', width: 400 }}>
          <ProgressBar completed={emailsCompleted} bgColor="#FF0000" />
        </div>
      )
    }
    return <ButtonGroupComponent goBack={goBack} nextPageClick={nextPage} label={question.button} processing={processingEmailStatus} />
  }
  return (
    <div className="mr rmsteps email_confirmation">
      <div className="mr-container">
        <div className="ramp_logo">
          <img src={SMALL_LOGO} alt="Marketing RAMP" />
        </div>
        <div className="mr-content">
          <center>
            {/* <p>This is supposed to be a video here. Or do you want an animated gif?</p> */}
            <h2 dangerouslySetInnerHTML={{ __html: insertName(question.h2Copy, firstName) }} />
            <p>{question?.pCopy ?? ''}</p>
            {renderProgress()}
          </center>
        </div>
      </div>
    </div>
  )
}

import { StripeIntent } from '../../stripeSlice'
import { gql } from 'graphql-request'
export const createPaymentIntentHelper = (intent: StripeIntent) => {
  return gql`
    mutation createPaymentIntent {
      createPaymentIntent(intent: {
        amount: ${intent.amount},
        currency: "${intent.currency}",
        discountCode: "${intent.discountCode}",
        description: "${intent.description}"
      }) {
        id
        clientSecret
        amount
      }
    }
  `
}

import { gql } from 'graphql-request'
export const buildOurSolutionHelper = (idealCustomerId: string) => {
  return gql`
    query completeOurSolution {
      completeOurSolution (idealCustomerId: "${idealCustomerId}") {
        id
      }
    }
  `
}

import React, { FC } from 'react'

import { LoadingIconComponent } from '../loadingIcon/LoadingIconComponent'
import { NextButtonComponent } from './NextButtonComponent'

type Props = {
  nextPageClick: () => void
  label?: string
  processing?: boolean
}
export const SingleButtonSmartComponent: FC<Props> = ({ label = 'Next', nextPageClick, processing = false }: Props) => {
  if (processing) {
    return (
      <div className="grp-btns">
        <LoadingIconComponent />
      </div>
    )
  }

  return (
    <div className="grp-btns">
      <NextButtonComponent onClick={nextPageClick} label={label} />
    </div>
  )
}

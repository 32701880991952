import getCognitoUser from './getCognitoUser'

const confirmCognitoPassword = async (username: string, password: string, code: string): Promise<any> => {
  return await new Promise(function(resolve, reject) {
    const currentUser = getCognitoUser(username)
    currentUser.confirmPassword(code, password, {
      onSuccess: function(res: any) {
        console.log('Password confirmed!')
        resolve(res)
      },
      onFailure: function(err: any) {
        console.log('Password not confirmed!')
        resolve(err)
      }
    })
  }).catch(err => {
    console.log('throwError', err)
    return err
  })
}

export default confirmCognitoPassword

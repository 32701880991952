import React, { FC } from 'react'

import { NEXT_BTN_ARROW } from '../../constants/ImageConstants'

type Props = {
  onClick: () => void
  label?: string
  useEnter?: boolean
  noWhiteArrow?: boolean
}

export const NextButtonComponent: FC<Props> = ({ onClick, label = 'Next', useEnter = false, noWhiteArrow = false }) => {
  if (noWhiteArrow) {
    return (
      <a href="#" onClick={onClick} className="btn-red" rel="noopener noreferrer">
        {label}
      </a>
    )
  }
  return (
    <a href="#" onClick={onClick} className="btn-red" rel="noopener noreferrer">
      {label}
      <img className="btn_icon" src={NEXT_BTN_ARROW} />
    </a>
  )
}
